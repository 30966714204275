/* eslint-disable no-self-compare */
import React, { useState, useEffect, useRef } from 'react'
import handlersSales from '../../../handlers/Sales'
import handlersBranch from '../../../handlers/Branch'
import {
  makeStyles, TextField, Theme, Divider, Tooltip,
  CircularProgress, Drawer, Typography, Container,
  Card, Button, IconButton, Switch, CardHeader,
  Breadcrumbs, Badge, List, ListItem, ListItemText,
  AppBar, TextareaAutosize, Tabs, Tab, Box
} from '@material-ui/core/'
import { Alert } from '@material-ui/lab/'
import {
  DeleteOutline,
  SearchOutlined,
} from '@material-ui/icons/'
import DataTable from '../../../components/DataTable'
import SnackAlert from '../../../components/Alert'
import FadeIn from 'react-fade-in'
import { getToken, maskReais } from '../../../util'
import './styles.css'
import api from '../../../services/api'
import ComboBox from '../../../components/ComboBox'
import IProduct from '../../../types/IProduct'
import handlersProduct from '../../../handlers/Product'
import handlersProductPrice from '../../../handlers/ProductPrice'
import IProductPriceGrid from '../../../types/IProductPriceGrid'
import DatePicker from '../../../components/DatePicker'
import handlersUser from '../../../handlers/User'
import RangePicker from '../../../components/RangePicker'
import { DateRange } from '@mui/lab/DateRangePicker';

const TramaReport = (props: any): React.ReactElement => {

  const [selectedIdfilial, setSelectedIdfilial] = useState('');
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [productPrices, setProductPrices] = useState<IProductPriceGrid[]>([])
  const [products, setProducts] = useState<IProduct[]>([])
  const [selectedIdProduct, setSelectedIdProduct] = useState('');
  const [selectedIdProductGrid, setSelectedIdProductGrid] = useState('');
  const [valorProducao, setValorProducao] = useState('');
  const [selectedIdUsuario, setSelectedIdUsuario] = useState('');
  const [dataInclusao, setDataInclusao] = useState(new Date())
  const [incluirEstoque, setIncluirEstoque] = useState(false)
  const [qtd, setQtd] = useState(1);
  const [alert, setAlert] = useState({ visible: false, message: '', error: false })
  const [selectedTab, setSelectedTab] = useState(0);
  const [users, setUsers] = useState<any>([])
  const [filiais, setFiliais] = useState<any>([])
  const [idPedidoVenda, setIdPedidoVenda] = useState('')
  const [loadingPedido, setLoadingPedido] = useState(false)
  const [pedidoInfo, setPedidoInfo] = useState<any>([])
  const [rowSelected, setRowSelected] = useState<any>()
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [filtroData, setFiltroData] = useState<DateRange<Date>>([null, null]);
  const [filtroTramador, setFiltroTramador] = useState('');

  const [arrProdutosIntegracao, setArrProdutosIntegracao] = useState<any>([])
  const [producaoPedido, setProducaoPedido] = useState(false)

  const getPedidoInfo = async (): Promise<void> => {
    setLoadingPedido(true)
    const response = await handlersSales._listOne(Number(idPedidoVenda))
    setPedidoInfo(response)
    setLoadingPedido(false)
  }

  const getFiliais = async (): Promise<void> => {
    const data = await handlersBranch._list()
    setFiliais(data)
  }

  const getUsers = async (): Promise<void> => {
    const data = await handlersUser._list()
    setUsers(data)
  }

  const getProducts = async (): Promise<void> => {
    const data = await handlersProduct._list()
    setProducts(data)
  }

  const getProductPrice = async (idproduto: number): Promise<void> => {
    const data = await handlersProductPrice._listGrid(idproduto)
    setProductPrices(data)
  }

  const handleRegister = async (): Promise<void> => {
    if (!selectedIdUsuario) return setAlert({ error: true, visible: true, message: 'Favor informe o tramador' })
    setLoadingRegister(true)
    try {
      const user = localStorage.getItem('userInfo')
      if (user) {
        const obj_user = JSON.parse(user)
        const { idusuario } = obj_user
        if (!producaoPedido) {
          await api.post('product/producaotrama', {
            idusuarioinclusao: Number(idusuario),
            idusuario: Number(selectedIdUsuario),
            dataproducao: dataInclusao,
            quantidade: qtd,
            idprodutograde: Number(selectedIdProductGrid),
            valor: qtd * Number(valorProducao),
            iditempedidovenda: null,
            idfilial: selectedIdfilial ? Number(selectedIdfilial) : null,
            estoque: incluirEstoque,
          }, { headers: { 'x-access-token': await getToken() } })
        } else {
          for await (let _prod of arrProdutosIntegracao) {
            await api.post('product/producaotrama', {
              idusuarioinclusao: Number(idusuario),
              idusuario: Number(selectedIdUsuario),
              dataproducao: dataInclusao,
              quantidade: _prod.quantidade,
              idprodutograde: _prod.idprodutograde,
              valor: _prod.valor,
              iditempedidovenda: _prod.iditempedidovenda,
              idfilial: null,
              estoque: incluirEstoque,
            }, { headers: { 'x-access-token': await getToken() } })
          }
        }
        setSelectedIdUsuario('')
        setDataInclusao(new Date())
        setSelectedIdProductGrid('')
        setQtd(1)
        setRowSelected(null)
        setSelectedIdfilial('')
        setIncluirEstoque(false)
        setSelectedIdProduct('')
        setValorProducao('')
        setPedidoInfo([])
        setIdPedidoVenda('')
        setArrProdutosIntegracao([])
        setAlert({ visible: true, error: false, message: 'Sucesso ao cadastrar.' })
        setSelectedTab(1)
        setSelectedTab(0)
        getData()
      } else {
        setAlert({ visible: true, error: true, message: 'Erro ao realizar cadastro, contate o suporte.' })
      }
    } catch (err) {
      setAlert({ visible: true, error: true, message: 'Erro ao realizar cadastro, contate o suporte.' })
    }
    setLoadingRegister(false)
  }

  const getData = async (): Promise<void> => {
    setLoading(true)
    try {
      const response = await api.post('product/producaotrama/list', {
        idusuario: filtroTramador ? Number(filtroTramador) : null,
        datainicial: filtroData[0] ? filtroData[0] : null,
        datafinal: filtroData[1] ? filtroData[1] : null
      }, {
        headers: {
          'x-access-token': await getToken()
        }
      })
      setData(response.data)
    } catch (err) {
      setData([])
    }
    setLoading(false)
  }


  const deleteProd = async (id: number): Promise<void> => {
    setLoading(true)
    try {
      await api.delete(`product/producaotrama/${id}`, {
        headers: {
          'x-access-token': await getToken()
        }
      })
      getData()
      setAlert({ error: false, message: 'Sucesso ao excluir produção', visible: true })
    } catch (err) {
      setAlert({ error: true, message: 'Erro ao excluir produção', visible: true })
    }
    setLoading(false)
  }

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({ ...alert, visible: false })
      }, 3000)
    }
  }, [alert.visible])

  useEffect(() => {
    getProducts()
    getUsers()
    getFiliais()
  }, [])

  const dataParse = (data: any) => {
    if (data) {
      const newArr: any = [...data]
      let qtd = 0
      let val = 0
      for (let i of data) {
        qtd += Number(i.quantidade)
        val += Number(i.valor)
      }
      newArr.push({
        dataproducao: 'TOTAL',
        iditempedidovenda: '',
        filial: '',
        usuario: '',
        produto: '',
        quantidade: qtd,
        valor: val
      })
      return newArr
    } else {
      return []
    }
  }

  return (
    <>
      <SnackAlert
        alert={alert}
        align='center'
        position='top'
      />
      <FadeIn delay={200} transitionDuration={200}>
        <Card style={{ padding: '28px', marginTop: 30, borderRadius: 10 }}>
          <h2 style={{ textAlign: 'center' }}>{props?.selected?.descricao} {loading && (<CircularProgress size={17} />)}</h2>
          <Divider />
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            aria-label="simple tabs"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Formulário" {...a11yProps(0)} />
            <Tab label="Relatório" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={selectedTab} index={0} >
            {loadingRegister ? (<CircularProgress size={48} />) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ padding: '0px 258px' }}>
                  <h3>Incluir produção</h3>
                  <small style={{ margin: '18px 0' }}>Favor informar qual produto foi produzido.</small>
                </div>
                <div style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'flex-end',
                  padding: '28px 258px',
                  flexDirection: 'column',
                }}>

                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '50%' }}>
                      <DatePicker
                        label='Selecione a data'
                        value={dataInclusao}
                        change={(v: Date | null) => v && setDataInclusao(v)}
                      />
                    </div>
                    <div style={{ width: '50%' }}>
                      <ComboBox
                        value={selectedIdUsuario}
                        data={
                          users.filter((f: any) => f.idtipousuario === 6 && f.ativo).map((c: any) => {
                            return {
                              descricao: `${c.nome.toUpperCase()}`,
                              id: String(c.idusuario)
                            }
                          })
                        }
                        change={(v: any) => setSelectedIdUsuario(String(v.id))}
                        label='Selecione o tramador'
                      />
                    </div>
                  </div>
                  <div style={{ width: '100%', marginTop: 28 }}>
                    <Switch
                      size='small'
                      value={producaoPedido}
                      onChange={() => {
                        setProducaoPedido(!producaoPedido)
                        setPedidoInfo([])
                        setIdPedidoVenda('')
                      }}
                    />  Produção pedido
                  </div>
                  <div style={{ padding: '20px 0' }}>
                    <Divider />
                  </div>
                  {!producaoPedido ? (<>
                    <ComboBox
                      value={selectedIdfilial}
                      data={
                        filiais && filiais.map((c: any) => {
                          return {
                            descricao: `${c.descricao}`,
                            id: String(c.idfilial)
                          }
                        })
                      }
                      change={(e: any) => setSelectedIdfilial(e.id)}
                      label='Filial'
                    />
                    <div style={{ marginTop: 14 }} />
                    <ComboBox
                      value={selectedIdProduct}
                      data={products.filter(p => p.ativo).map(p => {
                        return { ...p, id: String(p.idproduto) }
                      })}
                      change={(v: IProduct) => {
                        if (v.idproduto) {
                          setSelectedIdProduct(String(v.idproduto))
                          getProductPrice(v.idproduto)
                        }
                      }}
                      label='Selecione o produto'
                    />
                    <div style={{ marginTop: 14 }} />
                    {productPrices.length > 0 && (<>
                      <ComboBox
                        value={selectedIdProductGrid}
                        data={
                          productPrices.filter(product => product.ativo).map((p) => {
                            return {
                              ...p,
                              id: String(p?.idprodutograde),
                              descricao: `TRAMA: ${p?.trama.toUpperCase()} - ALUMÍNIO: ${p?.coraluminio.toUpperCase()} - FIBRA: ${p?.corfibra.toUpperCase()}`
                            }
                          })
                        }
                        change={(p: any) => {
                          setSelectedIdProductGrid(p.id)
                          setValorProducao(p.valorproducao)
                        }}
                        label='Selecione a grade'
                      />
                      <div style={{ marginTop: 14 }} />
                      <TextField
                        size='small'
                        variant="standard"
                        margin="normal"
                        value={qtd}
                        fullWidth
                        type="number"
                        label="Quantidade"
                        onChange={(e) => setQtd(Number(e.target.value))}
                      />
                      <div style={{ marginTop: 14 }} />
                      <TextField
                        size='small'
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label="Valor produção total"
                        disabled
                        value={qtd * Number(valorProducao)}
                      />

                      <div style={{ width: '100%', marginTop: 28 }}>
                        <Switch
                          size='small'
                          value={incluirEstoque}
                          onChange={() => {
                            setIncluirEstoque(!incluirEstoque)
                          }}
                        />  Incluir no estoque
                      </div>

                    </>)}
                  </>) : (<>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <TextField
                        size='small'
                        variant="standard"
                        margin="normal"
                        style={{ width: 258 }}
                        label="Digite o número do pedido"
                        value={idPedidoVenda}
                        onChange={(e) => setIdPedidoVenda(e.target.value)}
                      />
                      <Button onClick={() => getPedidoInfo()} color='primary' variant='contained'>
                        <SearchOutlined /> Buscar
                      </Button>
                    </div>
                    <div style={{ width: '100%', padding: 28, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      {pedidoInfo[0]?.itempedidovenda && (<>
                        <h3 >Produtos do pedido:</h3>
                        <span >Selecione o produto desse pedido que foi produzido</span>
                        <DataTable
                          columns={[
                            {
                              name: 'idprodutograde',
                              selector: 'idprodutograde',
                              button: true,
                            },
                            {
                              name: 'Produto',
                              selector: 'produto',
                            },
                            {
                              name: 'Dimensões',
                              cell: (v: any) => <small>{v.altura + ' x ' + v.largura + ' x ' + v.profundidade}</small>,
                              button: true
                            },
                            {
                              name: 'Qtde. pedido',
                              selector: 'quantidade',
                              button: true,
                            },
                            {
                              name: 'Qtde. pendente',
                              cell: (obj: any) => {

                                // const calc = Number(obj.quantidade) - Number(
                                //   arrProdutosIntegracao ?
                                //     arrProdutosIntegracao.filter((o: any) => o.idprodutograde === obj.idprodutograde)[0]?.quantidade ?
                                //       arrProdutosIntegracao.filter((o: any) => o.idprodutograde === obj.idprodutograde)[0]?.quantidade : 0
                                //     :
                                //     obj.qtdproduzida ? obj.qtdproduzida : 0
                                // )

                                const calc = Number(obj.quantidade) - Number(
                                  obj.qtdproduzida ? obj.qtdproduzida
                                    :
                                    arrProdutosIntegracao ?
                                      arrProdutosIntegracao.filter((o: any) => o.idprodutograde === obj.idprodutograde)[0]?.quantidade ?
                                        arrProdutosIntegracao.filter((o: any) => o.idprodutograde === obj.idprodutograde)[0]?.quantidade : 0
                                      :
                                      0
                                )

                                return calc
                              },
                              button: true,
                            },
                            {
                              name: 'Qtde. produzida',
                              button: true,
                              cell: (obj: any) => (
                                <TextField
                                  placeholder=''
                                  value={
                                    arrProdutosIntegracao ?
                                      arrProdutosIntegracao.filter((o: any) => o.idprodutograde === obj.idprodutograde)[0]?.quantidade
                                      :
                                      obj.qtdproduzida ? obj.qtdproduzida : 0
                                  }
                                  type='number'
                                  onChange={e => {
                                    const value = e.target.value
                                    if (value > obj.quantidade) return setAlert({ error: true, message: 'Quantidade inválida', visible: true })

                                    const calc = Number(obj.quantidade) - Number(
                                      obj.qtdproduzida ? obj.qtdproduzida
                                        :
                                        arrProdutosIntegracao ?
                                          arrProdutosIntegracao.filter((o: any) => o.idprodutograde === obj.idprodutograde)[0]?.quantidade ?
                                            arrProdutosIntegracao.filter((o: any) => o.idprodutograde === obj.idprodutograde)[0]?.quantidade : 0
                                          :
                                          0
                                    )

                                    if (calc < 0) return setAlert({ error: true, visible: true, message: 'Quantidade inválida' })

                                    const newList: any = []
                                    if (arrProdutosIntegracao) {
                                      const others = arrProdutosIntegracao.filter((o: any) => o.idprodutograde !== obj.idprodutograde)
                                      newList.push(...[...others, {
                                        iditempedidovenda: Number(obj.iditempedidovenda),
                                        idprodutograde: Number(obj.idprodutograde),
                                        quantidade: Number(value),
                                        valor: Number(value) * Number(obj.valorproducao)
                                      }])
                                    } else {
                                      newList.push({
                                        iditempedidovenda: obj.iditempedidovenda,
                                        idprodutograde: obj.idprodutograde,
                                        quantidade: Number(value),
                                        valor: (Number(value) * Number(obj.valorproducao))?.toFixed(2)
                                      })
                                    }
                                    return setArrProdutosIntegracao(newList)
                                  }}
                                />
                              )
                            }
                          ]}
                          data={pedidoInfo[0]?.itempedidovenda}
                        />
                      </>)}
                    </div>
                  </>)}

                  <Button onClick={() => handleRegister()} variant='contained' color='primary' style={{ width: 250, marginTop: 58 }}>
                    Gravar
                  </Button>
                </div>
                <div style={{ marginTop: 50 }} />
              </div>
            )}
          </TabPanel>
          <TabPanel value={selectedTab} index={1} >
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0 158px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ paddingRight: 18 }}>
                  Filtros:
                </h3>
                <RangePicker value={filtroData} onChange={(v: any) => setFiltroData(v)} />
                <div style={{ width: 350, marginLeft: 8 }}>
                  <ComboBox
                    value={filtroTramador}
                    data={
                      users.filter((f: any) => f.idtipousuario === 6 && f.ativo).map((c: any) => {
                        return {
                          descricao: `${c.nome.toUpperCase()}`,
                          id: String(c.idusuario)
                        }
                      })
                    }
                    change={(v: any) => setFiltroTramador(String(v.id))}
                    label='Selecione o tramador'
                  />
                </div>
                <Button onClick={() => {
                  setSelectedTab(0)
                  setFiltroData([null, null])
                  setFiltroTramador('')
                  setTimeout(() => {
                    setSelectedTab(1)
                  },1)
                }} variant='outlined' color='secondary' style={{ marginLeft: 18 }}>
                  Limpar
                </Button>
                <Button onClick={() => getData()} variant='contained' color='primary' style={{ marginLeft: 18 }}>
                  <SearchOutlined /> Pesquisar
                </Button>
              </div>
              <div style={{ marginTop: 18 }} />
              <DataTable
                columns={[
                  {
                    name: 'Data produção',
                    cell: (d: any) => d.dataproducao === 'TOTAL' ? 'TOTAL' : d.dataproducao ? d.dataproducao.toString().split('T')[0] : '-',
                    button: true
                  },
                  {
                    name: 'Pedido',
                    selector: 'iditempedidovenda',
                    button: true
                  },
                  {
                    name: 'Filial',
                    selector: 'filial',
                    button: true
                  },
                  {
                    name: 'Tramador',
                    selector: 'usuario',
                    button: true
                  },
                  {
                    name: 'Produto',
                    cell: (p: any) => `${p.produto ? p.produto : ''}`
                  },
                  {
                    name: 'Quantidade',
                    selector: 'quantidade',
                    button: true
                  },
                  {
                    name: 'Total',
                    cell: (obj: any) => maskReais(obj.valor),
                    button: true
                  },
                  {
                    name: 'Excluir',
                    cell: (obj: any) => {
                      return obj.dataproducao !== 'TOTAL' && (
                        <IconButton color='secondary' onClick={() => deleteProd(obj.id)}>
                          <DeleteOutline />
                        </IconButton>
                      )
                    },
                    button: true
                  },
                ]}
                data={dataParse(data)}
              />
            </div>
          </TabPanel>
        </Card>
      </FadeIn>
    </>
  )
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default TramaReport
