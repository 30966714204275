import api from "../../services/api"
import ITrama from "../../types/ITrama"
import { getToken } from "../../util"

const _update = async (trama: ITrama): Promise<any> => {
  try {
    await api.put(`product/trama/update/${trama.idtrama}`,
      { ...trama },
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: "Sucesso ao atualizar trama" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao atualizar trama" }
  }
}

export default _update
