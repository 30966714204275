import api from "../../services/api"
import IUserTypes from "../../types/IUserTypes"
import { getToken } from "../../util"

const _register = async (userType: IUserTypes): Promise<any> => {
  try {
    const response = await api.post('user/type/register', { ...userType }, { headers: { 'x-access-token': await getToken() } })
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao registrar tipo usuário" }
  }
}

export default _register
