import api from "../../services/api"
import { getToken } from "../../util"

interface IReturn {
    error: boolean
    visible: boolean
    message: string
}

const _reset = async (idfilial: number): Promise<IReturn> => {
    try {
        await api.delete(`movement/delete/${idfilial}`, {
            headers: {
                'x-access-token': await getToken()
            }
        })
        return { error: false, visible: true, message: "Sucesso ao realizar movimentação!" }
    } catch (err) {
        return { error: true, visible: true, message: "Erro ao realizar movimentação!" }
    }
}

export default _reset
