import api from "../../services/api"
import IProduct from "../../types/IProduct"
import { getToken } from "../../util"

const _register = async (product: IProduct): Promise<any> => {
  try {
    await api.post('product/register', { ...product }, { headers: { 'x-access-token': await getToken() } })
    return { error: false, visible: true, message: "Sucesso ao cadastrar produto" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao cadastrar produto" }
  }
}

export default _register
