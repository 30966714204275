import React, { useState, useEffect, useRef } from 'react'
import handlersProduct from '../../handlers/Product'
import handlersUpload from '../../handlers/Upload'
import handlersProductPrice from '../../handlers/ProductPrice'
import handlersProductGrid from '../../handlers/ProductGrid'
import handlersProductInventory from '../../handlers/Inventory'
import handlersBranch from '../../handlers/Branch'
import handlersCustomer from '../../handlers/Customer'
import handlersTrial from '../../handlers/Trial'
import handlersSales from '../../handlers/Sales'
import handlersPaymentType from '../../handlers/PaymentType'
import handlersSaleOrigin from '../../handlers/SaleOrigin'
import Upload from '../../components/Upload'
import {
  makeStyles, TextField, Theme, Divider, Tooltip, CircularProgress,
  Drawer, Typography, Container, Card, Button, FormControlLabel,
  IconButton, Switch, FormControl, Checkbox, CardHeader, Stepper,
  Step, StepLabel, Radio, FormLabel, RadioGroup, TextareaAutosize,
  Tabs, Box, Tab
} from '@material-ui/core/'
import { Alert } from '@material-ui/lab/'
import {
  AddOutlined, DeleteOutlined, RefreshOutlined, EditOutlined,
  ShoppingCartOutlined, ShoppingCart, LockOutlined, InfoOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined
} from '@material-ui/icons/'
import { useForm, Controller } from "react-hook-form";
import DataTable from '../../components/DataTable'
import SnackAlert from '../../components/Alert'
import FadeIn from 'react-fade-in'
import ComboBox from '../../components/ComboBox'
import { getToken, maskReais } from '../../util'
import IProduct from '../../types/IProduct'
import IProductGrid from '../../types/IProductGrid'
import IBranch from '../../types/IBranch'
import ICustomer from '../../types/ICustomer'
import IProductPriceGrid from '../../types/IProductPriceGrid'
import Modal from '../../components/Modal'
import Customer from '../Customer'
import Camera from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import InputMoney from '../../components/InputMoney'
import ImagePreview from '../../components/ImagePreview'
import IInventory from '../../types/IInventory'
import ICart from '../../types/ICart'
import IParcela from '../../types/IParcela'
import successImg from '../../assets/success.png'
import ISale from '../../types/ISale'
import { stringify } from 'postcss'
import IPaymentTypes from '../../types/IPaymentTypes'
import ImgView from '../../components/ImgView'
import SalePdf from '../../components/SalePdf'
import ISaleOrigin from '../../types/ISaleOrigin'
import ISalesReport from '../../types/ISalesReport'
import IUser from '../../types/IUser'
import handlersUser from '../../handlers/User'
import IFiles from '../../types/IFiles'
import DatePicker from '../../components/DatePicker'
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Sales = (): React.ReactElement => {

  const [isVisibleModalPdf, setIsVisibleModalPdf] = useState(false)

  const [isVisibleModalFoto, setIsVisibleModalFoto] = useState(false)
  const [dataUri, setDataUri] = useState<string[]>([]);
  const [alert, setAlert] = useState({
    visible: false,
    message: '',
    error: false
  })

  const [reseted, setReseted] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingTrial, setLoadingTrial] = useState(false)
  const [checkedProducao, setCheckedProducao] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [users, setUsers] = useState<IUser[]>([])
  // STEP 0 >> PRODUTOS DO PEDIDO
  const [inventory, setInventory] = useState<IInventory[]>([])
  const [products, setProducts] = useState<IProduct[]>([])
  const [productGrid, setProductGrid] = useState<IProductGrid[]>([])
  const [branchs, setBranchs] = useState<IBranch[]>([])
  const [productPrices, setProductPrices] = useState<IProductPriceGrid[]>([])
  const [showStock, setShowStock] = useState(false);
  const [selectedIdProduct, setSelectedIdProduct] = useState('');
  const [selectedIdProductGrid, setSelectedIdProductGrid] = useState('');
  const [altura, setAltura] = useState('')
  const [largura, setLargura] = useState('')
  const [profundidade, setProfundidade] = useState('')
  const [checkedSobMedida, setCheckedSobMedida] = useState(false);
  const [valueProduct, setValueProduct] = useState('')
  const [qtd, setQtd] = useState(1);
  const [total, setTotal] = useState(0);
  const [selectedIdfilial, setSelectedIdfilial] = useState('');
  const [productDesc, setProductDesc] = useState('');
  const [cart, setCart] = useState<ICart[]>([])
  const [selectedIdUser, setSelectedIdUser] = useState('')

  // STEP 1 >> CLIENTE DO PEDIDO
  const [arrSaleOrigin, setArrSaleOrigin] = useState<ISaleOrigin[]>([])
  const [customers, setCustomers] = useState<ICustomer[]>([])
  const [selectedIdCliente, setSelectedIdCliente] = useState('')
  const [selectedIdOrigemPedido, setSelectedIdOrigemPedido] = useState('');
  const [isVisibleModalCrudCliente, setIsVisibleModalCrudCliente] = useState(false)
  const [obs, setObs] = useState('')

  // STEP 2 >> FORMAS DE PAGAMENTO DO PEDIDO
  const [selectedTipoPagamentoEntradaDesc, setSelectedTipoPagamentoEntradaDesc] = useState('');
  const [selectedTipoPagamentoDesc, setSelectedTipoPagamentoDesc] = useState('');
  const [selectedIdTipoPagamento, setSelectedIdTipoPagamento] = useState('');
  const [selectedIdTipoPagamentoEntrada, setSelectedIdTipoPagamentoEntrada] = useState('');
  const [selectedParcelas, setSelectedParcelas] = useState('');
  const [valorComDesconto, setValorComDesconto] = useState('');
  const [valorTotal, setValorTotal] = useState('');
  const [valorFrete, setValorFrete] = useState('');
  const [valorEntrada, setValorEntrada] = useState('');
  const [checkedPedidoPago, setCheckedPedidoPago] = useState(false);
  const [isVisibleModalTrial, setIsVisibleModalTrial] = useState(false);
  const [isVisibleModalParcelas, setIsVisibleModalParcelas] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [arrParcelas, setArrParcelas] = useState<IParcela[]>([])
  const [arrPaymentTypes, setArrPaymentTypes] = useState<IPaymentTypes[]>([])

  const [trial, setTrial] = useState('')
  const [errorTrial, setErrorTrial] = useState(false)
  const [trialValid, setTrialValid] = useState(false)
  const [trialId, setIdTrial] = useState('')
  const [idPedidoVenda, setIdPedidoVenda] = useState<number | undefined>(undefined)


  const [isProductSobMedida, setIsProductSobMedida] = useState(false)

  const [infosPdf, setInfosPdf] = useState<ISalesReport[]>([])

  const [selectedTab, setSelectedTab] = useState(0);

  const [arrFiles, setArrFiles] = useState<IFiles[]>([])

  const [qtdDias, setQtdDias] = useState(0)
  const [dataPrevista, setDataPrevista] = useState(new Date())
  const [dataInclusao, setDataInclusao] = useState(new Date())

  useEffect(() => {
    if (qtdDias > 0) {
      const newDt = new Date()
      newDt.setDate(new Date().getDate() + qtdDias)
      setDataPrevista(newDt)
    }
  }, [qtdDias])

  const classes = useStyles()
  const steps = getSteps();

  const resetInputsStep1 = () => {
    setInventory([])
    setProductGrid([])
    setProductPrices([])
    setShowStock(false);
    setSelectedIdProduct('');
    setSelectedIdProductGrid('');
    setAltura('')
    setLargura('')
    setProfundidade('')
    setCheckedSobMedida(false);
    setValueProduct('')
    setQtd(1);
    setTotal(0);
    setProductDesc('');
  }

  const getUsers = async (): Promise<void> => {
    const data = await handlersUser._list()
    setUsers(data)
  }

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const createSale = async (): Promise<void> => {

    setDisabledBtn(true)

    setAlert({
      error: true,
      visible: true,
      message: 'Gerando parcelas... aguarde...'
    })
    const _arrParcelas = await getParcelas()

    setTimeout(() => {
      console.log('timer 3seg')
    }, 3000)

    const user = localStorage.getItem('userInfo')

    if (user && _arrParcelas.length > 0) {

      const obj_user = JSON.parse(user)
      const { idfilial } = obj_user

      const sales_order: ISale = {
        valor_frete: valorFrete  && valorFrete !== '' ? Number(valorFrete) : null,
        idfilialsaida: selectedIdfilial === "" ? null : selectedIdfilial,
        idfilialorigem: idfilial,
        data_prevista: dataPrevista,
        data_pedido: dataInclusao,
        observacao: obs,
        valor_total: valorTotal,
        valor_comdesconto: valorComDesconto,
        idusuario: Number(selectedIdUser),
        idcliente: selectedIdCliente,
        quantidadeparcela: Number(selectedParcelas),
        idsituacaopedidovenda: checkedPedidoPago ? 2 : 1,
        producao: checkedProducao,
        entrada: valorEntrada !== "" && Number(valorEntrada) > 0,
        idorigempedido: Number(selectedIdOrigemPedido),
        idtrial: trialId !== '' ? Number(trialId) : null,
        itens: cart.map(p => {
          return {
            idprodutograde: p.idprodutograde,
            quantidade: p.quantidade,
            valor: p.valor,
            total: p.total,
            altura: p.altura,
            largura: p.largura,
            profundidade: p.profundidade,
            personalizado: p.personalizado,
            idfilialsaldo: p.idfilialsaldo
          }
        }),
        parcelas: _arrParcelas.map(p => {
          return {
            numeroparcela: p.parcela,
            valor: p.valor,
            idtipopagamento: p.idtipopagamento
          }
        }),
        arquivos: []
      }

      const createSale = await handlersSales._register(sales_order)

      if (createSale?.error) {
        setAlert({
          error: true,
          visible: true,
          message: 'Erro ao incluir pedido, contate o suporte.'
        })
      } else {
        console.log(createSale)
        setIdPedidoVenda(createSale.idpedidovenda)
        handleNext()
        setAlert({
          error: false,
          visible: true,
          message: 'Pedido incluido com sucesso!'
        })
      }
      setDisabledBtn(false)
      if (checkedPedidoPago) setIsVisibleModalFoto(true)
    }
  }

  const getParcelas = async (): Promise<IParcela[]> => {
    const qtd_parcelas = Number(selectedParcelas)

    const total = (Number(valorComDesconto) + Number(valorFrete)) - Number(valorEntrada)

    const valor_parcela = total / qtd_parcelas
    const arr_parcelas: IParcela[] = []
    const limit = qtd_parcelas + (valorEntrada !== '' && Number(valorEntrada) > 0 ? 1 : 0)

    for (let i = 1; i <= limit; i++) {
      if (i === 1 && valorEntrada !== '' && Number(valorEntrada) > 0) {
        arr_parcelas.push({
          parcela: i,
          valor: valorEntrada,
          tipo_pagamento_desc: selectedTipoPagamentoEntradaDesc,
          idtipopagamento: selectedIdTipoPagamentoEntrada
        })
      } else {
        arr_parcelas.push({
          parcela: i,
          valor: String(valor_parcela.toFixed(2)),
          tipo_pagamento_desc: selectedTipoPagamentoDesc,
          idtipopagamento: selectedIdTipoPagamento
        })
      }
    }

    setArrParcelas(arr_parcelas)
    return arr_parcelas
  }


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const getProducts = async (): Promise<void> => {
    const data = await handlersProduct._list()
    setProducts(data)
  }

  const getProductGrid = async (idproduto: number): Promise<void> => {
    const data = await handlersProductGrid._list(idproduto)
    setProductGrid(data)
  }

  const getProductPrice = async (idproduto: number): Promise<void> => {
    const data = await handlersProductPrice._listGrid(idproduto)
    setProductPrices(data)
  }

  const getInventory = async (idprodutograde: string): Promise<void> => {
    const data = await handlersProductInventory._list(idprodutograde)
    setInventory(data)
  }

  const getBranchs = async (): Promise<void> => {
    const data = await handlersBranch._list()
    setBranchs(data)
  }

  const getPaymentTypes = async (): Promise<void> => {
    const data = await handlersPaymentType._list()
    setArrPaymentTypes(data)
  }

  const getSaleOrigin = async (): Promise<void> => {
    const data = await handlersSaleOrigin._list()
    setArrSaleOrigin(data)
  }

  const getCustomers = async (): Promise<void> => {
    const data = await handlersCustomer._list()
    setCustomers(data)
  }

  const validateStep0 = () => [selectedIdfilial, selectedIdOrigemPedido, selectedIdCliente].every(_ => _ !== '')

  const validateStep1 = () => [qtd].every(_ => _ > 0)

  const handleAddItemCart = () => {

    const boolValidate = validateStep1()

    if (!boolValidate) return setAlert({ error: true, visible: true, message: 'Favor informar todos os campos' })

    const newcart: ICart[] = [...cart]
    const newitem: ICart = {
      idprodutograde: selectedIdProductGrid,
      quantidade: qtd,
      valor: Number(valueProduct),
      total: qtd * Number(valueProduct),
      altura,
      largura,
      profundidade,
      personalizado: checkedSobMedida,
      descricao: productDesc,
      idfilialsaldo: selectedIdfilial
    }
    newcart.push(newitem)
    setCart(newcart)
    resetInputsStep1()
    setReseted(false)
  }

  const handleDeleteItemCart = (item: ICart) => {
    const newcart: ICart[] = cart.filter(p => p !== item)
    setCart(newcart)
  }

  const getSaleInfo = (param: string): number => {

    if (param === 'quantidade_produtos') {
      let qtd = 0
      for (let i of cart) {
        qtd += i.quantidade
      }
      return qtd
    }

    if (param === 'valor_produtos') {
      let total = 0
      for (let i of cart) {
        total += i.total
      }
      return total
    }

    if (param === 'percentual_desconto') {
      let percent = 0.0
      let valor_produtos = 0
      for (let i of cart) {
        valor_produtos += i.total
      }
      let total = valor_produtos // - Number(valorEntrada || 0)
      let valor_desconto = total - Number(valorComDesconto)
      percent = valor_desconto / total * 100
      if (percent < 0) return 100
      return percent
    }

    return 0
  }

  const getValidateTrial = async (): Promise<void> => {
    setLoadingTrial(true)
    const validate = await handlersTrial._validate(trial)
    if (validate.error) {
      setLoadingTrial(false)
      setErrorTrial(true)
      setAlert({
        error: true,
        visible: true,
        message: validate?.message ? validate?.message : 'Trial inválido'
      })
    } else {
      setIdTrial(String(validate?.idtrial))
      setTrialValid(true)
      setErrorTrial(false)
      setIsVisibleModalTrial(false)
      setDisabledBtn(false)
      setLoadingTrial(false)
      setAlert({
        error: false,
        visible: true,
        message: 'Liberado'
      })

      await createSale()
      setActiveStep(3)
    }
  }

  const getInfoPdf = async (): Promise<void> => {
    if (idPedidoVenda) {
      const result = await handlersSales._listOne(idPedidoVenda)
      setInfosPdf(result)
    }
  }

  const getFiles = async (id: number): Promise<void> => {
    const list = await handlersUpload._list(id)
    setArrFiles(list)
  }

  useEffect(() => {
    const val = getSaleInfo('valor_produtos')
    setValorTotal(String(val))
    setValorComDesconto(String(val))
  }, [cart])

  useEffect(() => {
    getProducts()
    getBranchs()
    getCustomers()
    getPaymentTypes()
    getUsers()
    getSaleOrigin()
    const user = localStorage.getItem('userInfo')
    if (user) {
      const obj_user = JSON.parse(user)
      const { idusuario } = obj_user
      setSelectedIdUser(String(idusuario))
    }
  }, [])

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({ ...alert, visible: false })
      }, 3000)
    }
  }, [alert.visible])


  useEffect(() => {
    if (altura && largura && profundidade && selectedIdProductGrid) {
      try {
        const { altura: originalAltura, largura: originalLargura, profundidade: originalProfundidade } = products.filter((p: IProduct) => p.idproduto && p.idproduto === Number(selectedIdProduct))[0]
        const { valor: valorOriginal } = productPrices.filter((p: IProductPriceGrid) => p.idprodutograde && p.idprodutograde === Number(selectedIdProductGrid))[0]
        const valor_produto = Number(valorOriginal)
        const cubagem_original = Number(originalAltura) * Number(originalLargura) * Number(originalProfundidade)
        const cubagem_nova = Number(altura) * Number(largura) * Number(profundidade)
        const novo_valor = (cubagem_nova * (valor_produto / cubagem_original)).toFixed(2)
        setValueProduct(String(novo_valor))
      } catch (err) {
        setValueProduct('')
      }
    }
  }, [altura, largura, profundidade])


  useEffect(() => {
    if (!reseted) {
      setTimeout(() => {
        setReseted(true)
      }, 1)
    }
  }, [reseted])


  useEffect(() => {
    getInfoPdf()
  }, [idPedidoVenda])


  return (
    <>
      <SnackAlert
        alert={alert}
        align='center'
        position='top'
      />
      <Container maxWidth='xl'>
        <FadeIn delay={200} transitionDuration={200}>
          <Typography variant='h5' color='textSecondary'>
            Venda
          </Typography>
          <Card style={{ padding: 15, marginTop: 30, borderRadius: 10 }}>
            <CardHeader
              action={<></>}
              title='Pedido de venda'
            />
            <div style={{ padding: 25 }}>
              <div className={classes.root}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div style={{ width: '100%' }}>
                  {activeStep === steps.length ? (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center', padding: 58, justifyContent: 'center' }}>
                        <div>
                          <h2>Pedido finalizado com sucesso!  <img src={successImg} alt='Sucesso' width={24} /></h2>
                          {checkedPedidoPago && (
                            <p>
                              <Button variant='outlined' onClick={() => setIsVisibleModalFoto(true)}><CloudUploadOutlined /> &nbsp; Upload do Comprovante</Button>
                            </p>
                          )}
                        </div>

                        {/* <h4>id: {idPedidoVenda}</h4> */}
                      </div>
                      <Divider />
                      {infosPdf && infosPdf.length > 0 && (
                        <SalePdf showButtonNewSale={true} info={infosPdf} />
                      )}
                      <br />
                      <br />
                    </>
                  ) : (
                    <div>
                      {activeStep === 0 && (
                        <>
                          <div className="form-row" style={{ paddingLeft: 38 }}>
                            <div>
                              <FormControl component="fieldset" >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checkedProducao}
                                      onChange={() => {
                                        const newValue = !checkedProducao
                                        setCheckedProducao(newValue)
                                      }}
                                      name="gilad"
                                    />
                                  }
                                  label="Produção"
                                />
                              </FormControl>
                            </div>
                          </div>
                          <div className="form-row" style={{ padding: '10px 38px' }}>
                            <ComboBox
                              value={selectedIdfilial}
                              data={checkedProducao ?
                                branchs.filter(f => f.idfilial === 1).map(c => {
                                  return {
                                    descricao: `${c.descricao}`,
                                    id: String(c.idfilial)
                                  }
                                })
                                :
                                branchs.map(c => {
                                  return {
                                    descricao: `${c.descricao}`,
                                    id: String(c.idfilial)
                                  }
                                })
                              }
                              change={(e: any) => setSelectedIdfilial(e.id)}
                              label='Selecione a filial'
                            />
                            <ComboBox
                              value={selectedIdOrigemPedido}
                              data={arrSaleOrigin.map(c => {
                                return {
                                  ...c,
                                  id: String(c.idorigempedido)
                                }
                              })}
                              change={(v: any) => setSelectedIdOrigemPedido(v.id)}
                              label='Origem do pedido'
                            />
                            <ComboBox
                              value={selectedIdCliente}
                              data={customers.filter(c => c.ativo).map(c => {
                                return {
                                  ...c,
                                  id: String(c.idcliente),
                                  descricao: c.nome.toUpperCase()
                                }
                              })}
                              change={(v: any) => setSelectedIdCliente(v.idcliente)}
                              label='Selecione o Cliente'
                            />
                            <Button
                              variant='outlined'
                              color='primary'
                              onClick={() => setIsVisibleModalCrudCliente(true)}
                            >
                              <AddOutlined />
                            </Button>
                          </div>
                          <div className="form-row" style={{ padding: '10px 38px' }}>
                            <div style={{ width: '100%' }}>
                              <DatePicker
                                label='Data da venda'
                                value={dataInclusao}
                                change={(v: Date | null) => v && setDataInclusao(v)}
                              />
                            </div>
                            <div style={{ width: '100%', marginLeft: 4 }}>
                              <ComboBox
                                value={selectedIdUser}
                                data={
                                  users.filter(f => f.idtipousuario === 2 && f.ativo).map(c => {
                                    return {
                                      descricao: `${c.nome.toUpperCase()}`,
                                      id: String(c.idusuario)
                                    }
                                  })
                                }
                                change={(e: any) => setSelectedIdUser(e.id)}
                                label='Selecione o vendedor'
                              />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: 20, marginLeft: 6 }}>
                              <TextField
                                style={{ margin: 6 }}
                                size='small'
                                variant="standard"
                                margin="normal"
                                value={qtdDias}
                                fullWidth
                                type="number"
                                label="Qtd. dias para entrega"
                                onChange={(e) => setQtdDias(Number(e.target.value))}
                              />
                              <small style={{ marginLeft: 8, fontWeight: 'bold' }}>
                                {dataPrevista.toLocaleDateString()}
                              </small>
                            </div>
                            <div style={{ width: 200 }} />
                          </div>

                          <div style={{ marginTop: 28 }} />
                          <div style={{ padding: '0 36px' }}>
                            <TextareaAutosize
                              style={{ width: '100%' }}
                              aria-label="minimum height"
                              minRows={6}
                              value={obs}
                              onChange={(v: any) => setObs(v.target.value)}
                              placeholder="Observação"
                            />
                          </div>
                        </>
                      )}

                      {activeStep === 1 && (
                        <div style={{ padding: 58 }}>
                          {reseted && (
                            <>
                              <div className="form-row">
                                <ComboBox
                                  value={selectedIdProduct}
                                  data={products.filter(p => p.ativo).map(p => {
                                    return { ...p, id: String(p.idproduto) }
                                  })}
                                  change={(v: IProduct) => {
                                    if (v.idproduto) {
                                      setSelectedIdProduct(String(v.idproduto))
                                      setAltura(v.altura)
                                      setLargura(v.largura)
                                      setProfundidade(v.profundidade)
                                      setProductDesc(v.descricao)
                                      setIsProductSobMedida(v.personalizar)
                                      getProductGrid(v.idproduto)
                                      getProductPrice(v.idproduto)
                                      if (!v.personalizar) setCheckedSobMedida(false)
                                    }
                                  }}
                                  label='Selecione o produto'
                                />
                              </div>
                              <div className="form-row">
                                {productPrices.length > 0 && (
                                  <ComboBox
                                    value={selectedIdProductGrid}
                                    data={
                                      productPrices.filter(product => product.ativo).map((p) => {
                                        return {
                                          ...p,
                                          id: String(p?.idprodutograde),
                                          descricao: `TRAMA: ${p?.trama.toUpperCase()} - ALUMÍNIO: ${p?.coraluminio.toUpperCase()} - FIBRA: ${p?.corfibra.toUpperCase()} -  R$${maskReais(Number(p.valor))}`
                                        }
                                      })
                                    }
                                    change={(p: IProductPriceGrid) => {
                                      if (p.idprodutograde) {
                                        setSelectedIdProductGrid(String(p.idprodutograde))
                                        setValueProduct(String(p.valor))
                                        setShowStock(true)
                                        getInventory(String(p.idprodutograde))
                                        setProductDesc(`
                                          ${productDesc.toUpperCase()} - 
                                          TRAMA: ${p?.trama.toUpperCase()} - 
                                          ALUMÍNIO: ${p?.coraluminio.toUpperCase()} - 
                                          FIBRA: ${p?.corfibra.toUpperCase()}
                                        `)
                                      }
                                    }}
                                    label='Selecione a grade'
                                  />
                                )}
                              </div>
                            </>
                          )}
                          {selectedIdProductGrid && (
                            <>
                              <div className="form-row" style={{ marginTop: 18 }}>
                                <TextField
                                  size='small'
                                  style={{ margin: 6 }}
                                  variant="standard"
                                  disabled={!checkedSobMedida}
                                  margin="normal"
                                  value={altura}
                                  fullWidth
                                  type="number"
                                  label="A"
                                  onChange={(e) => setAltura(e.target.value)}
                                />
                                <TextField
                                  style={{ margin: 6 }}
                                  size='small'
                                  disabled={!checkedSobMedida}
                                  variant="standard"
                                  margin="normal"
                                  value={largura}
                                  type="number"
                                  fullWidth
                                  label="L"
                                  onChange={(e) => setLargura(e.target.value)}
                                />
                                <TextField
                                  style={{ margin: 6 }}
                                  disabled={!checkedSobMedida}
                                  size='small'
                                  variant="standard"
                                  margin="normal"
                                  value={profundidade}
                                  type="number"
                                  fullWidth
                                  label="P"
                                  onChange={(e) => setProfundidade(e.target.value)}
                                />
                                {isProductSobMedida && (
                                  <div>
                                    <div style={{ width: 168 }}>
                                      <FormControl component="fieldset" >
                                        <FormControlLabel
                                          control={<Checkbox checked={checkedSobMedida} onChange={() => setCheckedSobMedida(!checkedSobMedida)} name="gilad" />}
                                          label="Sob-medida"
                                        />
                                      </FormControl>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="form-row" style={{ marginTop: 18 }}>
                                <TextField
                                  style={{ margin: 6 }}
                                  size='small'
                                  variant="standard"
                                  margin="normal"
                                  value={maskReais(Number(valueProduct))}
                                  fullWidth
                                  disabled
                                  label="Preço unitário"
                                />
                                <TextField
                                  style={{ margin: 6 }}
                                  size='small'
                                  variant="standard"
                                  margin="normal"
                                  value={qtd}
                                  fullWidth
                                  type="number"
                                  label="Quantidade"
                                  onChange={(e) => setQtd(Number(e.target.value))}
                                />
                                <TextField
                                  style={{ margin: 6 }}
                                  size='small'
                                  variant="standard"
                                  margin="normal"
                                  value={maskReais(qtd * Number(valueProduct))}
                                  fullWidth
                                  disabled
                                  label="Valor Total"
                                />
                              </div>
                              {inventory && (
                                <>
                                  <div style={{ marginTop: 28 }} />
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend">Estoque</FormLabel>
                                    {inventory.map(f =>
                                      <p>{`${f.idfilial} - ${f?.cidade}-${f?.uf} (${f?.descricao}) -  SALDO: ${f.quantidade}`}</p>
                                    )}
                                  </FormControl>
                                </>
                              )}
                              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 8 }}>
                                <Button
                                  variant='contained'
                                  color='default'
                                  onClick={() => {
                                    // verificar se tem saldo

                                    const verify = inventory.filter(i => String(i.idfilial) === selectedIdfilial && i.quantidade >= qtd)

                                    if ((verify.length > 0 && !checkedProducao) || checkedProducao) {
                                      handleAddItemCart()
                                    } else {
                                      setAlert({
                                        error: true,
                                        visible: true,
                                        message: 'Produto sem estoque na filial selecionada. '
                                      })
                                    }

                                  }}
                                >
                                  <ShoppingCart /> Adicionar
                                </Button>
                              </div>
                            </>
                          )}
                          {cart.length > 0 && (
                            <DataTable
                              columns={[
                                {
                                  name: 'Descrição',
                                  cell: (v: ICart) => `${v.descricao}`
                                  // button: true,
                                },
                                {
                                  name: 'Dimensões',
                                  cell: (v: ICart) => v.altura + ' x ' + v.largura + ' x ' + v.profundidade,
                                  // button: true
                                },
                                {
                                  name: 'Sob-medida',
                                  selector: 'personalizado',
                                  cell: (v: ICart) => v.personalizado ? 'Sim' : 'Não',
                                  button: true
                                },
                                {
                                  name: 'Preço',
                                  cell: (v: ICart) => maskReais(v.valor),
                                  button: true
                                },
                                {
                                  name: 'Qtde',
                                  selector: 'quantidade',
                                  sortable: true,
                                  button: true
                                },
                                {
                                  name: 'Total',
                                  button: true,
                                  selector: 'total',
                                  cell: (v: ICart) => maskReais(v.total),
                                },
                                {
                                  name: '',
                                  cell: (v: ICart) => <>
                                    <Button
                                      color='primary'
                                      onClick={() => handleDeleteItemCart(v)}
                                    >
                                      <DeleteOutlined style={{ fontSize: 18 }} />
                                    </Button>
                                  </>
                                },
                              ]}
                              data={cart}
                            />
                          )}

                        </div>
                      )}

                      {activeStep === 2 && (
                        <div style={{ padding: 48 }}>
                          <div className="form-row" style={{ paddingTop: 12, position: 'relative' }}>
                            <ComboBox
                              value={selectedIdTipoPagamento}
                              data={arrPaymentTypes.map(t => { return { ...t, id: String(t.idtipopagamento) } })}
                              change={(v: any) => {
                                setSelectedIdTipoPagamento(v.id)
                                setSelectedTipoPagamentoDesc(v.descricao)
                              }}
                              label='Tipo de pagamento'
                            />
                            <ComboBox
                              value={selectedParcelas}
                              data={Array(12).fill(1).map((_, index) => { return { id: String(index + 1), descricao: String(index + 1) } })}
                              change={async (v: any) => {
                                setSelectedParcelas(v.id)
                                await getParcelas()
                              }}
                              label='Quantidade de parcelas'
                            />
                            <div style={{
                              position: 'absolute',
                              display: 'flex',
                              right: -32,
                              width: 35,
                              height: 40,
                              alignContent: 'center',
                              borderRadius: 25,
                              top: 30
                            }}>
                              <Tooltip title="Visualizar parcelas">
                                <Button
                                  color='primary'
                                  onClick={async () => {
                                    await getParcelas()
                                    setIsVisibleModalParcelas(true)
                                  }}
                                  style={{ borderRadius: 25 }}
                                  disabled={selectedParcelas === ""}
                                >
                                  <InfoOutlined style={{ fontSize: 18 }} />
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="form-row" style={{ paddingTop: 12 }}>
                            <ComboBox
                              value={selectedIdTipoPagamentoEntrada}
                              data={arrPaymentTypes.map(t => { return { ...t, id: String(t.idtipopagamento) } })}
                              change={(v: any) => {
                                setSelectedIdTipoPagamentoEntrada(v.id)
                                setSelectedTipoPagamentoEntradaDesc(v.descricao)
                              }}
                              label='Tipo de pagamento da entrada'
                            />
                            <InputMoney
                              value={valorEntrada}
                              onChange={(v: any) => setValorEntrada(v)}
                              label='Valor da entrada'
                            />
                          </div>
                          <div className="form-row" style={{ paddingTop: 12, position: 'relative' }}>
                            <TextField
                              style={{ marginRight: 2 }}
                              size='small'
                              variant="standard"
                              margin="normal"
                              value={maskReais(Number(valorTotal))}
                              fullWidth
                              disabled
                              label="Valor total"
                            />
                            <InputMoney
                              value={valorComDesconto}
                              onChange={(v: any) => setValorComDesconto(v)}
                              label='Valor com desconto'
                            />
                            <small style={{ position: 'absolute', right: 0, bottom: -10 }}>
                              {getSaleInfo('percentual_desconto').toFixed(2)} %
                            </small>

                          </div>
                          <div className="form-row" style={{ paddingTop: 12 }}>
                            <ComboBox
                              value={!checkedPedidoPago ? '1' : '2'}
                              data={[
                                {
                                  id: '1', descricao: 'SEM PAGAMENTO'
                                },
                                {
                                  id: '2', descricao: 'PAGO'
                                }
                              ]}
                              change={(d: any) => setCheckedPedidoPago(d.id === '2' ? true : false)}
                              label='Status do pagamento'
                            />
                            <InputMoney
                              value={valorFrete}
                              onChange={(v: any) => setValorFrete(v)}
                              label='Valor frete'
                            />
                          </div>
                        </div>
                      )}
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 18 }}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.backButton}
                          >
                            Voltar
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={async () => {


                              if (valorComDesconto && activeStep === 2 && !trialValid) {

                                const valida_desconto_a_prazo = Number(selectedParcelas) > 1 && getSaleInfo('percentual_desconto') > 2

                                const valida_desconto_a_vista = Number(selectedParcelas) === 1 && getSaleInfo('percentual_desconto') > 16


                                const userInfo = localStorage.getItem('userInfo')
                                let verifyUserType = true
                                if (userInfo) {
                                  const userInfoParsed = JSON.parse(userInfo)
                                  // 1: administrador | 4: financeiro
                                  if ([1, 4].includes(userInfoParsed.idtipousuario)) {
                                    verifyUserType = false
                                  }
                                }

                                if ((valida_desconto_a_prazo || valida_desconto_a_vista) && verifyUserType) {
                                  setIsVisibleModalTrial(true)
                                  return
                                } else {
                                  setIsVisibleModalTrial(false)
                                }
                              }
                              if (activeStep === 2) {
                                await createSale()
                              } else {

                                if (activeStep === 0) {
                                  const boolValidateStep0 = validateStep0()
                                  if (boolValidateStep0) {
                                    handleNext()
                                  } else {
                                    setAlert({
                                      error: true,
                                      visible: true,
                                      message: 'Favor preencher todos os campos'
                                    })
                                  }
                                } else {
                                  handleNext()
                                }
                              }
                            }}
                            disabled={
                              // activeStep === 2 && ((Number(selectedParcelas) > 1 && getSaleInfo('percentual_desconto') > 1) || (Number(selectedParcelas) === 1 && getSaleInfo('percentual_desconto') > 16))
                              // ||
                              (activeStep === 1 && cart.length === 0) || disabledBtn
                            }
                          >
                            {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </FadeIn>
      </Container>

      <Modal
        open={isVisibleModalParcelas}
        onClose={() => {
          setIsVisibleModalParcelas(false)
          // getCustomers()
        }}
        children={
          <>
            <h2>Parcelas</h2>
            <Divider />
            <DataTable
              columns={[
                {
                  name: 'Parcela',
                  selector: 'parcela',
                  sortable: true
                },
                {
                  name: 'Tipo pagamento',
                  selector: 'tipo_pagamento_desc',
                  sortable: true
                },
                {
                  name: 'Valor',
                  selector: 'valor',
                  cell: (p: IParcela) => maskReais(Number(p.valor)),
                  sortable: true
                }
              ]}
              data={arrParcelas}
            />
          </>
        }
      />
      <Modal
        open={isVisibleModalCrudCliente}
        onClose={() => {
          setIsVisibleModalCrudCliente(false)
          getCustomers()
        }}
        children={
          <>
            <Customer />
          </>
        }
      />
      <Modal
        open={isVisibleModalTrial}
        onClose={() => {
          setIsVisibleModalTrial(false)
        }}
        children={
          <>
            <div style={{ padding: 28 }}>
              <h2>Atenção</h2>
              <h4>O Limite de desconto foi ultrapassado, favor insira o trial para liberação</h4>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 28 }}>
                <TextField
                  size='small'
                  variant="standard"
                  margin="normal"
                  value={trial}
                  error={errorTrial}
                  helperText={errorTrial && "Trial inválido, tente novamente"}
                  fullWidth
                  label="Digite o trial"
                  onChange={(e) => setTrial(e.target.value)}
                />
                <div style={{ marginTop: 8 }}>
                  <Button
                    color='primary'
                    disabled={loadingTrial}
                    onClick={async () => {
                      await getValidateTrial()
                    }}
                  >
                    {loadingTrial ? <CircularProgress style={{ fontSize: 22 }} /> : (<LockOutlined style={{ fontSize: 22 }} />)}
                  </Button>
                </div>
              </div>
            </div>
          </>
        }
      />
      <Modal
        open={isVisibleModalFoto}
        onClose={() => {
          if (checkedPedidoPago && arrFiles.length === 0) {
            setIsVisibleModalFoto(true)
          } else {
            setIsVisibleModalFoto(false)
          }
        }}
        children={
          <>
            <h2>Adicionar arquivos do pedido</h2>
            <Divider />
            <div className={classes.rootTabs} style={{ marginTop: 18 }}>
              <div>
                <Tabs
                  value={selectedTab}
                  onChange={handleChangeTab}
                  aria-label="simple tabs example"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="COMPROVANTES DE PAGAMENTO" {...a11yProps(0)} />
                  <Tab label="BOLETOS" {...a11yProps(1)} />
                </Tabs>
              </div>
              <TabPanel value={selectedTab} index={0}>
                <Upload
                  tipo='C'
                  refresh={
                    idPedidoVenda ?
                      async () => {
                        await getFiles(idPedidoVenda)
                        setAlert({ error: false, visible: true, message: 'Upload realizado com sucesso.' })
                        setIsVisibleModalFoto(true)
                      }
                      :
                      () => { }
                  }
                  idpedidovenda={idPedidoVenda}
                  onClose={() => setIsVisibleModalFoto(true)}
                  handleCloseModalPai={() => setIsVisibleModalFoto(false)}
                />
                <div style={{ margin: '10px 0' }} />
                <DataTable
                  columns={[
                    {
                      name: 'Arquivo',
                      selector: 'nome'
                    },
                    {
                      name: 'Download',
                      cell: (v: any) => (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            var a = document.createElement("a"); //Create <a>
                            a.href = v.arquivo; //Image Base64 Goes here
                            a.download = v.nome; //File name Here
                            a.click(); //Downloaded file
                          }}
                        >
                          <CloudDownloadOutlined />
                        </Button>
                      ),
                      button: true
                    }
                  ]}
                  data={arrFiles?.filter(a => a.tipo === 'C')}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <div style={{ margin: '10px 0' }} />
                <Upload
                  tipo='B'
                  refresh={
                    idPedidoVenda ?
                      async () => {
                        await getFiles(idPedidoVenda)
                        setAlert({ error: false, visible: true, message: 'Upload realizado com sucesso.' })
                        setIsVisibleModalFoto(true)
                      }
                      :
                      () => { }
                  }
                  idpedidovenda={idPedidoVenda}
                  onClose={() => setIsVisibleModalFoto(true)}
                  handleCloseModalPai={() => setIsVisibleModalFoto(false)}
                />
                <div style={{ margin: '10px 0' }} />
                <DataTable
                  columns={[
                    {
                      name: 'Arquivo',
                      selector: 'nome'
                    },
                    {
                      name: 'Download',
                      cell: (v: any) => (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            var a = document.createElement("a"); //Create <a>
                            a.href = v.arquivo; //Image Base64 Goes here
                            a.download = v.nome; //File name Here
                            a.click(); //Downloaded file
                          }}
                        >
                          <CloudDownloadOutlined />
                        </Button>
                      ),
                      button: true
                    }
                  ]}
                  data={arrFiles?.filter(a => a.tipo === 'B')}
                />
              </TabPanel>
            </div>
          </>
        }
      />
    </>
  )
}

const getSteps = () => {
  return ['Cliente / Filial', 'Produtos do pedido', 'Forma de pagamento'];
}

const getStepContent = (stepIndex: number) => {
  switch (stepIndex) {
    case 0:
      return 'Cliente / Filial';
    case 1:
      return 'Produtos do pedido'
    case 2:
      return 'Forma de pagamento';
    default:
      return '-';
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  rootTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default Sales
