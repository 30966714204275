import React, { useState, useEffect } from 'react'
import handlersCustomer from '../../handlers/Customer'
import {
  makeStyles, TextareaAutosize, TextField, Theme,
  Divider, Tooltip, CircularProgress, Drawer,
  Typography, Container, Card, Button, IconButton,
  Switch, CardHeader
} from '@material-ui/core/'
import { Alert } from '@material-ui/lab/'
import { AddOutlined, DeleteOutlined, RefreshOutlined, EditOutlined } from '@material-ui/icons/'
import { useForm, Controller } from "react-hook-form";
import DataTable from '../../components/DataTable'
import SnackAlert from '../../components/Alert'
import FadeIn from 'react-fade-in'
import api from '../../services/api';
import ICustomer from '../../types/ICustomer'
import ComboBox from '../../components/ComboBox';
import { getToken } from '../../util';
import axios from 'axios'

const blankForm = {
  idcliente: 0,
  nome: '',
  email: '',
  endereco: '',
  numero: 0,
  cidade: '',
  bairro: '',
  estado: '',
  cep: 0,
  telefone: '',
  celular: '',
  juridico: false,
  cnpj_cpf: '',
  observacao: '',
  ativo: true,
  fornecedor: false
}

const Customer = (): React.ReactElement => {


  const [filtro, setFiltro] = useState('0')

  const [juridico, setJuridico] = useState(false)
  const [fornecedor, setFornecedor] = useState(false)
  const [loading, setLoading] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [customers, setCustomers] = useState<ICustomer[]>([])
  const [edit, setEdit] = useState<ICustomer>(blankForm)
  const [alert, setAlert] = useState({
    visible: false,
    message: '',
    error: false
  })
  const [loadingLocale, setLoadingLocale] = useState(false)
  const { handleSubmit, errors, control, setValue } = useForm();
  const classes = useStyles()

  useEffect(() => {
    getCustomers()
  }, [])

  const getCustomers = async (): Promise<void> => {
    const data = await handlersCustomer._list()
    setCustomers(data.sort((a, b) => a.idcliente && b.idcliente ? a.idcliente - b.idcliente : 0))
  }

  const handleDelete = async (idcliente: number, cnpj_cpf: string, ativo: boolean): Promise<void> => {
    const deleteCustomer = await handlersCustomer._delete(idcliente, cnpj_cpf, ativo)
    setAlert(deleteCustomer)
    await getCustomers()
  }

  const handleRegister = async (customer: ICustomer): Promise<void> => {
    const register = edit.idcliente ?
      await handlersCustomer._update({ ...customer, idcliente: edit.idcliente }, juridico, fornecedor)
      :
      await handlersCustomer._register(customer, juridico, fornecedor)
    if (register.error) {
      setAlert(register)
    } else {
      setAlert({
        message: "Sucesso ao incluir registro.",
        visible: true,
        error: false
      })
    }
    setDrawerVisible(false)
    getCustomers()
    setEdit(blankForm)
  }

  const handleEdit = (obj: ICustomer) => {
    setEdit(obj)
    setJuridico(obj.juridico)
    setFornecedor(obj.fornecedor)
    setDrawerVisible(true)
  }

  const verifyCpf = (cpf: string): boolean => {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
      !cpf ||
      cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
    ) {
      return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(10, 11))) return false
    return true
  }



  const getLocale = async (cep: string): Promise<void> => {
    setLoadingLocale(true)
    const locale = await axios.get(`https://viacep.com.br/ws/${cep}/json`)
    setTimeout(() => {
      setValue('cidade', locale.data?.localidade)
      setValue('estado', locale.data?.uf)
      setValue('endereco', locale.data?.logradouro)
      setValue('bairro', locale.data?.bairro)
      setLoadingLocale(false)
    }, 5000)
  }

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({ ...alert, visible: false })
      }, 3000)
    }
  }, [alert.visible])

  return (
    <>
      <SnackAlert
        alert={alert}
        align='center'
        position='top'
      />
      <Container maxWidth='xl'>
        <FadeIn delay={200} transitionDuration={200}>
          <Typography variant='h5' color='textSecondary'>
            Cadastro de cliente e fornecedores
          </Typography>
          <Card style={{ padding: 15, marginTop: 30, borderRadius: 10 }}>
            <CardHeader
              action={
                <>
                  <Tooltip title='Atualizar'>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={async () => await getCustomers()}
                      style={{ borderRadius: '50%', height: 60 }}
                    >
                      <RefreshOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title='Cadastrar novo cliente/fornecedor'>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={() => {
                        setDrawerVisible(true)
                        setEdit(blankForm)
                      }}
                      style={{ borderRadius: '50%', height: 60 }}
                    >
                      <AddOutlined />
                    </Button>
                  </Tooltip>
                </>
              }
              title={(
                <>
                  <div style={{ width: 322 }}>
                    <ComboBox
                      value={filtro}
                      data={[
                        {
                          id: '0',
                          descricao: 'TODOS'
                        },
                        {
                          id: '1',
                          descricao: 'Clientes'
                        },
                        {
                          id: '2',
                          descricao: 'Fornecedores'
                        }
                      ]}
                      change={(v: any) => setFiltro(v.id)}
                      label='Filtro'
                    />
                  </div>
                </>
              )}
            />
            <div style={{ zoom: '90%' }}>
              <DataTable
                search
                columns={[
                  {
                    name: 'Nome',
                    cell: (obj: ICustomer) => obj.nome,
                    sortable: true,
                  },
                  {
                    name: 'CPF / CNPJ',
                    cell: (obj: ICustomer) => obj.cnpj_cpf,
                    sortable: true,
                  },
                  {
                    name: 'Contato',
                    cell: (obj: ICustomer) => `${obj.celular ? obj.celular : obj.telefone}`
                  },
                  {
                    name: 'Endereço',
                    selector: 'endereco',
                    sortable: true
                  },
                  {
                    name: 'Cidade',
                    cell: (obj: ICustomer) => obj.cidade + '-' + obj.estado
                  },
                  {
                    name: 'E-mail',
                    selector: 'email',
                    sortable: true
                  },
                  {
                    name: 'Observação',
                    cell: (obj: ICustomer) => {
                      return (
                        <TextareaAutosize
                          style={{ width: '100%' }}
                          aria-label="minimum height"
                          minRows={2}
                          value={obj.observacao}
                          disabled
                        />
                      )
                    }
                  },
                  {
                    name: 'Status',
                    selector: 'ativo',
                    button: true,
                    cell: (obj: ICustomer) => obj.ativo ? <Alert severity="success"></Alert> : <Alert severity="error"></Alert>
                  },
                  {
                    button: true,
                    cell: (obj: ICustomer) => <IconButton aria-label="editar" onClick={() => handleEdit(obj)}><EditOutlined fontSize="small" /></IconButton>
                  },
                  {
                    button: true,
                    cell: (obj: ICustomer) => <IconButton aria-label="excluir" onClick={async () => await handleDelete(obj.idcliente, obj.cnpj_cpf, obj.ativo)}><DeleteOutlined fontSize="small" /></IconButton>
                  }
                ]}
                data={customers.filter((d, i) => {
                  if (filtro === '0') {
                    return true
                  }
                  if (filtro === '1') {
                    return d.fornecedor === false
                  }
                  if (filtro === '2') {
                    return d.fornecedor === true
                  }
                })}
              />
            </div>
          </Card>
        </FadeIn>
      </Container>

      <Drawer
        open={drawerVisible}
        anchor='right'
        onClose={() => setDrawerVisible(false)}
        style={{ zIndex: 9999999 }}
      >
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={handleSubmit(handleRegister)}
          style={{ width: 600 }}
        >
          <Typography variant='h5'>
            {edit.idcliente > 0 ? 'Editando' : 'Cadastrando'}  cliente/fornecedor
          </Typography>
          <Divider style={{ margin: '5px 0' }} />
          <Controller
            name='nome'
            defaultValue={edit.nome}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.nome}
                helperText={errors.nome && "Campo obrigatório"}
                variant="standard"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o nome"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name='email'
            defaultValue={edit.email}
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.email}
                helperText={errors.email && "Campo obrigatório"}
                variant="standard"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o e-mail"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name='cnpj_cpf'
            defaultValue={edit.cnpj_cpf}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.cnpj_cpf || (juridico ? false : !verifyCpf(value))}
                helperText={errors.cnpj_cpf && "CPF Inválido"}
                variant="standard"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o CNPJ / CPF"
                onChange={(e) => onChange(e.target.value)}
                onBlur={(e) => {
                  const validate = juridico ? false : !verifyCpf(e.target.value)
                  if (validate) {
                    window.alert('CPF Inválido, favor tente novamente')
                  }
                }}
              />
            )}
          />
          <Controller
            name='cep'
            defaultValue={edit.cep}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.cep}
                helperText={errors.cep && "Campo obrigatório"}
                variant="standard"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o cep"
                onChange={(e) => onChange(e.target.value)}
                onBlur={async (e) => await getLocale(e.target.value)}
              />
            )}
          />
          <Controller
            name='cidade'
            defaultValue={edit.cidade}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  size='small'
                  error={errors.cidade}
                  helperText={errors.cidade && "Campo obrigatório"}
                  variant="standard"
                  margin="normal"
                  value={value}
                  fullWidth
                  label="Cidade"
                  onChange={(e) => onChange(e.target.value)}
                />
                {/* {loadingLocale && (<CircularProgress size={26} color="primary" />)} */}
              </div>
            </>}
          />
          <Controller
            name='estado'
            defaultValue={edit.estado}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  size='small'
                  error={errors.estado}
                  helperText={errors.estado && "Campo obrigatório"}
                  variant="standard"
                  margin="normal"
                  value={value}
                  fullWidth
                  label="Estado"
                  onChange={(e) => onChange(e.target.value)}
                />
                {/* {loadingLocale && (<CircularProgress size={26} color="primary" />)} */}
              </div>
            </>}
          />
          <Controller
            name='endereco'
            defaultValue={edit.endereco}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  size='small'
                  error={errors.endereco}
                  helperText={errors.endereco && "Campo obrigatório"}
                  variant="standard"
                  margin="normal"
                  value={value}
                  fullWidth
                  label="Endereço"
                  onChange={(e) => onChange(e.target.value)}
                />
                {/* {loadingLocale && (<CircularProgress size={26} color="primary" />)} */}
              </div>
            </>}
          />
          <Controller
            name='numero'
            defaultValue={edit.numero}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.numero}
                helperText={errors.numero && "Campo obrigatório"}
                variant="standard"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o número"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name='bairro'
            defaultValue={edit.bairro}
            control={control}
            render={({ onChange, onBlur, value }) => <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  size='small'
                  error={errors.bairro}
                  helperText={errors.bairro && "Campo obrigatório"}
                  variant="standard"
                  margin="normal"
                  value={value}
                  fullWidth
                  label="Digite o bairro"
                  onChange={(e) => onChange(e.target.value)}
                />
                {/* {loadingLocale && (<CircularProgress size={26} color="primary" />)} */}
              </div>
            </>}
          />
          <Controller
            name='telefone'
            defaultValue={edit.telefone}
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.telefone}
                helperText={errors.telefone && "Campo obrigatório"}
                variant="standard"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o telefone"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name='celular'
            defaultValue={edit.celular}
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.celular}
                helperText={errors.celular && "Campo obrigatório"}
                variant="standard"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o celular"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />

          <Controller
            name='observacao'
            defaultValue={edit.observacao}
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextareaAutosize
                style={{ width: '100%', marginTop: 18 }}
                aria-label="minimum height"
                minRows={3}
                value={value}
                placeholder="Digite uma observacão"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />

          <div style={{ marginTop: 12 }}>
            <Switch
              size='small'
              value={juridico}
              checked={juridico}
              onChange={() => setJuridico(!juridico)}
            />  {juridico ? 'Pessoa Juridica' : 'Pessoa Fisica'}
          </div>
          <div style={{ marginTop: 12 }}>
            <Switch
              size='small'
              value={fornecedor}
              checked={fornecedor}
              onChange={() => setFornecedor(!fornecedor)}
            />
            <span>Fornecedor ?</span>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
            <Button
              size='small'
              type="reset"
              fullWidth
              variant="contained"
              color="secondary"
              style={{ margin: '0 10px' }}
              className={classes.submit}
              onClick={() => setDrawerVisible(false)}
            >
              Cancelar
            </Button>
            <Button
              size='small'
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ margin: '0 10px' }}
              className={classes.submit}
              disabled={errors.idcliente || errors.nome || loading}
            >
              {loading ? <CircularProgress size={26} color="inherit" /> : 'Gravar'}
            </Button>
          </div>
        </form>
      </Drawer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default Customer
