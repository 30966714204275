import React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide
} from "@material-ui/core/"
import { TransitionProps } from "@material-ui/core/transitions"
import './styles.css'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface IProps {
  open: true | false
  onClose: any
  children: any
}

const Modal: React.FC<IProps> = (props) => {
  const open = props.open

  const handleClose = () => {
    props.onClose()
  }

  return (
    <Dialog
      style={{ zIndex: 9999999 }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <div style={{ width: '100%' }}>
          {props.children}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Modal
