import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'

import PageLayout from './components/Layout'
import Login from './pages/Login'
import UserPage from './pages/User'
import CustomerPage from './pages/Customer'
import BranchPage from './pages/Branch'
import ProductPage from './pages/Product'
import SalesPage from './pages/Sales'
import TrialPage from './pages/Trial'
import ReportPage from './pages/Report'
import Page404 from './pages/Page404'

const Routes = (): React.ReactElement => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/"  component={Login} />
        <PageLayout>
          <Route exact path="/admin/user"  component={UserPage} />
          <Route exact path="/admin/customer"  component={CustomerPage} />
          <Route exact path="/admin/dashboard"  component={Page404} />
          <Route exact path="/admin/sales"  component={SalesPage} />
          <Route exact path="/admin/report"  component={ReportPage} />
          <Route exact path="/admin/stock"  component={Page404} />
          <Route exact path="/admin/expedition"  component={Page404} />
          <Route exact path="/admin/product" component={ProductPage} />
          <Route exact path="/admin/branchs"  component={BranchPage} />
          <Route exact path="/admin/trial"  component={TrialPage} />
        </PageLayout>
        <Route path="*" component={Page404} />
      </Switch>
    </HashRouter>
  )
}

export default Routes
