import api from "../../services/api"
import { getToken } from "../../util"

const _syncTiny = async (idprodutograde: number, tiny: boolean): Promise<any> => {
  try {
    if (tiny) {
      await api.get(
        `product/productgrade/integrar/${idprodutograde}/true`,
        { headers: { 'x-access-token': await getToken() } }
      )
      return { error: false, visible: true, message: "Sucesso ao integrar produto" }
    }
    await api.get(
      `product/productgrade/integrar/${idprodutograde}`,
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: "Sucesso ao integrar produto" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao integrar produto" }
  }
}

export default _syncTiny
