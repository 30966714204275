import api from "../../services/api"
import { getToken } from "../../util"

interface IIntegrador {
  error?: boolean
  visible?: boolean
  message?: string
}

const integrador = async (idpedidovenda: number, empresa: string): Promise<IIntegrador> => {
  try {
    await api.get(`/sale/tiny/${idpedidovenda}/${empresa}`,
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: "Sucesso ao integrar pedido" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao integrar pedido" }
  }
}

export default integrador
