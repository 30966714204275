import React, { useState, useEffect, useRef } from 'react'
import handlersSales from '../../../handlers/Sales'
import handlersUser from '../../../handlers/User'
import handlersUpload from '../../../handlers/Upload'
import handlersBranch from '../../../handlers/Branch'
import handlersSaleComment from '../../../handlers/SaleComment'
import handlersSaleOrigin from '../../../handlers/SaleOrigin'
import handlersCustomer from '../../../handlers/Customer'
import handlersTiny from '../../../handlers/Tiny'
import { DateRange } from '@mui/lab/DateRangePicker';
import {
  makeStyles, TextField, Theme, Divider, Tooltip,
  CircularProgress, Drawer, Typography, Container,
  Card, Button, IconButton, Switch, CardHeader,
  Breadcrumbs, Badge, List, ListItem, ListItemText,
  AppBar, TextareaAutosize, Tabs, Tab, Box, FormControl,
  FormLabel, RadioGroup, FormControlLabel, Radio, InputAdornment
} from '@material-ui/core/'
import { Alert } from '@material-ui/lab/'
import {
  AddOutlined, DeleteOutlined, RefreshOutlined,
  EditOutlined, ShoppingCartOutlined, ShoppingCart,
  ListOutlined, AttachFileOutlined, ChatOutlined,
  SearchOutlined, CloudDownloadOutlined, CloudUploadOutlined,
  SyncOutlined, ClearOutlined, PersonOutline
} from '@material-ui/icons/'
import { getToken, onlyUnique } from "../../../util"
import DataTable from '../../../components/DataTable'
import SnackAlert from '../../../components/Alert'
import RangePicker from '../../../components/RangePicker'
import FadeIn from 'react-fade-in'
import ISalesReport from '../../../types/ISalesReport'
import { maskReais } from '../../../util'
import ISituation from '../../../types/ISituations'
import Modal from '../../../components/Modal'
import IParcela from '../../../types/IParcela'
import ICart from '../../../types/ICart'
import './styles.css'
import ISaleComment from '../../../types/ISaleComment'
import ImgView from '../../../components/ImgView'
import Upload from '../../../components/Upload'
import IFiles from '../../../types/IFiles'
import ComboBox from '../../../components/ComboBox'
import IBranch from '../../../types/IBranch'
import ISaleOrigin from '../../../types/ISaleOrigin'
import ICustomer from '../../../types/ICustomer'
import ISituations from '../../../types/ISituations'
import IUser from '../../../types/IUser'
import api from '../../../services/api'
import Kpi from '../../../components/Kpi'
import SalePdf from '../../../components/SalePdf'
import ComboBoxMulti from '../../../components/ComboBoxMulti'
import { Colors } from '../../../constants/colors'
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const SalesReport = (props: any): React.ReactElement => {

  const [selectedRows, setSelectedRows] = useState<any>()
  const [isVisibleModalComentarios, setIsVisibleModalComentarios] = useState(false)
  const [idComentarios, setIdComentarios] = useState(0)
  const [textComment, setTextComment] = useState('')
  const [arrComments, setArrComments] = useState<ISaleComment[]>([])

  const [selectedPedidoArquivos, setSelectedPedidoArquivos] = useState<ISalesReport>()
  const [arrFiles, setArrFiles] = useState<IFiles[]>([])
  const [isVisibleModalArquivos, setIsVisibleModalArquivos] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0);

  const [selectedPedidoDetalhe, setSelectedPedidoDetalhe] = useState<ISalesReport>()
  const [isVisibleModalDetalhes, setIsVisibleModalDetalhes] = useState(false)

  const [arrParcelas, setArrParcelas] = useState<IParcela[]>([])
  const [isVisibleModalParcelas, setIsVisibleModalParcelas] = useState(false)

  const [arrProdutos, setArrProdutos] = useState([])
  const [isVisibleModalProdutos, setIsVisibleModalProdutos] = useState(false)

  const [loading, setLoading] = useState(false)
  const [salesData, setSalesData] = useState<any>([])
  const [situationsData, setSituationsData] = useState<ISituation[]>([])

  const [isVisibleModalIntegrador, setIsVisibleModalIntegrador] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState('')
  const [loadingIntegrador, setLoadingIntegrador] = useState(false)

  const [isVisibleModalSituation, setIsVisibleModalSituation] = useState(false)
  const [selectedSituation, setSelectedSituation] = useState('1')
  const [selectedIdPedidoVenda, setSelectedIdPedidoVenda] = useState('')

  const [alert, setAlert] = useState({
    visible: false,
    message: '',
    error: false
  })

  const [selectedTabPrincipal, setSelectedTabPrincipal] = useState(0);

  // filtros
  const [arrSaleOrigin, setArrSaleOrigin] = useState<ISaleOrigin[]>([])
  const [customers, setCustomers] = useState<ICustomer[]>([])
  const [branchs, setBranchs] = useState<IBranch[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const [arrSaleOrderSituation, setArrSaleOrderSituation] = useState<ISituations[]>([])
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange<Date>>([null, null]);
  const [selectedIdOrigemPedido, setSelectedIdOrigemPedido] = useState('0');
  const [selectedIdCliente, setSelectedIdCliente] = useState('0');
  const [selectedIdfilial, setSelectedIdfilial] = useState('0');
  const [selectedStatus, setSelectedStatus] = useState<any>([]);
  const [selectedIdUser, setSelectedIdUser] = useState('0')
  const [infosPdf, setInfosPdf] = useState<ISalesReport[]>([])

  const [userInfo, setUserInfo] = useState<IUser>()

  const [order, setOrder] = useState('<')

  const [isReseted, setIsReseted] = useState(true)

  const [pedidosFaturamento, setPedidosFaturamento] = useState('')

  const classes = useStyles()


  const getInfoPdf = async (id: number): Promise<void> => {
    if (id) {
      const result = await handlersSales._listOne(id)
      setInfosPdf(result)
    }
  }


  const getBranchs = async (): Promise<void> => {
    const data = await handlersBranch._list()
    setBranchs(data)
  }

  const getCustomers = async (): Promise<void> => {
    const data = await handlersCustomer._list()
    setCustomers(data)
  }

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleChangeTabPrincipal = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabPrincipal(newValue);
  };

  const getSalesReport = async (): Promise<void> => {
    setLoading(true)
    try {
      const response = await api.post('sale/list', {
        idcliente: selectedIdCliente === "0" ? null : Number(selectedIdCliente),
        idfilial: selectedIdfilial === "0" ? null : Number(selectedIdfilial),
        idorigempedido: selectedIdOrigemPedido === "0" ? null : Number(selectedIdOrigemPedido),
        periodo: !dateRangeFilter ? null : dateRangeFilter,
        idvendedor: selectedIdUser === "0" ? null : Number(selectedIdUser),
        idsituacaopedidovenda: selectedStatus.length === 0 ? null : selectedStatus?.map((i: any) => Number(i.id))
      }, {
        headers: {
          'x-access-token': await getToken()
        }
      })
      setSalesData(response.data)
    } catch (err) {
      setSalesData([])
    }
    setLoading(false)
  }

  const getUsers = async (): Promise<void> => {
    const data = await handlersUser._list()
    setUsers(data)
  }

  const getSalesOrderSituation = async (): Promise<void> => {
    const data = await handlersSales._listOrderSituation()
    setSituationsData(data)
  }

  useEffect(() => {
    const user = localStorage.getItem('userInfo')
    if (user) {
      const obj_user = JSON.parse(user)
      if (obj_user?.idtipousuario) {
        if (obj_user.idtipousuario === 2) {
          setSelectedIdUser(String(obj_user?.idusuario))
        }
      }
      setUserInfo(obj_user)
    }
  }, [])

  const dataFilter = (arr: ISalesReport[]): ISalesReport[] => {
    if (userInfo) {
      if (userInfo.idtipousuario) {
        const userTypeId = userInfo.idtipousuario
        const userId = userInfo.idusuario
        let newArr: ISalesReport[] = []
        if (userTypeId === 2) { // vendedor
          newArr = arr.filter(d => d?.usuario[0]?.idusuario == userId)
        } else {
          newArr = arr
        }
        switch (props?.selected?.id) {
          case '01.01':
            return newArr
          case '01.02':
            return newArr.filter((sale) => sale.idsituacaopedidovenda === 2)
          case '01.03':
            return newArr.filter((sale) => sale.producao && sale.idsituacaopedidovenda === 3)
          case '01.04':
            return newArr.filter((sale) => sale.idsituacaopedidovenda === 4)
          case '01.05':
            return newArr.filter((sale) => sale.idsituacaopedidovenda !== 1 && !sale.idtiny)
          case '01.06':
            return newArr.filter((sale) => sale.idsituacaopedidovenda === 1)
          case '01.07':
            return newArr.filter((sale) => 1 === (3 - 2))
          default:
            return newArr
        }
      }
    }
    return arr
  }

  const getDailySale = (arr: ISalesReport[]): any => {

    let days = []
    for (let sale of arr) {
      days.push(sale?.data_inclusao?.toString().split('T')[0])
    }
    days = days.filter(onlyUnique)

    let arrDailySales = []
    for (let day of days) {
      const filterSaleByDay = arr.filter(_sale => _sale?.data_inclusao?.toString().split('T')[0] === day)
      let val1 = 0
      let val2 = 0
      let val3 = 0
      for (let i of filterSaleByDay) {
        val1 += Number(i.valor_total)
        val2 += Number(i.valor_comdesconto)
        const arrayItemsQtd = i.itempedidovenda ? i.itempedidovenda.map(({ quantidade }: any) => quantidade) : []
        val3 += arrayItemsQtd.reduce((total, it) => total += it)
      }

      arrDailySales.push({
        data: day.split('-').reverse().join('/'),
        totalvenda: 'R$ ' + maskReais(val1),
        totalvendacomdesconto: 'R$ ' + maskReais(val2),
        percentualdesconto: calcDiscountPercent(val2, val1) * 100,
        quantidade: val3
      })
    }

    return arrDailySales
  }

  const getSalleSales = (arr: ISalesReport[]): any => {

    let salles = []
    for (let sale of arr) {
      salles.push(sale?.usuario[0]?.nome?.toUpperCase())
    }
    salles = salles.filter(onlyUnique)

    let arrSalleSales = []
    for (let salle of salles) {
      const filterSaleBySalle = arr.filter(_sale => _sale?.usuario[0]?.nome?.toUpperCase() === salle)
      let val1 = 0
      let val2 = 0
      let val3 = 0
      for (let i of filterSaleBySalle) {
        val1 += Number(i.valor_total)
        val2 += Number(i.valor_comdesconto)
        const arrayItemsQtd = i.itempedidovenda ? i.itempedidovenda.map(({ quantidade }: any) => quantidade) : []
        val3 += arrayItemsQtd.reduce((total, it) => total += it)
      }
      arrSalleSales.push({
        vendedor: salle,
        totalvenda: val1,
        totalvendacomdesconto: val2,
        percentualdesconto: calcDiscountPercent(val2, val1) * 100,
        quantidade: val3
      })
    }

    return arrSalleSales.sort((a, b) => (a.totalvendacomdesconto < b.totalvendacomdesconto) ? +1 : -1)
  }

  const getStateSales = (arr: ISalesReport[]): any => {
    let states = []
    for (let sale of arr) {
      states.push(sale?.cliente[0]?.estado)
    }
    states = states.filter(onlyUnique)

    let arrStateSales = []
    for (let _state of states) {
      const filterSaleByState = arr.filter(_sale => _sale?.cliente[0]?.estado === _state)
      let val1 = 0
      let val2 = 0
      let val3 = 0
      for (let i of filterSaleByState) {
        val1 += Number(i.valor_total)
        val2 += Number(i.valor_comdesconto)
        const arrayItemsQtd = i.itempedidovenda ? i.itempedidovenda.map(({ quantidade }: any) => quantidade) : []
        val3 += arrayItemsQtd.reduce((total: number, it: number) => total += it)
      }
      arrStateSales.push({
        estado: _state,
        totalvenda: val1,
        totalvendacomdesconto: val2,
        percentualdesconto: calcDiscountPercent(val2, val1) * 100,
        quantidade: val3
      })
    }

    return arrStateSales.sort((a, b) => (a.totalvendacomdesconto < b.totalvendacomdesconto) ? +1 : -1)
  }

  const getProductSales = (arr: any): any => {
    let products = []
    for (let sale of arr) {
      const itens = sale.itempedidovenda
      if (itens) {
        for (let i of sale?.itempedidovenda) {
          products.push(i.produto)
        }
      }
    }

    products = products.filter(onlyUnique)


    let arrProductSales = []
    for (let _prod of products) {
      let verify = []
      for (let it of arr) {
        const products = it.itempedidovenda
        if (products) {
          const filtered = products.filter((p: any) => {
            return p.produto === _prod
          })
          if (filtered && filtered.length > 0) {
            verify.push(it)
          }
        }
      }
      let val1 = 0
      let val3 = 0
      for (let i of verify) {
        const arrayItemsValTotal = i.itempedidovenda ? i.itempedidovenda.filter((item: any) => item.produto === _prod).map(({ total }: any) => Number(total)) : []
        val1 += arrayItemsValTotal.reduce((total: number, it: number) => total += it)
        const arrayItemsQtd = i.itempedidovenda ? i.itempedidovenda.filter((item: any) => item.produto === _prod).map(({ quantidade }: any) => quantidade) : []
        val3 += arrayItemsQtd.reduce((total: number, it: number) => total += it)
      }
      arrProductSales.push({
        produto: _prod,
        totalvenda: val1,
        quantidade: val3
      })
    }

    return arrProductSales.sort((a, b) => (a.totalvenda < b.totalvenda) ? +1 : -1)
  }

  const getSaleOrderSituation = async (): Promise<void> => {
    const data = await handlersSales._listOrderSituation()
    setArrSaleOrderSituation(data)
  }

  const getSaleOrigin = async (): Promise<void> => {
    const data = await handlersSaleOrigin._list()
    setArrSaleOrigin(data)
  }

  const getSalesTotalValue = (total: number, item: ISalesReport) => Number(total) + Number(item.valor_total);

  const getSalesTotalQtd = (total: number, item: any) => {
    let qtd = 0
    for (let prod of item.itempedidovenda) {
      qtd += prod.quantidade
    }
    return total + qtd;
  }

  const getSalesTotalValueWithDiscount = (total: number, item: ISalesReport) => Number(total) + Number(item.valor_comdesconto);

  const getComment = async (id: number): Promise<void> => {
    const list = await handlersSaleComment._list(id)
    setArrComments(list)
  }

  const getFiles = async (id: number): Promise<void> => {
    const list = await handlersUpload._list(id)
    setArrFiles(list)
  }

  const addComment = async (): Promise<void> => {
    const user = localStorage.getItem('userInfo')
    if (user) {
      const obj_user = JSON.parse(user)
      const { idusuario } = obj_user
      const create = await handlersSaleComment._register({
        idpedidovenda: idComentarios,
        texto: textComment,
        idusuario: idusuario
      })
      if (create?.error) {
        setAlert({
          error: true,
          visible: true,
          message: create?.message ? create.message : 'Erro ao inserir comentário'
        })
      } else {
        getComment(idComentarios)
        setTextComment('')
        setAlert({
          error: false,
          visible: true,
          message: 'Comentário incluido com sucesso.'
        })
      }
    }
  }

  const handleChangeAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAccount((event.target as HTMLInputElement).value)
  };

  const handleChangeSituation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSituation((event.target as HTMLInputElement).value)
  };

  const createTiny = async (): Promise<void> => {
    setLoadingIntegrador(true)
    try {

      if (pedidosFaturamento && pedidosFaturamento.split(',') && pedidosFaturamento.split(',').length > 0) {
        for (let numPedido of pedidosFaturamento.split(',')) {
          await handlersTiny.integrador(Number(numPedido), selectedAccount)
        }
      } else {
        await handlersTiny.integrador(Number(pedidosFaturamento), selectedAccount)
      }

      setIsVisibleModalIntegrador(false)
      setPedidosFaturamento('')
      setSelectedAccount('')
      setAlert({ error: false, visible: true, message: 'Integração realizada com sucesso!' })
      getSalesReport()
    } catch (err) {
      setIsVisibleModalIntegrador(false)
      setAlert({ error: true, visible: true, message: 'Erro na integração com Tiny, contate o suporte!' })
    }
    setLoadingIntegrador(false)
  }

  const updateSaleSituation = async (): Promise<void> => {

    const update = await handlersSales._update(selectedIdPedidoVenda, { idsituacaopedidovenda: selectedSituation })

    if (update?.error) {
      setAlert({ error: true, visible: true, message: 'Erro ao atualizar status do pedido' })
    } else {
      setAlert({ error: false, visible: true, message: 'Sucesso ao atualizar status do pedido' })
    }

    getSalesReport()
    setIsVisibleModalSituation(false)
    setSelectedIdPedidoVenda('')
    setSelectedSituation('')
  }

  const resetFilters = () => {
    setDateRangeFilter([null, null])
    setSelectedIdOrigemPedido('0')
    setSelectedIdCliente('0')
    setSelectedIdfilial('0')
    setSelectedStatus([])
    setSelectedIdUser('0')
  }

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({ ...alert, visible: false })
      }, 3000)
    }
  }, [alert.visible])

  useEffect(() => {
    getSalesOrderSituation()
    getSaleOrigin()
    getBranchs()
    getSaleOrderSituation()
    getCustomers()
    getUsers()
  }, [])


  const verifySelected = (idpedidovenda: number): boolean => {

    if (pedidosFaturamento && pedidosFaturamento.split(',') && pedidosFaturamento.split(',').length > 0) {
      for (let id of pedidosFaturamento.split(',')) {
        if (String(idpedidovenda) === String(id)) {
          return true
        }
      }
    }

    return false
  }

  const calcDiscountPercent = (
    discount: number,
    total: number
  ) => 1 - (Number(discount) / Number(total))


  const parsedSacReport = (arrPedidos: any): any => {
    const newArr = []
    for (let it of arrPedidos) {
      if (it.comentarios && it.comentarios.length > 0) {
        for (let comment of it.comentarios) {
          newArr.push({
            ...it,
            comentario: comment.texto,
            autor: comment.nome,
            dt: new Date(comment.data).toLocaleString()
          })
        }
      } else {
        newArr.push({
          ...it,
          comentario: '',
          autor: '',
          dt: new Date().toLocaleString()
        })
      }
    }
    return newArr
  }

  return (
    <>
      <SnackAlert
        alert={alert}
        align='center'
        position='top'
      />
      <FadeIn delay={200} transitionDuration={200}>
        <Card style={{ padding: 15, marginTop: 30, borderRadius: 10 }}>

          <h2 style={{ textAlign: 'center' }}>
            {props?.selected?.descricao}
          </h2>







          <div style={{ padding: 12 }}>
            <h2>Filtros: </h2>
          </div>
          <div style={{ padding: '0 12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ width: 350 }}>
              <RangePicker value={dateRangeFilter} onChange={(v: any) => setDateRangeFilter(v)} />
            </div>
            <div style={{ width: '76%', display: 'flex', justifyContent: 'space-between' }}>
              <ComboBox
                value={selectedIdfilial}
                data={
                  branchs.map(c => {
                    return {
                      descricao: `${c.descricao}`,
                      id: String(c.idfilial)
                    }
                  })
                }
                change={(e: any) => setSelectedIdfilial(e.id)}
                label='Filial'
              />
              <ComboBox
                value={selectedIdOrigemPedido}
                data={arrSaleOrigin.map(c => {
                  return {
                    ...c,
                    id: String(c.idorigempedido)
                  }
                })}
                change={(v: any) => setSelectedIdOrigemPedido(v.id)}
                label='Canal'
              />
              <ComboBox
                value={selectedIdCliente}
                data={customers.map(c => {
                  return {
                    ...c,
                    id: String(c.idcliente),
                    descricao: c.nome.toUpperCase()
                  }
                })}
                change={(v: any) => setSelectedIdCliente(v.idcliente)}
                label='Cliente'
              />
              {users && (
                <ComboBox
                  value={selectedIdUser}
                  data={
                    users
                      .filter(f => userInfo?.idtipousuario === 2 ? f.idusuario == userInfo.idusuario : true)
                      .filter(f => f.idtipousuario === 2).map(c => {
                        return {
                          descricao: `${c.nome.toUpperCase()}`,
                          id: String(c.idusuario)
                        }
                      })
                  }
                  change={(e: any) => setSelectedIdUser(e?.id)}
                  label='Vendedor'
                />
              )}
              <ComboBoxMulti
                value={selectedStatus.filter(onlyUnique)}
                data={
                  // aqui
                  props?.selected?.id === "01.07" ?
                    arrSaleOrderSituation.filter(s => [7, 8].includes(s.idsituacaopedidovenda)).map(c => {
                      return {
                        descricao: `${c.descricao}`,
                        id: String(c.idsituacaopedidovenda)
                      }
                    })
                    :
                    arrSaleOrderSituation.map(c => {
                      return {
                        descricao: `${c.descricao}`,
                        id: String(c.idsituacaopedidovenda)
                      }
                    })
                }
                change={(e: any) => {
                  console.log(e)
                  setSelectedStatus(e)
                }}
                label='Status'
              />
            </div>
          </div>
          <div style={{ padding: 12, display: 'flex', justifyContent: 'flex-end' }}>
            <Button color='secondary' size='small' variant='contained' onClick={() => resetFilters()} style={{ marginRight: 8 }}>
              <ClearOutlined /> Limpar
            </Button>
            <Button size='small' color='default' variant='contained' onClick={async () => await getSalesReport()} >
              {loading ? (<><CircularProgress size={19} /> Carregando</>) : (<><SearchOutlined /> Pesquisar</>)}
            </Button>
          </div>

          <div style={{ marginTop: 18 }} />
          <Divider />




          <>
            <Tabs
              value={selectedTabPrincipal}
              onChange={handleChangeTabPrincipal}
              aria-label="simple tabs example"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Relatório" {...a11yProps(0)} />
              <Tab label="Dashboard" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={selectedTabPrincipal} index={0} >
              <div style={{ display: 'flex', flexDirection: 'column', zoom: '90%' }}>


                <div style={{ display: 'flex', alignItems: 'center' }}>

                  <Button variant='contained' color={order === '>' ? 'primary' : 'default'} onClick={() => setOrder('>')} >
                    Maior Valor
                  </Button>

                  <Button variant='contained' color={order === '<' ? 'primary' : 'default'} onClick={() => setOrder('<')} style={{ marginLeft: 8 }}>
                    Menor Valor
                  </Button>

                  {props?.selected?.id === "01.05" && <>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }} >
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => setIsVisibleModalIntegrador(true)}
                      >
                        <SyncOutlined /> Integração
                      </Button>
                    </div>
                    <small style={{ marginLeft: 12 }}>{pedidosFaturamento}</small>
                  </>}

                </div>




                {props?.selected?.id === "01.07" && (
                  <>
                    <DataTable
                      columns={[
                        {
                          name: 'Pedido',
                          cell: (obj: any) => obj.idpedidovenda,
                          sortable: true,
                          button: true
                        },
                        {
                          name: 'Pedido tiny',
                          cell: (obj: any) => obj.numerotiny,
                          sortable: true,
                          button: true
                        },
                        {
                          name: 'Data',
                          cell: (obj: any) => new Date(obj.data_pedido).toLocaleDateString(),
                          button: true
                        },
                        {
                          name: 'Cliente',
                          cell: (obj: any) => obj?.cliente[0]?.nome.toUpperCase()
                        },
                        {
                          name: 'Texto',
                          cell: (obj: any) => <div style={{ padding: 8 }}><TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            style={{ width: 350 }}
                            value={obj.comentario}
                            disabled
                          /></div>
                        },
                        {
                          name: 'Comentários',
                          button: true,
                          cell: (obj: any) => (
                            <Button
                              color='default'
                              onClick={() => {
                                setArrComments([])
                                setIdComentarios(obj.idpedidovenda)
                                setIsVisibleModalComentarios(true)
                                getComment(obj.idpedidovenda)
                              }}
                            >
                              <Badge badgeContent={<span style={{ width: 24 }}>{obj.comentarios.length}</span>} color="primary">
                                <ChatOutlined color="action" style={{ fontSize: 28 }} />
                              </Badge>
                            </Button>
                          )
                        },
                        {
                          name: 'Status',
                          selector: 'ativo',
                          cell: (obj: any) => (
                            <Button
                              disabled={userInfo?.idtipousuario === 2}
                              variant="outlined"
                              style={{ width: 222, display: 'flex', justifyContent: 'flex-start', alignContent: 'center' }}
                              color="primary"
                              onClick={() => {
                                setSelectedSituation(String(obj.idsituacaopedidovenda))
                                setIsVisibleModalSituation(true)
                                setSelectedIdPedidoVenda(String(obj?.idpedidovenda))
                              }}
                            >
                              <small> <EditOutlined style={{ fontSize: 14 }} /> {obj.situacaopedidovenda}</small>
                            </Button>
                          )
                        },
                      ]}
                      data={
                        parsedSacReport(
                          dataFilter(salesData)
                            .sort(
                              (a, b) => {
                                if (order === '>') {
                                  return Number(b?.valor_comdesconto) - Number(a?.valor_comdesconto)
                                } else {
                                  return Number(a?.valor_comdesconto) - Number(b?.valor_comdesconto)
                                }
                              }
                            )
                        )
                      }
                    />
                  </>
                )}

                {isReseted && props?.selected?.id !== "01.07" && (
                  <DataTable
                    selectedRows={selectedRows}
                    changeRow={(arr: any) => setSelectedRows(arr)}
                    selectable={false}
                    columns={[
                      {
                        name: 'Pedido',
                        cell: (obj: any) => {
                          return (
                            <>
                              {props?.selected?.id === '01.05' && !verifySelected(obj.idpedidovenda) && (
                                <Button
                                  onClick={() => {
                                    let newPedidosStr = pedidosFaturamento === '' ? String(obj.idpedidovenda) : pedidosFaturamento + ',' + obj.idpedidovenda
                                    setPedidosFaturamento(newPedidosStr)
                                  }}
                                >
                                  <AddOutlined />
                                </Button>
                              )}
                              {obj.idpedidovenda}
                            </>
                          )
                        },
                        sortable: true,
                        button: true
                      },
                      {
                        name: 'Pedido tiny',
                        cell: (obj: any) => obj.numerotiny,
                        sortable: true,
                        button: true
                      },
                      {
                        name: 'Filial',
                        cell: (obj: any) => obj.idfilialorigem,
                        button: true
                      },
                      {
                        name: 'Data',
                        cell: (obj: any) => new Date(obj.data_pedido).toLocaleDateString(),
                        button: true
                      },
                      {
                        name: 'Origem',
                        button: true,
                        cell: (obj: any) => obj.origempedido,
                      },
                      {
                        name: 'Produção?',
                        button: true,
                        cell: (obj: any) => obj.producao ? 'Sim' : 'Não'
                      },
                      {
                        name: 'Cliente',
                        button: true,
                        cell: (obj: any) => obj?.cliente[0]?.nome.toUpperCase()
                      },
                      {
                        name: 'Valor frete',
                        cell: (obj: any) => maskReais(Number(obj.valor_frete)),
                        button: true,
                        right: true,
                      },
                      {
                        name: 'Valor total pedido',
                        cell: (obj: any) => maskReais(Number(obj.valor_total)),
                        button: true,
                        right: true,
                      },
                      {
                        name: 'Valor com desconto',
                        cell: (obj: any) => maskReais(Number(obj.valor_comdesconto)),
                        button: true,
                        right: true,
                      },
                      {
                        name: '% Desconto',
                        cell: (obj: any) => {
                          const calc = calcDiscountPercent(Number(obj.valor_comdesconto), Number(obj.valor_total))
                          return calc < 0 ? (
                            <span style={{ fontWeight: 'bold', color: 'red' }}>
                              {maskReais(calc * 100) + ' %'}
                            </span>
                          )
                            :
                            maskReais(calc * 100) + ' %'
                        },
                        button: true,
                        right: true,
                      },
                      {
                        name: 'Produtos',
                        button: true,
                        cell: (obj: any) => (
                          <Button
                            color='default'
                            onClick={() => {
                              setArrProdutos(obj?.itempedidovenda)
                              setIsVisibleModalProdutos(true)
                            }}
                          >
                            <Badge badgeContent={<span style={{ width: 24 }}>{obj.itempedidovenda.length}</span>} color="primary">
                              <ShoppingCart color="action" style={{ fontSize: 27 }} />
                            </Badge>
                          </Button>
                        )
                      },
                      {
                        name: 'Parcelas',
                        button: true,
                        cell: (obj: any) => (
                          <Button
                            color='default'
                            onClick={() => {
                              setArrParcelas(obj.parcelas)
                              setIsVisibleModalParcelas(true)
                            }}
                          >
                            <Badge badgeContent={<span style={{ width: 24 }}>{obj.quantidadeparcela}</span>} color="primary">
                              <ListOutlined color="action" style={{ fontSize: 28 }} />
                            </Badge>
                          </Button>
                        )
                      },
                      {
                        name: 'Arquivos',
                        button: true,
                        cell: (obj: any) => (
                          <Button
                            color='default'
                            onClick={() => {
                              setArrFiles([])
                              setIsVisibleModalArquivos(true)
                              setSelectedPedidoArquivos(obj)
                              setArrFiles(obj.arquivos)
                              getFiles(obj.idpedidovenda)
                            }}
                          >
                            <Badge badgeContent={<span style={{ width: 24 }}>{obj.arquivos.length}</span>} color="primary">
                              <AttachFileOutlined color="action" style={{ fontSize: 28 }} />
                            </Badge>
                          </Button>
                        )
                      },
                      {
                        name: 'Comentários',
                        button: true,
                        cell: (obj: any) => (
                          <Button
                            color='default'
                            onClick={() => {
                              setArrComments([])
                              setIdComentarios(obj.idpedidovenda)
                              setIsVisibleModalComentarios(true)
                              getComment(obj.idpedidovenda)
                            }}
                          >
                            <Badge badgeContent={<span style={{ width: 24 }}>{obj.comentarios.length}</span>} color="primary">
                              <ChatOutlined color="action" style={{ fontSize: 28 }} />
                            </Badge>
                          </Button>
                        )
                      },
                      {
                        name: 'Detalhes',
                        button: true,
                        cell: (obj: any) => (
                          <Button
                            color='default'
                            onClick={async () => {
                              await getInfoPdf(obj.idpedidovenda)
                              setSelectedPedidoDetalhe(obj)
                              setIsVisibleModalDetalhes(true)
                            }}
                          >
                            <SearchOutlined color="action" style={{ fontSize: 28 }} />
                          </Button>
                        )
                      },
                      {
                        name: 'Status',
                        selector: 'ativo',
                        cell: (obj: any) => (
                          <Button
                            disabled={userInfo?.idtipousuario === 2}
                            variant="outlined"
                            style={{ width: 222, display: 'flex', justifyContent: 'flex-start', alignContent: 'center' }}
                            color="primary"
                            onClick={() => {
                              setSelectedSituation(String(obj.idsituacaopedidovenda))
                              setIsVisibleModalSituation(true)
                              setSelectedIdPedidoVenda(String(obj?.idpedidovenda))
                            }}
                          >
                            <small> <EditOutlined style={{ fontSize: 14 }} /> {obj.situacaopedidovenda}</small>
                          </Button>
                        )
                      },
                    ]}
                    data={
                      dataFilter(salesData)
                        .sort(
                          (a, b) => {
                            if (order === '>') {
                              return Number(b?.valor_comdesconto) - Number(a?.valor_comdesconto)
                            } else {
                              return Number(a?.valor_comdesconto) - Number(b?.valor_comdesconto)
                            }
                          }
                        )
                    }
                  />
                )}

              </div>
            </TabPanel>
            <TabPanel value={selectedTabPrincipal} index={1} >
              <div style={{ marginTop: 8, display: 'flex', justifyContent: 'space-between' }}>
                <Kpi
                  label="TOTAL DE PEDIDOS"
                  value={dataFilter(salesData).length.toString()}
                />
                <Kpi
                  label="QTD. PEÇAS VENDIDAS"
                  value={dataFilter(salesData).reduce(getSalesTotalQtd, 0).toString()}
                />
                <Kpi
                  label="VALOR VENDA TOTAL"
                  value={maskReais(dataFilter(salesData).reduce(getSalesTotalValue, 0))}
                />
                <Kpi
                  label="VALOR VENDA C/ DESCONTO"
                  value={maskReais(dataFilter(salesData).reduce(getSalesTotalValueWithDiscount, 0))}
                />
                <Kpi
                  label="% PERCENTUAL DESCONTO"
                  value={
                    `
                    ${maskReais(calcDiscountPercent(dataFilter(salesData).reduce(getSalesTotalValueWithDiscount, 0), dataFilter(salesData).reduce(getSalesTotalValue, 0)) * 100)}
                    %`
                  }
                />
              </div>
              <div style={{ marginTop: 18, display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '49.5%' }}>
                  <h4>Venda diária</h4>
                  <div style={{ border: '2px #e1dede solid', padding: 5 }}>
                    <DataTable
                      pagination
                      columns={[
                        {
                          name: 'Data',
                          selector: 'data',
                        },
                        {
                          name: 'Qtd. peças',
                          selector: 'quantidade',
                          button: true
                        },
                        {
                          name: 'Venda',
                          selector: 'totalvenda',
                        },
                        {
                          name: 'Venda c/ desconto',
                          selector: 'totalvendacomdesconto',
                        },
                        {
                          name: '% Desconto',
                          cell: ({ percentualdesconto: number }: any) => {
                            return (
                              <>
                                {number < 0 ?
                                  (
                                    <span style={{ fontWeight: 'bold', color: 'red' }}>
                                      {maskReais(number) + '%'}
                                    </span>
                                  )
                                  :
                                  maskReais(number) + '%'
                                }
                              </>
                            )
                          }
                        },
                      ]}
                      data={getDailySale(dataFilter(salesData))}
                      striped
                    />
                  </div>
                </div>
                <div style={{ width: '49.5%' }}>
                  <h4>Total por vendedor</h4>
                  <div style={{ border: '2px #e1dede solid', padding: 5 }}>
                    <DataTable
                      columns={[
                        {
                          name: 'Vendedor',
                          selector: 'vendedor',
                        },
                        {
                          name: 'Qtd. peças',
                          selector: 'quantidade',
                          button: true
                        },
                        {
                          name: 'Venda',
                          cell: (obj: any) => `R$ ${maskReais(obj.totalvenda)}`,
                        },
                        {
                          name: 'Venda c/ desconto',
                          cell: (obj: any) => `R$ ${maskReais(obj.totalvendacomdesconto)}`,
                        },
                        {
                          name: '% Desconto',
                          cell: ({ percentualdesconto: number }: any) => {
                            return (
                              <>
                                {number < 0 ?
                                  (
                                    <span style={{ fontWeight: 'bold', color: 'red' }}>
                                      {maskReais(number) + '%'}
                                    </span>
                                  )
                                  :
                                  maskReais(number) + '%'
                                }
                              </>
                            )
                          }
                        },

                      ]}
                      data={getSalleSales(dataFilter(salesData))}
                      striped
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 18, display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '49.5%' }}>
                  <h4>Venda por produto</h4>
                  <div style={{ border: '2px #e1dede solid', padding: 5 }}>
                    <DataTable
                      pagination
                      columns={[
                        {
                          name: 'Produto',
                          selector: 'produto',
                        },
                        {
                          name: 'Qtd. peças',
                          selector: 'quantidade',
                          button: true
                        },
                        {
                          name: 'Venda',
                          cell: (obj: any) => `R$ ${maskReais(obj.totalvenda)}`,
                        }
                      ]}
                      data={getProductSales(dataFilter(salesData))}
                      striped
                    />
                  </div>
                </div>
                <div style={{ width: '49.5%' }}>
                  <h4>Venda por estado</h4>
                  <div style={{ border: '2px #e1dede solid', padding: 5 }}>
                    <DataTable
                      columns={[
                        {
                          name: 'Estado',
                          selector: 'estado',
                        },
                        {
                          name: 'Qtd. peças',
                          selector: 'quantidade',
                          button: true
                        },
                        {
                          name: 'Venda',
                          cell: (obj: any) => `R$ ${maskReais(obj.totalvenda)}`,
                        },
                        {
                          name: 'Venda c/ desconto',
                          cell: (obj: any) => `R$ ${maskReais(obj.totalvendacomdesconto)}`,
                        },
                        {
                          name: '% Desconto',
                          cell: ({ percentualdesconto: number }: any) => {
                            return (
                              <>
                                {number < 0 ?
                                  (
                                    <span style={{ fontWeight: 'bold', color: 'red' }}>
                                      {maskReais(number) + '%'}
                                    </span>
                                  )
                                  :
                                  maskReais(number) + '%'
                                }
                              </>
                            )
                          }
                        },
                      ]}
                      data={getStateSales(dataFilter(salesData))}
                      striped
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
          </>



        </Card>
      </FadeIn>







      <Modal
        open={isVisibleModalSituation}
        onClose={() => {
          setIsVisibleModalSituation(false)
          // getCustomers()
        }}
        children={
          <>
            <h3>Alterar situação do pedido</h3>
            <Divider />
            <div style={{ padding: 28 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={selectedSituation} onChange={handleChangeSituation}>
                  {situationsData.map(f =>
                    <FormControlLabel
                      value={String(f.idsituacaopedidovenda)}
                      control={<Radio />}
                      label={`${f.descricao}`}
                    />
                  )}
                </RadioGroup>
              </FormControl>
              <div style={{ paddingTop: 28 }} />
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  updateSaleSituation()
                }}
              >
                <SyncOutlined /> &nbsp; Gravar
              </Button>
            </div>
          </>
        }
      />
      <Modal
        open={isVisibleModalIntegrador}
        onClose={() => {
          setIsVisibleModalIntegrador(false)
          setSelectedRows([])
          setIsReseted(false)
          setTimeout(() => {
            setIsReseted(true)
          }, 50)
        }}
        children={
          <div style={{ width: 900 }}>
            <h2>Integrador</h2>
            <Divider />
            {loadingIntegrador ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 18 }}><CircularProgress /> &nbsp; Carregando ... </div> : <>
              <div style={{ paddingTop: 28 }} />

              <div style={{ padding: 28, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                <FormControl component="fieldset">
                  <h4>Empresa faturamento: </h4>
                  <RadioGroup aria-label="gender" name="gender1" value={selectedAccount} onChange={handleChangeAccount}>
                    {[
                      {
                        id: '1',
                        descricao: 'Brasil de Coração'
                      },
                      {
                        id: '2',
                        descricao: 'RN Martins'
                      }
                    ].map(f =>
                      <FormControlLabel
                        value={String(f.id)}
                        control={<Radio />}
                        label={`${f.id} - ${f?.descricao}`}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
                <div style={{ paddingTop: 18 }} />
                <div style={{ paddingTop: 2 }}>
                  <h4>Pedidos: </h4>
                  <TextField
                    error={pedidosFaturamento === ''}
                    helperText={pedidosFaturamento === '' && "Pedido inválido"}
                    variant="filled"
                    margin="normal"
                    style={{ background: Colors.white }}
                    value={pedidosFaturamento}
                    fullWidth
                    id="pedidosfaturamento"
                    label="Digite o número dos pedidos separados por virgula, exemplo: 123,444,725"
                    name="pedidosfaturamento"
                    autoFocus
                    onChange={(e) => setPedidosFaturamento(e.target.value)}
                  />
                </div>

                <div style={{ marginTop: 28 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={selectedAccount === '' || pedidosFaturamento === ''}
                    onClick={() => {
                      createTiny()
                    }}
                  >
                    <SyncOutlined /> &nbsp; Gravar
                  </Button>
                </div>

              </div>
            </>}
          </div>
        }
      />
      <Modal
        open={isVisibleModalArquivos}
        onClose={() => {
          setIsVisibleModalArquivos(false)
        }}
        children={
          <>
            <h2>Arquivos do pedido</h2>
            <Divider />
            <div className={classes.rootTabs} style={{ marginTop: 18 }}>
              <div>
                <Tabs
                  value={selectedTab}
                  onChange={handleChangeTab}
                  aria-label="simple tabs example"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="COMPROVANTES DE PAGAMENTO" {...a11yProps(0)} />
                  <Tab label="BOLETOS" {...a11yProps(1)} />
                </Tabs>
              </div>
              <TabPanel value={selectedTab} index={0}>
                <Upload
                  refrtipo='C'
                  refresh={selectedPedidoArquivos?.idpedidovenda ?
                    async () => {
                      await getFiles(selectedPedidoArquivos.idpedidovenda)
                      setAlert({ error: false, visible: true, message: 'Upload realizado com sucesso.' })
                      setIsVisibleModalArquivos(true)
                    }
                    :
                    () => { }}
                  idpedidovenda={selectedPedidoArquivos?.idpedidovenda}
                  onClose={() => setIsVisibleModalArquivos(true)}
                  handleCloseModalPai={() => setIsVisibleModalArquivos(false)}
                />
                {/* <ImgView arquivos={arrFiles} /> */}
                <div style={{ margin: '10px 0' }} />
                <DataTable
                  columns={[
                    {
                      name: 'Arquivo',
                      selector: 'nome'
                    },
                    {
                      name: 'Download',
                      cell: (v: any) => (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            var a = document.createElement("a"); //Create <a>
                            a.href = v.arquivo; //Image Base64 Goes here
                            a.download = v.nome; //File name Here
                            a.click(); //Downloaded file
                          }}
                        >
                          <CloudDownloadOutlined />
                        </Button>
                      ),
                      button: true
                    }
                  ]}
                  data={arrFiles?.filter(a => a.tipo === 'C')}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <div style={{ margin: '10px 0' }} />
                <Upload
                  tipo='B'
                  refresh={selectedPedidoArquivos?.idpedidovenda ?
                    async () => {
                      await getFiles(selectedPedidoArquivos.idpedidovenda)
                      setAlert({ error: false, visible: true, message: 'Upload realizado com sucesso.' })
                      setIsVisibleModalArquivos(true)
                    }
                    :
                    () => { }}
                  idpedidovenda={selectedPedidoArquivos?.idpedidovenda}
                  onClose={() => setIsVisibleModalArquivos(true)}
                  handleCloseModalPai={() => setIsVisibleModalArquivos(false)}
                />
                <div style={{ margin: '10px 0' }} />
                <DataTable
                  columns={[
                    {
                      name: 'Arquivo',
                      selector: 'nome'
                    },
                    {
                      name: 'Download',
                      cell: (v: any) => (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            var a = document.createElement("a"); //Create <a>
                            a.href = v.arquivo; //Image Base64 Goes here
                            a.download = v.nome; //File name Here
                            a.click(); //Downloaded file
                          }}
                        >
                          <CloudDownloadOutlined />
                        </Button>
                      ),
                      button: true
                    }
                  ]}
                  data={arrFiles?.filter(a => a.tipo === 'B')}
                />
              </TabPanel>
            </div>
          </>
        }
      />
      <Modal
        open={isVisibleModalComentarios}
        onClose={() => {
          setIsVisibleModalComentarios(false)
        }}
        children={
          <>
            <h2>Comentários do pedido</h2>
            <Divider />
            <div style={{ padding: 18 }}>
              <TextareaAutosize
                style={{ width: '100%', padding: 5 }}
                aria-label="minimum height"
                minRows={5}
                placeholder="Digite um comentário"
                value={textComment}
                onChange={(e) => setTextComment(e.target.value)}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 8 }}>
                <Button
                  variant='contained'
                  color='default'
                  onClick={() => addComment()}
                >
                  <AddOutlined /> Adicionar
                </Button>
              </div>
              <div style={{ marginTop: 18 }} />
              <Divider />
              {arrComments.map((c, index) => (
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 18 }} key={index}>
                  <TextareaAutosize
                    style={{ width: '100%', padding: 5 }}
                    aria-label="minimum height"
                    minRows={3}
                    disabled
                    value={c.texto}
                    placeholder="Digite um comentário"
                  />
                  <small>Por: {c?.nome} | Em: {c?.data ? new Date(c.data).toLocaleString() : ' - '}</small>
                </div>
              ))}
            </div>
          </>
        }
      />
      <Modal
        open={isVisibleModalProdutos}
        onClose={() => {
          setIsVisibleModalProdutos(false)
          // getCustomers()
        }}
        children={
          <>
            <h2>Itens do pedido</h2>
            <Divider />
            <DataTable
              columns={[
                {
                  name: 'Produto',
                  cell: (obj: any) => `${obj.produto}`
                },
                {
                  name: 'Cor Alumínio',
                  cell: (obj: any) => `${obj.coraluminio}`
                },
                {
                  name: 'Cor Fibra',
                  cell: (obj: any) => `${obj.corfibra}`
                },
                {
                  name: 'A',
                  selector: 'altura',
                  sortable: true
                },
                {
                  name: 'L',
                  selector: 'largura',
                  sortable: true
                },
                {
                  name: 'P',
                  selector: 'profundidade',
                  sortable: true
                },
                {
                  name: 'Sob-medida',
                  selector: 'personalizado',
                  cell: (v: ICart) => v.personalizado ? 'Sim' : 'Não',
                },
                {
                  name: 'Preço',
                  cell: (v: ICart) => maskReais(v.valor),
                  sortable: true
                },
                {
                  name: 'Qtde',
                  selector: 'quantidade',
                  sortable: true
                },
                {
                  name: 'Total',
                  selector: 'total',
                  cell: (v: ICart) => maskReais(v.total),
                  sortable: true
                }
              ]}
              data={arrProdutos}
            />
          </>
        }
      />
      <Modal
        open={isVisibleModalParcelas}
        onClose={() => {
          setIsVisibleModalParcelas(false)
          // getCustomers()
        }}
        children={
          <div style={{ width: 700 }}>
            <h2>Parcelas</h2>
            <Divider />
            <DataTable
              columns={[
                {
                  name: 'Parcela',
                  selector: 'numeroparcela',
                  sortable: true,
                  button: true
                },
                {
                  name: 'Tipo pagamento',
                  cell: (obj: any) => `${obj.tipopagamento}`,
                  sortable: true
                },
                {
                  name: 'Valor',
                  selector: 'valor',
                  cell: (p: IParcela) => maskReais(Number(p.valor)),
                  sortable: true
                }
              ]}
              data={arrParcelas}
            />
          </div>
        }
      />
      <Modal
        open={isVisibleModalDetalhes}
        onClose={() => {
          setIsVisibleModalDetalhes(false)
          // getCustomers()
        }}
        children={
          <div style={{ width: 800, zoom: '90%' }}>
            <h2>Detalhes do pedido</h2>
            <Divider />


            {infosPdf && infosPdf.length > 0 && (
              <SalePdf showButtonNewSale={false} info={infosPdf} />
            )}

          </div>
        }
      />
    </>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default SalesReport
