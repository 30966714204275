import api from "../../services/api"
import IColorFibra from "../../types/IColorFibra"
import { getToken } from "../../util"

const _register = async (colorfibra: IColorFibra): Promise<any> => {
  try {
    const response = await api.post('product/colorfibra/register',
      { ...colorfibra },
      { headers: { 'x-access-token': await getToken() } }
    )
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao registrar cor fibra" }
  }
}

export default _register
