export const Colors = {
  grey: '#666666',
  dark: '#000000',
  yellow: '#FBE301',
  greenLight: '#CBDEAA',
  greenDark: '#7EAD2A',
  red: '#fb3640',
  darkblue: '#0B1E42',
  lightblue: '#0F73BA',
  lightblue2: '#5CB1CF',
  lightblue3: '#3C8DC7',
  bluesea: '#1F99C4',
  darkgrey: '#616161',
  white: '#FFFFFF',
  almostwhite: '#EFEFEF',
  greyone: '#CCCCCC',
  lightgrey: '#F3F3F3',
  dashedgrey: '#8F8F8F',
  greengrey: '#848D89',
  nightblack: '#02050A',
  nightlightblue: '#1A82CC',
  shadow: '#00000029',
  graphiteLight: '#252f3e',
  graphiteDark: '#1b2330',
  greyMenu: '#b6babf'
}
