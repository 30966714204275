import React, { useState, useEffect } from 'react'
import {
  makeStyles, Theme, Typography, Container,
  Card, Button, IconButton, Switch, CardHeader,
  TextField
} from '@material-ui/core/'
import { AddOutlined, DeleteOutlined, RefreshOutlined, EditOutlined } from '@material-ui/icons/'
import FadeIn from 'react-fade-in'
import ComboBox from '../../components/ComboBox'
import SalesReport from './SalesReport'
import ContasReport from './ContasReport'
import StockReport from './StockReport'
import ProdReport from './ProdReport'
import TramaReport from './TramaReport'

interface IRelatorios {
  id: string
  descricao: string
}

const ReportPage = (): React.ReactElement => {
  const [loading, setLoading] = useState(false)
  const [selectedReport, setSelectedReport] = useState({
    id: '',
    descricao: ''
  })

  const arrRelatorios: IRelatorios[] = [
    {
      id: '01.01',
      descricao: '01.01 Relatório de Vendas',
    },
    {
      id: '01.02',
      descricao: '01.02 Relatório pedidos pagos',
    },
    {
      id: '01.04',
      descricao: '01.04 Relatório de expedição',
    },
    {
      id: '01.05',
      descricao: '01.05 Faturamento TINY',
    },
    {
      id: '01.06',
      descricao: '01.06 Relatório pedidos sem pagamento',
    },
    {
      id: '01.07',
      descricao: '01.07 Relatório SAC',
    },
    {
      id: '02.01',
      descricao: '02.01 Relatório de estoque',
    },
    {
      id: '03.01',
      descricao: '03.01 Relatório de produção',
    },
    {
      id: '04.01',
      descricao: '04.01 Relatório de contas',
    },
    {
      id: '05.01',
      descricao: '05.01 Produção Tramadores',
    },
  ]

  
  const filterByPermission = (arrRelatorios: IRelatorios[]): IRelatorios[] => {
    //{idtipousuario: 1, descricao: "Administrador"}
    //{idtipousuario: 2, descricao: "Vendedor"}
    //{idtipousuario: 3, descricao: "Expedição"}
    //{idtipousuario:     4, descricao: "Financeiro"}
    //{idtipousuario: 5, descricao: "Produção"}
    if (arrRelatorios) {
      try {
        const TABLE_REPORT_FOR_USER_TYPES: any = {
          '1': [...arrRelatorios.map(r => r?.id?.split(' ')[0])],
          '2': ['01.01', '02.01', '03.01', '01.07'],
          '3': ['01.04', '01.07'],
          '4': ['01.01','01.05','02.01', '03.01' , '04.01', '01.07'],
          '5': ['03.01', '01.07', '05.01']
        }
        const newArr: IRelatorios[] = []
        const user = localStorage.getItem('userInfo')
        if (user) {
          const obj_user = JSON.parse(user)
          if (obj_user.idtipousuario) {
            const userTypeId = obj_user.idtipousuario.toString()
            const tableInfo = TABLE_REPORT_FOR_USER_TYPES[userTypeId]
            return arrRelatorios.filter((d: any) => {
              const reportIdStr = d.id.split(' ')[0]
              if (tableInfo) {
                return tableInfo.includes(reportIdStr)
              } else {
                return []
              }
            })
          }
        }
        return newArr
      } catch (err) {
        return []
      }
    } else {
      return []
    }
  }

  return (
    <>
      <Container maxWidth='xl'>
        <FadeIn delay={200} transitionDuration={200}>
          <Typography variant='h5' color='textSecondary'>
            Relatórios
          </Typography>
          <Card style={{ padding: 15, marginTop: 30, borderRadius: 10 }}>
            <CardHeader
              action={<>
                <div style={{ width: 458 }}>
                  <ComboBox
                    value={selectedReport.id}
                    data={filterByPermission(arrRelatorios)}
                    change={(v: any) => {
                      setSelectedReport(v)
                    }}
                    label='Selecione o relatório'
                  />
                </div>
              </>}
              title='Relatórios do sistema'
            />
          </Card>
          {selectedReport.id.split('.')[0] === '01' && (<SalesReport selected={selectedReport} />)}
          {selectedReport.id.split('.')[0] === '02' && (<StockReport selected={selectedReport} />)}
          {selectedReport.id.split('.')[0] === '03' && (<ProdReport selected={selectedReport} />)}
          {selectedReport.id.split('.')[0] === '04' && (<ContasReport selected={selectedReport} />)}
          {selectedReport.id.split('.')[0] === '05' && (<TramaReport selected={selectedReport} />)}
        </FadeIn>
      </Container>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default ReportPage
