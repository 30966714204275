import api from "../../services/api"
import IBranch from "../../types/IBranch"
import { getToken } from "../../util"

const _list = async (): Promise<IBranch[]> => {
  try {
    const response = await api.get('branch/list', {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

export default _list
