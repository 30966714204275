import api from "../../services/api"
import ISaleComment from "../../types/ISaleComment"
import { getToken } from "../../util"

interface IRegisterSaleComment {
  error?: boolean
  visible?: boolean
  message?: string
}

const _register = async (comment: ISaleComment): Promise<IRegisterSaleComment> => {
  try {
    const response = await api.post('sale/comment/register',
      { ...comment },
      { headers: { 'x-access-token': await getToken() } }
    )
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao incluir comentário" }
  }
}

export default _register
