import React from "react";
import ReactToPrint from "react-to-print";
import {
  Button, Divider, TextareaAutosize
} from '@material-ui/core/'
import './style.css'
import DataTable from '../DataTable'
import { maskReais } from '../../util'
import logo from '../../assets/logo.png'
import {
  PictureAsPdfOutlined, ReplayOutlined
} from '@material-ui/icons/'

class ComponentToPrint extends React.Component {
  render() {
    const info = this.props.pedido?.length > 0 ? this.props.pedido[0] : []
    return (
      <>
        <div className="container">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span></span>
            <img src={logo} width={158} alt='Logo' />
            <span></span>
          </div>
          <div style={{ paddingTop: 6 }} />
          <Divider />
          <div style={{ paddingTop: 8 }} />
          <h2>Informações do pedido</h2>
          <div style={{ paddingTop: 8 }} />
          <div className="row row-info info-bg">
            <div>
              <span>Número pedido: </span>
              <b>{info?.idpedidovenda}</b>
            </div>
            <div>
              <span>Empresa faturamento: </span>
              <b>{info?.empresa_faturamento}</b>
            </div>
          </div>
          <div className="row row-info">
            <div>
              <span>Valor Total </span>
              <b> R$ {maskReais(info?.valor_total)} </b>
            </div>
            <div>
              <span>Valor c/ desconto </span>
              <b> R$ {maskReais(info?.valor_comdesconto)} </b>
            </div>
          </div>
          <div className="row row-info info-bg">
            <div>
              <span>Quantidade parcelas: </span>
              <b>  {info?.quantidadeparcela} </b>
            </div>
            <div>
              <span>Valor Entrada: </span>
              <b> R$ {info?.entrada ? maskReais(info?.parcelas[0]?.valor) : '00,00'} </b>
            </div>
          </div>
          <div className="row row-info">
            <div>
              <span>Origem do pedido: </span>
              <b> {info?.origempedido} </b>
            </div>
            <div>
              <span>Status: </span>
              <b> {info?.situacaopedidovenda} </b>
            </div>

          </div>
          <div className="row row-info info-bg">
            <div>
              <span>Filial Origem: </span>
              <b> {info?.idfilialorigem} - {info?.cidade}/{info?.uf} </b>
            </div>
            <div>
              <span>Produção: </span>
              <b> {info?.producao ? 'Sim' : 'Não'} </b>
            </div>
          </div>
          <div className="row row-info ">
            <div>
              <span>Data inclusão: </span>
              <b> {info?.data_inclusao ? new Date(info.data_inclusao).toLocaleString() : '-'} </b>
            </div>
            <div>
              <span>Nome do Vendedor: </span>
              <b> {info?.usuario[0]?.nome.toUpperCase()} </b>
            </div>
          </div>
          <div className="row row-info info-bg">
            <div>
              <span>Nome do Cliente: </span>
              <b> {info?.cliente[0]?.nome.toUpperCase()} </b>
            </div>
            <div>
              <span>Celular: </span>
              <b> {info?.cliente[0]?.celular}  </b>
            </div>
          </div>
          <div className="row row-info">
            <div>
              <span>Endereço: </span>
              <b> {info?.cliente[0]?.endereco} - {info?.cliente[0]?.numero} </b>
            </div>
            <div>
              <span>Cidade: </span>
              <b> {info?.cliente[0]?.cidade} - {info?.cliente[0]?.estado} </b>
            </div>
          </div>

          <div style={{ padding: 6 }}>
            <h4>Observação: </h4>
            <TextareaAutosize
              style={{ width: '100%', padding: 5 }}
              aria-label="minimum height"
              minRows={3}
              disabled
              value={info?.observacao}
              placeholder=""
            />
          </div>
          <Divider />
          <br />
          <h2>Itens do pedido</h2>
          <div style={{ zoom: '90%' }}>
            <DataTable
              columns={[
                {
                  name: 'idprodutograde',
                  selector: 'idprodutograde',
                  button: true,
                },
                {
                  name: 'Produto',
                  selector: 'produto',
                },
                {
                  name: 'Dimensões',
                  cell: (v) => <small>{v.altura + ' x ' + v.largura + ' x ' + v.profundidade}</small>,
                  button: true
                },
                {
                  name: 'Preço',
                  cell: (v) => maskReais(v.valor),
                  sortable: true,
                  button: true

                },
                {
                  name: 'Qtde',
                  selector: 'quantidade',
                  button: true,
                },
                {
                  name: 'Total',
                  selector: 'total',
                  cell: (v) => maskReais(v.total),
                  sortable: true,
                }
              ]}
              data={info?.itempedidovenda}
            />
          </div>

        </div>
      </>
    );
  }
}

class SalePdf extends React.Component {
  render() {
    return (
      <div style={{ marginTop: 18 }}>
        {this.props.showButtonNewSale && (
          <Button variant='outlined' onClick={() => window.location.reload()}><ReplayOutlined /> &nbsp; NOVO PEDIDO</Button>
        )}
        <ReactToPrint
          trigger={() => <Button variant='outlined' style={{ marginLeft: 28 }}><PictureAsPdfOutlined /> &nbsp; SALVAR PDF</Button>}
          content={() => this.componentRef}
        />
        <ComponentToPrint pedido={this.props.info} ref={(el) => (this.componentRef = el)} />
      </div>
    );
  }
}

export default SalePdf;
