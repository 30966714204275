import React, { useEffect, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { AddOutlined, CloudUploadOutlined } from '@material-ui/icons'
import { DropzoneDialog } from 'material-ui-dropzone'
import handlersUpload from '../../handlers/Upload'



const _Upload = (props) => {
  const [open, setOpen] = useState(false)
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
      return Promise.resolve(reader.result)
    })
  }

  const getBaseData = async (_file) => {
    if (_file) {
      let base64 = ''
      base64 = await getBase64(_file)
      return base64
    }
  }

  const handleSubmit = async () => {
    if (files) {
      setLoading(true)
      for await (const _file of files) {
        const b64 = await getBaseData(_file)
        await sendFile({
          idpedidovenda: props.idpedidovenda,
          nome: _file.name,
          arquivo: b64,
          tipo: props.tipo,
        })
      }
      setOpen(false)
      setLoading(false)
      props.refresh()
    }
  }

  const sendFile = async (file) => {
    try {
      await handlersUpload._register(file)
    } catch (err) {
      // return console.log(err)
    }
  }

  const handleClose = () => {
    setOpen(false)
    props.onClose()
  }

  const handleSave = (files) => {
    setFiles(files)
  }

  const handleOpen = () => {
    setOpen(true)
    props.handleCloseModalPai()
  }

  useEffect(() => {
    if (files.length > 0) {
      handleSubmit()
    }
  }, [files])

  return (
    <>
      <Button onClick={handleOpen} disabled={loading} color="primary" variant="contained" >
        {loading ? <CircularProgress color='inherit' size={20} /> : <><CloudUploadOutlined /> &nbsp;<span>Adicionar</span></>}
      </Button>
      <DropzoneDialog
        dialogTitle={<span>Upload</span>}
        open={open}
        cancelButtonText='Cancelar'
        submitButtonText='Enviar'
        onSave={handleSave}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={handleClose}
      />
    </>
  )
}

export default _Upload
