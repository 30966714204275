import api from "../../services/api"
import IUser from "../../types/IUser"
import { getToken } from "../../util"

const _register = async (user: IUser, ativo: boolean): Promise<any> => {
  try {
    const response = await api.post('user/register',
      { ...user, ativo },
      { headers: { 'x-access-token': await getToken() } }
    )
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao registrar usuário" }
  }
}

export default _register
