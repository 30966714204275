import api from "../../services/api"
import IInventory from "../../types/IInventory"
import { getToken } from "../../util"

const _list = async (idprodutograde: string): Promise<IInventory[]> => {
  try {
    const response = await api.get(`product/inventory/grade/${idprodutograde}`, {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

export default _list
