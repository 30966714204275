/* eslint-disable import/no-anonymous-default-export */
import _register from "./register";
import _list from "./list";
import _listOne from "./listOne";
import _update from "./update";
import _updateItem from "./updateItem";
import _listOrderSituation from "./listOrderSituation";

export default {
  _register,
  _list,
  _listOne,
  _listOrderSituation,
  _update,
  _updateItem,
}
