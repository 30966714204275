import api from "../../services/api"
import { getToken } from "../../util"

const _delete = async (id: number): Promise<any> => {
  try {
    await api.delete(`debts/${id}`,
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: `Sucesso ao excluir conta` }
  } catch (err) {
    return { error: true, visible: true, message: `Erro ao excluir conta` }
  }
}

export default _delete
