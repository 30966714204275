import api from "../../services/api"
import ISale from "../../types/ISale"
import { getToken } from "../../util"

interface IRegisterSale {
  idpedidovenda?: number
  error?: boolean
  visible?: boolean
  message?: string
}

const _register = async (orderSale: ISale): Promise<IRegisterSale> => {
  try {
    const response = await api.post('sale/register',
      { ...orderSale },
      { headers: { 'x-access-token': await getToken() } }
    )
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao incluir pedido" }
  }
}

export default _register
