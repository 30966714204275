import api from "../../services/api"
import ICustomer from "../../types/ICustomer"
import { getToken } from "../../util"

const _list = async (): Promise<ICustomer[]> => {
  try {
    const response = await api.get('client/list', {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data.map(
      (row: ICustomer) => {
        return {
          ...row,
          nome: row.nome.toUpperCase(),
          cnpj_cpf: row.cnpj_cpf.toString().padStart(row.juridico ? 14 : 11, '0')
        }
      }
    )
  } catch (err) {
    return []
  }
}

export default _list
