import api from "../../services/api"
import ISalesReport from "../../types/ISalesReport"
import { getToken } from "../../util"

const _listOne = async (id: number): Promise<ISalesReport[]> => {
  try {
    const response = await api.get(`sale/list/${id}`, {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

export default _listOne
