import React from 'react'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { InputAdornment } from '@material-ui/core'
import { MonetizationOnOutlined } from '@material-ui/icons'

const InputMoney = (props) => {
  return (
    <CurrencyTextField
      value={props.value}
      // error={props.error}
      label={props.label || 'Valor'}
      currencySymbol=""
      outputFormat="string"
      decimalCharacter=","
      digitGroupSeparator="."
      style={{ width: '100%', marginTop: 5 }}
      onChange={(event, value) => props.onChange(value)}
    />
  )
}

export default InputMoney
