import api from "../../services/api"
import { getToken } from "../../util"

const _delete = async (id: number, cnpj_cpf: string, ativo: boolean): Promise<any> => {
  try {
    await api.put(`client/update/${id}`, {
      ativo: !ativo,
      cnpj_cpf
    },
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: `Sucesso ao ${ativo ? 'Inativar' : 'Ativar'} cliente` }
  } catch (err) {
    return { error: true, visible: true, message: `Erro ao ${ativo ? 'Inativar' : 'Ativar'} cliente` }
  }
}

export default _delete
