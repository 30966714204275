import * as React from 'react';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import local from 'date-fns/locale/pt-BR'

export default function RangePicker(props: any) {

  const value = props.value
  const setValue = (range: any) => props.onChange(range)

  return (
    <LocalizationProvider locale={local} dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText="Data inicio"
        endText="Data fim"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField
              {...startProps}
              variant='standard'
              size='small'
              fullWidth
              // inputProps={{
              //   autoComplete: 'off'
              // }}
              autoComplete='off'
            />
            <Box sx={{ mx: 1 }}> até </Box>
            <TextField
              {...endProps}
              variant='standard'
              size='small'
              fullWidth
              // inputProps={{
              //   autoComplete: 'off'
              // }}
              autoComplete='off'
            />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}
