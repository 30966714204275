import api from "../services/api"

export const formatDate = (dt: string, isTimestamp: true | false) => {
  return !isTimestamp ? new Date(dt).toLocaleDateString() : new Date(dt).toLocaleString()
}

export const titleize = (text: string) => {
  var words = text.toLowerCase().split(" ")
  for (var a = 0; a < words.length; a++) {
    var w = words[a]
    words[a] = w[0].toUpperCase() + w.slice(1)
  }
  return words.join(" ")
}

export const maskReais = (val: number) => {
  return Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(val).replace('R$','')
}

export const maskDecimal = (val: number, precision: number) => {
  return Intl.NumberFormat("pt-BR", {
    maximumFractionDigits: precision
  }).format(val)
}

export const maskReverse = (val: string) => {
  val = val.replace("R$", "").trim()
  var group = new Intl.NumberFormat("pt-BR").format(1111).replace(/1/g, "")
  var decimal = new Intl.NumberFormat("pt-BR").format(1.1).replace(/1/g, "")
  var reversedVal = val.replace(new RegExp("\\" + group, "g"), "")
  reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".")
  return Number.isNaN(reversedVal) ? 0 : reversedVal
}

export const onlyUnique = (value: any, index: any, self: any) => {
  return self.indexOf(value) === index
}

export const maskPercent = (val: number) => {
  return Intl.NumberFormat("pt-BR", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 4
  }).format(val)
}

export const addDays = (dateObj: any, numDays: number) => {
  dateObj.setDate(dateObj.getDate() + numDays)
  return dateObj
}

export const formatDate2 = (date: any) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) {
    month = '0' + month
  }
  if (day.length < 2) {
    day = '0' + day
  }

  return [year, month, day].join('-')
}

export const addMonths = (date: any, months: number) => {
  var d = date.getDate()
  date.setMonth(date.getMonth() + +months)
  if (date.getDate() !== d) {
    date.setDate(0)
  }
  return date
}

export const getToken = async () => {
  try {
    const userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      const { login, pass, token } = JSON.parse(userInfo)
      const response = await api.post('auth/login', {
        login,
        pass
      })
      return response.data.token ? response.data.token : token
    }
  } catch (err) {
    return undefined
  }
}
