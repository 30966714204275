import api from "../../services/api"
import ITrama from "../../types/ITrama"
import { getToken } from "../../util"

const _list = async (): Promise<ITrama[]> => {
  try {
    const response = await api.get('product/trama/list', {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

export default _list
