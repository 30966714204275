import api from "../../services/api"

const _delete = async (id: number): Promise<any> => {
  try {
    await api.delete(`product/${id}`)
    return { error: false, visible: true, message: "Sucesso ao inativar tipo de usuário" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao inativar tipo de usuário" }
  }
}

export default _delete
