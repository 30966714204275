import api from "../../services/api"
import IUserTypes from "../../types/IUserTypes"
import { getToken } from "../../util"

const _list = async (): Promise<IUserTypes[]> => {
  try {
    const response = await api.get('user/type/list', {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

export default _list
