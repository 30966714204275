import api from "../../services/api"
import { getToken } from "../../util"

interface IUpdateSale {
  error?: boolean
  visible?: boolean
  message?: string
}

const _update = async (idpedidovenda: string, orderSale: any): Promise<IUpdateSale> => {
  try {
    await api.put(`sale/update/${idpedidovenda}`,
      { ...orderSale },
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: "Sucesso ao atualizar dados do pedido" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao atualizar dados do pedido" }
  }
}

export default _update
