import api from "../../services/api"
import { getToken } from "../../util"

interface IMovimentCreateReturn {
  error: boolean
  visible: boolean
  message: string
}

interface IMoviment {
  idfilial: number
  idprodutograde: number
  quantidade: number
  idtipomovimento: number
  idusuario: number
}

const _register = async (moviment: IMoviment): Promise<IMovimentCreateReturn> => {
  try {
    await api.post('movement/register',
      { ...moviment },
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: "Sucesso ao incluir movimentação!" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao registrar movimentação!" }
  }
}

export default _register
