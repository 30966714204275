import React from 'react'
import { Button } from '@material-ui/core'
import DataTable from 'react-data-table-component'
import styled from 'styled-components'
import './styles.css'
import { CircularProgress } from '@material-ui/core'
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const _DataTable = (props) => {
  const filteredItems = props.data

  const blankRow = {
    arquivos: "-",
    cidade: "-",
    cliente: "-",
    comentarios: "-",
    coraluminio: "-",
    corfibra: "-",
    data_inclusao: "-",
    data_pedido: "-",
    data_prevista: "-",
    entrada: "-",
    filial: "-",
    idcliente: "-",
    idfilialorigem: "-",
    idfilialsaida: "-",
    iditempedidovenda: "-",
    idorigempedido: "-",
    idpedidovenda: "-",
    idsituacaopedidovenda: "-",
    idtiny: "-",
    idtrial: "-",
    idusuario: "-",
    itempedidovenda: "-",
    montagem: "-",
    numerotiny: "-",
    observacao: "-",
    origempedido: "-",
    parcelas: "-",
    producao: "-",
    produto: "-",
    quantidade: "-",
    quantidadeparcela: "-",
    sequenciatiny: "-",
    situacaopedidovenda: "-",
    tapecaria: "-",
    trama: "-",
    trama_desc: "-",
    trial: "-",
    uf: "-",
    usuario: "-",
    valor_comdesconto: "-",
    valor_total: "-",
  }

  const serializedDataProd = (data) => {
    if (props.isProdReport) {
      const newData = []
      for (let d of data) {
        newData.push(d)
        newData.push(blankRow)
      }
      return newData
    } else {
      return data
    }
  }

  const exportData = {
    columns: props.columns,
    data: serializedDataProd(props.data)
  }

  return (
    <>
      {
        (filteredItems || props.loading) ?
          <DataTableExtensions {...exportData}  >
            <DataTable
              selectableRows={props.selectable}
              onSelectedRowsChange={({ selectedRows }) => props?.changeRow(selectedRows)}
              columns={props.columns}
              data={filteredItems}
              pagination
              striped
              highlightOnHover
              persistTableHead
              paginationPerPage={15}
            />
          </DataTableExtensions>
          : (<h4 style={{ textAlign: 'center' }}><CircularProgress /> <br /> Carregando...</h4>)
      }
    </>
  )
}

export default _DataTable
