import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import {
  Container, IconButton, Divider, List, Drawer,
  CssBaseline, makeStyles, ThemeProvider, createMuiTheme,
  ListSubheader, ListItemText, ListItemIcon, ListItem
} from '@material-ui/core'
import {
  MenuOutlined, ExitToAppOutlined, ArrowLeftOutlined,
  PersonOutline, DashboardOutlined, ShoppingCartOutlined,
  PersonOutlined, StoreOutlined, LocalOfferOutlined,
  AddBoxOutlined, GroupAddOutlined, AccountCircleOutlined,
  AssignmentOutlined, VpnKeyOutlined, TableChartOutlined,
  ArchiveOutlined
} from '@material-ui/icons'
import logo from '../../assets/logo.png'
import './styles.css'
const drawerWidth = 250

interface IUser {
  idusuario: number
  login: string
  nome: string
  email: string
  idtipousuario: number
  tipousuario_desc: string
  ativo: boolean
  auth: boolean
  token: string
}

interface IProps {
  location?: {
    pathname: string
    search: string
  }
}

const _Layout: React.FC<IProps> = (props): React.ReactElement => {
  const classes = useStyles()
  const history = useHistory()
  const [darkState, setDarkState] = useState(false)
  const [open, setOpen] = useState(false)
  const [activePage, setActivePage] = useState('dashboard')
  const [userInfo, setUserInfo] = useState<IUser>()

  useEffect(() => {
    if (props.location?.pathname) {
      setActivePage(props.location.pathname)
      handleGetUserInfo()
    }
  }, [props])

  const handleGetUserInfo = async (): Promise<void> => {
    const user = localStorage.getItem('userInfo')
    if (user) {
      const obj_user = JSON.parse(user)
      if (obj_user.idusuario > 0) {
        setUserInfo(obj_user)
      } else {
        alert('acesso restrito')
        return history.push('/')
      }
    } else {
      alert('acesso restrito')
      return history.push('/')
    }
  }

  const theme = createMuiTheme({
    palette: {
      type: darkState ? 'dark' : 'light'
    }
  })

  const handleThemeChange = (): void => {
    setDarkState(!darkState)
  }

  const handleDrawerOpen = (): void => {
    setOpen(true)
  }
  const handleDrawerClose = (): void => {
    setOpen(false)
  }

  const menus = [
    {
      url: '/admin/sales',
      title: 'Vendas',
      visible: true,
      icon: <ShoppingCartOutlined className="white" />
    },
    {
      url: '/admin/report',
      title: 'Relatório',
      visible: true,
      icon: <AssignmentOutlined className="white" />
    },
    {
      url: '/admin/customer',
      title: 'Cliente/Fornecedor',
      visible: true,
      icon: <GroupAddOutlined className="white" />
    },
    {
      url: '/admin/product',
      title: 'Produtos',
      visible: true,
      icon: <LocalOfferOutlined className="white" />
    },
    {
      url: '/admin/user',
      title: 'Usuários',
      visible: true,
      icon: <PersonOutlined className="white" />
    },
    {
      url: '/admin/branchs',
      title: 'Filiais',
      visible: true,
      icon: <StoreOutlined className="white" />
    },
    {
      url: '/admin/trial',
      title: 'Trial',
      visible: true,
      icon: <VpnKeyOutlined className="white" />
    }
  ]

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
          className="shadow-sidebar"
        >
          <div className="bg-sidebar" style={{ width: '100%', height: '100%' }}>
            <div className={classes.toolbarIcon}>
              {open && (
                <img src={logo} width={120} alt='Logo' />
              )}
              {open && (
                <IconButton onClick={handleDrawerClose}>
                  <ArrowLeftOutlined className="white" />
                </IconButton>
              )}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.menuButtonHidden)}
              >
                <MenuOutlined />
              </IconButton>
            </div>
            <Divider className="bg-white" />
            <ListItem button>
              <ListItemIcon className="item-menu">
                <AccountCircleOutlined className="white" />
              </ListItemIcon>
              <ListItemText
                id='user-info'
                primary={userInfo && userInfo.nome?.split(' ')[0] + ' ' + userInfo.nome?.split(' ')[userInfo.nome?.split(' ').length - 1]}
                secondary={userInfo && userInfo.tipousuario_desc}
              />
            </ListItem>
            <Divider className="bg-white" />
            <List>
              <ListSubheader className="white">Menu</ListSubheader>
              {menus.map((m, i) => {
                if ((userInfo?.idtipousuario === 4 && i < 4) || (userInfo?.idtipousuario === 2 && i < 3) || (userInfo?.idtipousuario !== 2 && i === 1) || userInfo?.idtipousuario === 1) {
                  return (
                    <Link key={m.url} to={m.url}>
                      <ListItem button className={activePage === m.url ? "bg-selected" : ''}>
                        <ListItemIcon className="item-menu">{m.icon}</ListItemIcon>
                        <ListItemText primary={m.title} />
                      </ListItem>
                    </Link>
                  )
                } else {
                  return null
                }
              })}
              <ListItem
                button
                onClick={() => {
                  localStorage.clear()
                  history.push('/')
                }}
              >
                <ListItemIcon className="item-menu">
                  <ExitToAppOutlined className="white" />
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItem>
              {/* <ListItem button >
                <Typography color="inherit" noWrap className={classes.title}>
                  <Switch color='default' checked={darkState} onChange={handleThemeChange} />
                </Typography>
              </ListItem> */}
            </List>
          </div>
        </Drawer>
        <main className={classes.content}>
          <Container maxWidth="xl" className={classes.container}>
            <>
              {props.children}
            </>
          </Container>
        </main>
      </div>
    </ThemeProvider >
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    alignItems: 'center'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}))

export default _Layout
