import api from "../../services/api"
import IDebts from "../../types/IDebts"
import { getToken } from "../../util"

const _list = async (params: any): Promise<IDebts[]> => {
  try {
    const response = await api.post('debts/list', {...params} ,{
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

export default _list
