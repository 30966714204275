import React from 'react'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import local from 'date-fns/locale/pt-BR'
import "./customDatePickerWidth.css";

interface IProps {
    label: string
    value: Date | null
    change: any
}

export default function DatePicker(props: IProps) {

    const handleChange = (newValue: Date | null) => {
        props.change(newValue)
    }

    return (
        <div className="customDatePickerWidth">
            <LocalizationProvider locale={local} dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    label={props.label}
                    inputFormat="dd/MM/yyyy"
                    value={props.value}
                    onChange={handleChange}
                    renderInput={(params) => <TextField variant='standard' {...params} />}
                />
            </LocalizationProvider>
        </div>
    )
}
