import api from "../../services/api"
import IColorAluminio from "../../types/IColorAluminio"
import { getToken } from "../../util"

const _register = async (coloraluminio: IColorAluminio): Promise<any> => {
  try {
    const response = await api.post('product/coloraluminio/register',
      { ...coloraluminio },
      { headers: { 'x-access-token': await getToken() } }
    )
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao registrar cor aluminio" }
  }
}

export default _register
