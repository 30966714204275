import api from "../../services/api"
import { getToken } from "../../util"

interface IValidateTrial {
  error?: boolean
  visible?: boolean
  message?: string
  valid?: boolean
  idtrial?: number
}

const _validate = async (trial: string): Promise<IValidateTrial> => {
  try {
    const response = await api.get(`sale/trial/list/${trial}`,
      { headers: { 'x-access-token': await getToken() } }
    )
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Trial inválido" }
  }
}

export default _validate
