import _register from "./register";
import _delete from "./delete";
import _list from "./list";
import _update from "./update";

export default {
  _register,
  _delete,
  _list,
  _update
}
