import React, { useState, useEffect, useRef } from 'react'
import handlersSales from '../../../handlers/Sales'
import {
  makeStyles, TextField, Theme, Divider, Tooltip,
  CircularProgress, Drawer, Typography, Container,
  Card, Button, IconButton, Switch, CardHeader,
  Breadcrumbs, Badge, List, ListItem, ListItemText,
  AppBar, TextareaAutosize, Tabs, Tab, Box, FormControlProps
} from '@material-ui/core/'
import { Alert } from '@material-ui/lab/'
import {
  AddOutlined, DeleteOutlined, RefreshOutlined,
  EditOutlined, ShoppingCartOutlined, ShoppingCart,
  ListOutlined, AttachFileOutlined, ChatOutlined,
  SearchOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  SyncOutlined,
  ArchiveOutlined,
  LocalShippingOutlined
} from '@material-ui/icons/'
import DataTable from '../../../components/DataTable'
import SnackAlert from '../../../components/Alert'
import FadeIn from 'react-fade-in'
import Modal from '../../../components/Modal'
import ISalesReport from '../../../types/ISalesReport'
import { getToken, maskReais } from '../../../util'
import './styles.css'
import ICart from '../../../types/ICart'
import handlersSaleComment from '../../../handlers/SaleComment'
import ISaleComment from '../../../types/ISaleComment'
import api from '../../../services/api'

const ProdReport = (props: any): React.ReactElement => {

  const [loading, setLoading] = useState(false)
  const [salesData, setSalesData] = useState<any>([])
  const [selectedPedidoDetalhe, setSelectedPedidoDetalhe] = useState<ISalesReport>()
  const [isVisibleModalDetalhes, setIsVisibleModalDetalhes] = useState(false)
  const [arrProdutos, setArrProdutos] = useState([])
  const [isVisibleModalProdutos, setIsVisibleModalProdutos] = useState(false)
  const [alert, setAlert] = useState({
    visible: false,
    message: '',
    error: false
  })


  const getUserType = (): number => {
    const user = localStorage.getItem('userInfo')
    if (user) {
      const obj_user = JSON.parse(user)
      const { idtipousuario } = obj_user
      return Number(idtipousuario)
    }

    return 0
  }


  const getSalesReport = async (): Promise<void> => {
    setLoading(true)
    const response = await api.post('sale/list/', { idsituacaopedidovenda: [3] }, {
      headers: {
        'x-access-token': await getToken()
      }
    })
    const sortered = response.data.sort((a: any, b: any) => (new Date(a.data_prevista) > new Date(b.data_prevista)) ? +1 : -1)

    let newArr: any = []

    for (let ped of sortered) {
      for (let it of ped.itempedidovenda) {
        newArr.push({
          ...ped,
          iditempedidovenda: it.iditempedidovenda,
          produto: it.produto,
          trama_desc: it.trama_desc,
          trama: it.trama,
          coraluminio: it.coraluminio,
          corfibra: it.corfibra,
          quantidade: it.quantidade,
          montagem: it.montagem,
          tapecaria: it.tapecaria
        })
      }
    }
    setSalesData(newArr)

    setLoading(false)
  }

  const updateSaleSituation = async (id: string): Promise<void> => {

    const update = await handlersSales._update(id, { idsituacaopedidovenda: 4 })

    if (update?.error) {
      setAlert({ error: true, visible: true, message: 'Erro ao atualizar status do pedido' })
    } else {
      setAlert({ error: false, visible: true, message: 'Sucesso ao atualizar status do pedido' })
    }

    getSalesReport()
  }


  const verifyProd = (obj: any) => {
    let bool = false
    for (let i of obj.itempedidovenda) {
      if (!i.montagem) {
        bool = true
      }
      if (!i.montagem) {
        bool = true
      }
      if (!i.trama) {
        bool = true
      }
    }
    return bool
  }

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({ ...alert, visible: false })
      }, 3000)
    }
  }, [alert.visible])

  useEffect(() => {
    getSalesReport()
  }, [])

  return (
    <>
      <SnackAlert
        alert={alert}
        align='center'
        position='top'
      />
      <FadeIn delay={200} transitionDuration={200}>
        <Card style={{ padding: 15, marginTop: 30, borderRadius: 10 }}>
          <h2 style={{ textAlign: 'center' }}>{props?.selected?.descricao} {loading && (<CircularProgress size={17} />)}</h2>
          <Divider />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginTop: 18 }} />
            <DataTable
              columns={[
                {
                  name: 'Dt. Prevista',
                  cell: (obj: any) => obj.data_prevista === '-' ? '__________' : new Date(obj.data_prevista).toLocaleDateString('pt-br'),
                  button: true
                },
                {
                  name: 'Cliente',
                  cell: (obj: any) => obj.data_prevista === '-' ? '________________' : obj?.cliente[0]?.nome?.toUpperCase(),
                },
                {
                  name: 'Observações',
                  cell: (obj: any) => {
                    return (
                      <>
                        {obj?.observacao === '-' ? '________________' : (
                          <TextareaAutosize
                            style={{ width: 200, padding: 1 }}
                            aria-label="minimum height"
                            maxRows={3}
                            disabled
                            value={obj?.observacao}
                            placeholder=""
                          />
                        )}
                      </>
                    )
                  },
                },
                {
                  name: 'Descrição do produto',
                  cell: (obj: any) => obj.produto === '-' ? '____________________' : obj.produto,
                  sortable: true,
                },
                {
                  name: 'Nome da trama',
                  cell: (obj: any) => obj.trama_desc === '-' ? '__________' : obj.trama_desc,
                  button: true,
                  sortable: true,
                },
                {
                  name: 'Cor alumínio',
                  cell: (obj: any) => obj.coraluminio === '-' ? '__________' : obj.coraluminio,
                  sortable: true,
                  button: true,
                },
                {
                  name: 'Cor da fibra',
                  cell: (obj: any) => obj.corfibra === '-' ? '__________' : obj.corfibra,
                  sortable: true,
                  button: true,
                },
                {
                  name: 'Qtd',
                  cell: (obj: any) => obj.quantidade === '-' ? '____' : obj.quantidade,
                  sortable: true,
                  button: true,
                  right: true
                },
                {
                  name: 'Montagem',
                  button: true,
                  cell: (obj: any) => (
                    <>
                      {obj.montagem === '-' ? '__________' : (
                        <>
                          <Switch
                            color='primary'
                            disabled={loading || ![1, 3, 5].includes(getUserType())}
                            checked={obj.montagem}
                            onChange={async e => {
                              const update = await handlersSales._updateItem(
                                obj.iditempedidovenda,
                                { montagem: !obj.montagem }
                              )
                              setAlert(update)
                              getSalesReport()
                            }}
                          />
                          <small>{obj.montagem ? 'Ok' : '-'}</small>
                        </>
                      )}
                    </>
                  )
                },
                {
                  name: 'Trama',
                  button: true,
                  cell: (obj: any) => (
                    <>
                      {obj.trama === '-' ? '______' : (
                        <>
                          <Switch
                            color='primary'
                            disabled={!obj.montagem || loading || ![1, 3, 5].includes(getUserType())}
                            checked={obj.trama}
                            onChange={async e => {
                              const update = await handlersSales._updateItem(
                                obj.iditempedidovenda,
                                { trama: !obj.trama }
                              )
                              setAlert(update)
                              getSalesReport()
                            }}
                          />
                          <small>{obj.trama ? 'Ok' : '-'}</small>
                        </>
                      )}
                    </>
                  )
                },
                {
                  name: 'Tapeçaria',
                  button: true,
                  cell: (obj: any) =>  (
                    <>
                      {obj.tapecaria === '-' ? '__________' : (
                        <>
                          <Switch
                            color='primary'
                            disabled={!obj.montagem || loading || ![1, 3, 5].includes(getUserType())}
                            checked={obj.tapecaria}
                            onChange={async e => {
                              const update = await handlersSales._updateItem(
                                obj.iditempedidovenda,
                                { tapecaria: !obj.tapecaria }
                              )
                              setAlert(update)
                              getSalesReport()
                            }}
                          />
                          <small>{obj.tapecaria ? 'Ok' : '-'}</small>
                        </>
                      )}
                    </>
                  )  
                },
                {
                  name: 'Liberar p/ entrega',
                  cell: (obj: any) =>  (
                    <>
                      {obj.tapecaria === '-' ? '__________' : (
                        <>
                          <Button
                            variant="contained"
                            style={{ display: 'flex', justifyContent: 'flex-start', alignContent: 'center' }}
                            color="primary"
                            disabled={verifyProd(obj) || ![1, 3, 5].includes(getUserType())}
                            onClick={async () => {
                              await updateSaleSituation(String(obj?.idpedidovenda))
                            }}
                          >
                            <LocalShippingOutlined />
                            <small>&nbsp; {!verifyProd(obj) ? 'Liberar' : 'Pendente'}</small>
                          </Button>
                        </>
                      )}
                    </>
                  )  
                },
              ]}
              data={salesData}
            />
          </div>
        </Card>
      </FadeIn>
    </>
  )
}



const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default ProdReport
