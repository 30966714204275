import React, { useState, useEffect } from 'react'
import handlersTrial from '../../handlers/Trial'
import {
  makeStyles,
  TextField,
  Theme,
  Divider,
  CircularProgress,
  Drawer,
  Typography,
  Container,
  Card,
  Button,
  IconButton,
  CardHeader
} from '@material-ui/core/'
import { Alert } from '@material-ui/lab/'
import { AddOutlined, DeleteOutlined, RefreshOutlined, EditOutlined } from '@material-ui/icons/'
import FadeIn from 'react-fade-in'
import SnackAlert from '../../components/Alert'

const Trial = (): React.ReactElement => {
  const [alert, setAlert] = useState({
    visible: false,
    message: '',
    error: false
  })
  const [trial, setTrial] = useState('')
  const [loading, setLoading] = useState(false)
  const classes = useStyles()

  const createTrial = async (): Promise<void> => {
    setLoading(true)
    const user = localStorage.getItem('userInfo')
    if (user) {
      const obj_user = JSON.parse(user)
      if (obj_user.idusuario > 0) {
        const trialData = await handlersTrial._register(obj_user.idusuario)
        const trialString = trialData?.trial ? trialData.trial : undefined
        if (trialString) {
          setTrial(trialString)
        } else {
          setAlert({ error: true, visible: true, message: 'Erro ao gerar trial' })
        }
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({ ...alert, visible: false })
      }, 3000)
    }
  }, [alert.visible])



  return (
    <>
      <SnackAlert
        alert={alert}
        align='center'
        position='top'
      />
      <Container maxWidth='xl'>
        <FadeIn delay={200} transitionDuration={200}>
          <Typography variant='h5' color='textSecondary'>
            Trial
          </Typography>
          <Card style={{ padding: 15, marginTop: 30, borderRadius: 10 }}>
            <CardHeader
              action={
                <>
                </>
              }
              title='Gerador'
            />

            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
              <div>
                <TextField
                  size='small'
                  variant="outlined"
                  margin="normal"
                  value={trial}
                  label="Trial"
                />
              </div>
              <div style={{ marginLeft: 8 }}>
                <Button
                  size='small'
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                  onClick={() => createTrial()}
                >
                  {loading ? <CircularProgress size={26} color="inherit" /> : (<> <RefreshOutlined /> Gerar </>)}
                </Button>
              </div>
            </div>


          </Card>
        </FadeIn>
      </Container>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default Trial
