import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, makeStyles, Typography, Box, FormControlLabel, TextField, Button, CircularProgress, Snackbar, Checkbox, InputAdornment, IconButton } from '@material-ui/core'
import { ExitToAppOutlined, PersonOutline, LockOutlined, VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { useForm, Controller } from "react-hook-form";
import api from '../../services/api'
import FadeIn from 'react-fade-in'
import logo from '../../assets/logo.png'
import { Colors } from '../../constants/colors'

interface ILogin {
  user: string
  password: string
}

const Login = (): React.ReactElement => {
  const [remember, setRemember] = useState(true)
  const [passVisible, setPassVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [msgVisible, setMsgVisible] = useState(false)
  const { handleSubmit, errors, control } = useForm();
  const history = useHistory()

  const handleLogin = async (data: ILogin): Promise<void> => {
    setLoading(true)
    try {
      const response = await api.post('auth/login', {
        login: data.user,
        pass: data.password
      })
      // console.log('userInfo', response.data)
      localStorage.setItem('userInfo', JSON.stringify({ ...response.data, pass: data.password }))
      history.push('admin/report')
      // localStorage.setItem('remember', remember.toString())
    } catch (err) {
      setMsgVisible(true)
      setLoading(false)
    }
  }

  // const handleRemember = () => {
  //   setRemember(!remember)
  // }

  const handleCloseMsg = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setMsgVisible(false)
  }

  const classes = useStyles()

  return (
    <Container component="main" maxWidth="xs">
      <FadeIn delay={200} transitionDuration={1000}>
        <div className={classes.paper}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={msgVisible}
            autoHideDuration={5000}
            onClose={handleCloseMsg}
          >
            <Alert onClose={handleCloseMsg} severity="error">
              Usuário não encontrado
            </Alert>
          </Snackbar>
          <img src={logo} width={350} alt='Logo' />
          <div style={{ backgroundColor: Colors.greenLight, padding: 50, display: 'flex', flexDirection: 'column', marginTop: 5, minWidth: 500 }}>
            <p className="font-default title">LOGIN</p>
            <div style={{ width: 71, height: 5, backgroundColor: Colors.greenDark, alignSelf: 'center' }} />

            <form
              className={classes.form}
              autoComplete="off"
              onSubmit={handleSubmit(handleLogin)}
            >
              <Controller
                name='user'
                defaultValue=''
                control={control}
                rules={{ required: true }}
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    error={errors.user}
                    helperText={errors.user && "Verifique seu usuário"}
                    variant="filled"
                    margin="normal"
                    style={{ background: Colors.white }}
                    value={value}
                    fullWidth
                    id="usuario"
                    label="Digite seu usuário"
                    name="usuario"
                    autoFocus
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutline />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
              <Controller
                name='password'
                defaultValue=''
                control={control}
                rules={{ required: true }}
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    error={errors.password}
                    helperText={errors.password && "Verifique sua senha"}
                    variant="filled"
                    style={{ background: Colors.white }}
                    margin="normal"
                    value={value}
                    fullWidth
                    type={passVisible ? '' : 'password'}
                    label="Digite sua senha"
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlined />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setPassVisible(!passVisible)}>
                            {!passVisible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
              {/* <FormControlLabel
                control={<Checkbox checked={remember} onChange={handleRemember} color="primary" />}
                label="Lembrar"
              /> */}
              <Button
                startIcon={<ExitToAppOutlined />}
                type="submit"
                fullWidth
                variant="contained"
                style={{ backgroundColor: Colors.yellow }}
                size='large'
                className={classes.submit}
                disabled={errors.user || errors.password}
              >
                {loading ? <CircularProgress size={26} color="inherit" /> : 'Entrar'}
              </Button>
            </form>
          </div>
        </div>
      </FadeIn>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default Login
