/* eslint-disable import/no-anonymous-default-export */
import _register from "./register";
import _list from "./list";
import _listTypes from "./listTypes";
import _listDebtsTypes from "./typeDebts";
import _update from "./update";
import _delete from "./delete";
import _updateConta from "./updateConta";
import _registerType from "./typeDebtsRegister";
import _updateType from "./typeDebtsUpdate";

export default {
  _register,
  _list,
  _listDebtsTypes,
  _listTypes,
  _update,
  _delete,
  _updateConta,
  _registerType,
  _updateType
}
