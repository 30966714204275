import api from "../../services/api"
import IBranchType from "../../types/IBranchType"
import { getToken } from "../../util"

const _register = async (branchType: IBranchType): Promise<any> => {
  try {
    const response = await api.post('branch/type/register', { ...branchType }, { headers: { 'x-access-token': await getToken() } })
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao registrar filial" }
  }
}

export default _register
