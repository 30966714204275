import api from "../../services/api"
import IBranchTypes from "../../types/IBranchType"
import { getToken } from "../../util"

const _update = async (branchType: IBranchTypes): Promise<any> => {
  try {
    const response = await api.put(`branch/type/update/${branchType?.idtipofilial}`, { ...branchType }, { headers: { 'x-access-token': await getToken() } })
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao atualizar filial" }
  }
}

export default _update
