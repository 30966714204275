import api from "../../services/api"
import { getToken } from "../../util"

const _listTypes = async (): Promise<IReturn[]> => {
  try {
    const response = await api.get('debts/typedocument', {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

interface IReturn {
    idtipodocumento: number
    descricao: string
}

export default _listTypes
