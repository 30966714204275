import api from "../../services/api"
import IProduct from "../../types/IProduct"
import { getToken } from "../../util"

const _update = async (product: IProduct): Promise<any> => {
  try {
    const response = await api.put(`product/update/${product.idproduto}`, { ...product }, { headers: { 'x-access-token': await getToken() } })
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao atualizar produto" }
  }
}

export default _update
