import api from "../../services/api"
import { getToken } from "../../util"

interface IReturn {
  error: boolean
  visible: boolean
  message: string
}

const _updateConta = async (obj: any): Promise<IReturn> => {
  try {
    await api.put(`debts/parcelvalue/${obj.idcontaspagar}`,
      { ...obj },
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: "Sucesso ao atualizar conta" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao atualizar conta" }
  }
}

export default _updateConta
