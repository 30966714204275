import api from "../../services/api"
import ICustomer from "../../types/ICustomer"
import { getToken } from "../../util"

const _register = async (customer: ICustomer, juridico: boolean, fornecedor: boolean): Promise<any> => {
  try {
    const response = await api.post('client/register',
      { ...customer, juridico, fornecedor },
      { headers: { 'x-access-token': await getToken() } }
    )
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao registrar cliente" }
  }
}

export default _register
