import api from "../../services/api"
import ISaleComment from "../../types/ISaleComment"
import { getToken } from "../../util"

const _list = async (id: number): Promise<ISaleComment[]> => {
  try {
    const response = await api.get(`/sale/comment/list/${id}`, {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

export default _list
