import React, { useState, useEffect } from 'react'
import handlersBranch from '../../handlers/Branch'
import handlersBranchTypes from '../../handlers/BranchTypes'
import {
  makeStyles,
  TextField,
  Theme,
  Divider,
  Tooltip,
  CircularProgress,
  Drawer,
  Typography,
  Container,
  Card,
  Button,
  IconButton,
  Switch,
  CardHeader
} from '@material-ui/core/'
import { Alert } from '@material-ui/lab/'
import { AddOutlined, DeleteOutlined, RefreshOutlined, EditOutlined } from '@material-ui/icons/'
import { useForm, Controller } from "react-hook-form";
import DataTable from '../../components/DataTable'
import SnackAlert from '../../components/Alert'
import FadeIn from 'react-fade-in'
import api from '../../services/api';
import IBranch from '../../types/IBranch'
import ComboBox from '../../components/ComboBox';
import { getToken } from '../../util';
import IBranchTypes from '../../types/IBranchType'

const blankForm = {
  idfilial: 0,
  cnpj: '',
  descricao: '',
  cidade: '',
  uf: '',
  idtipofilial: 0,
  ativo: true,
  tipo_desc: ''
}

const Branch = (): React.ReactElement => {
  const [ativo, setAtivo] = useState(false)
  const [loading, setLoading] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [branchs, setBranchs] = useState<IBranch[]>([])
  const [branchTypes, setBranchTypes] = useState<IBranchTypes[]>([])
  const [edit, setEdit] = useState<IBranch>(blankForm)
  const [alert, setAlert] = useState({
    visible: false,
    message: '',
    error: false
  })

  const { handleSubmit, errors, control } = useForm();
  const classes = useStyles()

  useEffect(() => {
    getBranchs()
    getBranchTypes()
  }, [])

  const getBranchs = async (): Promise<void> => {
    const data = await handlersBranch._list()
    setBranchs(data)
  }
  const getBranchTypes = async (): Promise<void> => {
    const data = await handlersBranchTypes._list()
    setBranchTypes(data)
  }

  const handleDelete = async (idcliente: number): Promise<void> => {
    // try {
    //   await api.delete(`client/${idcliente}`)
    //   getBranchs()
    // } catch (err) {
    //   setAlert({
    //     message: err.response.data.message ? err.response.data.message : 'Erro ao excluir usuário',
    //     visible: true,
    //     error: true
    //   })
    // }
  }

  const handleRegister = async (branch: IBranch): Promise<void> => {
    setLoading(true)
    setDrawerVisible(false)
    const register = edit.idfilial ?
      await handlersBranch._update({ ...branch, idfilial: edit.idfilial, ativo })
      :
      await handlersBranch._register({ ...branch, ativo })

    setAlert(register)
    setLoading(false)
    getBranchs()
    setEdit(blankForm)
  }
  const handleEdit = (obj: IBranch) => {
    setEdit(obj)
    setAtivo(obj.ativo)
    setDrawerVisible(true)
  }

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({ ...alert, visible: false })
      }, 3000)
    }
  }, [alert.visible])

  return (
    <>
      <SnackAlert
        alert={alert}
        align='center'
        position='top'
      />
      <Container maxWidth='xl'>
        <FadeIn delay={200} transitionDuration={200}>
          <Typography variant='h5' color='textSecondary'>
            Filiais
          </Typography>
          <Card style={{ padding: 15, marginTop: 30, borderRadius: 10 }}>
            <CardHeader
              action={
                <>
                  <Tooltip title='Atualizar'>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={async () => await getBranchs()}
                      style={{ borderRadius: '50%', height: 60 }}
                    >
                      <RefreshOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title='Cadastrar nova filial'>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={() => {
                        setDrawerVisible(true)
                        setEdit(blankForm)
                      }}
                      style={{ borderRadius: '50%', height: 60 }}
                    >
                      <AddOutlined />
                    </Button>
                  </Tooltip>
                </>
              }
              title='Lista de filiais'
            />
            <DataTable
              columns={[
                {
                  name: 'idfilial',
                  selector: 'idfilial',
                  sortable: true,
                },
                {
                  name: 'cnpj',
                  selector: 'cnpj',
                  sortable: true,
                },
                {
                  name: 'Descrição',
                  selector: 'descricao',
                  sortable: true
                },
                {
                  name: 'cidade',
                  selector: 'cidade',
                  sortable: true
                },
                {
                  name: 'uf',
                  selector: 'uf',
                  sortable: true
                },
                {
                  name: 'tipo',
                  selector: 'tipo_desc',
                  sortable: true
                },
                {
                  button: true,
                  cell: (obj: IBranch) => <IconButton aria-label="editar" onClick={() => handleEdit(obj)}><EditOutlined fontSize="small" /></IconButton>
                },
                // {
                //   button: true,
                //   cell: (obj: IBranch) => <IconButton aria-label="excluir" onClick={async () => await handleDelete(obj.idfilial)}><DeleteOutlined fontSize="small" /></IconButton>
                // }
              ]}
              data={branchs}
            />
          </Card>
        </FadeIn>
      </Container>

      <Drawer
        open={drawerVisible}
        anchor='right'
        onClose={() => setDrawerVisible(false)}
      >
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={handleSubmit(handleRegister)}
          style={{ width: 500 }}
        >
          <Typography variant='h5'>
            {edit.idfilial && edit.idfilial > 0 ? 'Editando filial' : 'Cadastrando filial'}
          </Typography>
          <Divider style={{ margin: '5px 0' }} />
          <Controller
            name='descricao'
            defaultValue={edit.descricao}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.descricao}
                helperText={errors.descricao && "Campo obrigatório"}
                variant="outlined"
                margin="normal"
                value={value}
                fullWidth
                label="Digite a descrição"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name='cnpj'
            defaultValue={edit.cnpj}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.cnpj}
                helperText={errors.cnpj && "Campo obrigatório"}
                variant="outlined"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o CNPJ"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name='cidade'
            defaultValue={edit.cidade}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.cidade}
                helperText={errors.cidade && "Campo obrigatório"}
                variant="outlined"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o cidade"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name='uf'
            defaultValue={edit.uf}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.uf}
                helperText={errors.uf && "Campo obrigatório"}
                variant="outlined"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o uf"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name='idtipofilial'
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <ComboBox
                value={value}
                data={branchTypes.map(t => {
                  return { ...t, id: String(t.idtipofilial) }
                })}
                change={(v: any) => onChange(v.idtipofilial)}
                label='Tipo Filial'
              />
            )}
          />

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
            <Button
              size='small'
              type="reset"
              fullWidth
              variant="contained"
              color="secondary"
              style={{ margin: '0 10px' }}
              className={classes.submit}
              onClick={() => setDrawerVisible(false)}
            >
              Cancelar
            </Button>
            <Button
              size='small'
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ margin: '0 10px' }}
              className={classes.submit}
              disabled={errors.idcliente || errors.nome || loading}
            >
              {loading ? <CircularProgress size={26} color="inherit" /> : 'Gravar'}
            </Button>
          </div>
        </form>
      </Drawer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default Branch
