import api from "../../services/api"
import { getToken } from "../../util"

interface IReturn {
  error: boolean
  visible: boolean
  message: string
}

const _update = async (idcontaspagarparcela: number, datapagamento: Date): Promise<IReturn> => {
  try {
    await api.put(`debts/parcel/${idcontaspagarparcela}`,
      { pago: true, datapagamento },
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: "Sucesso ao atualizar parcela" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao atualizar parcela" }
  }
}

export default _update
