import api from "../../services/api"
import IBranchTypes from "../../types/IBranchType"
import { getToken } from "../../util"

const _list = async (): Promise<IBranchTypes[]> => {
  try {
    const response = await api.get('branch/type/list', {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

export default _list
