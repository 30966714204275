import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme, TextField, Checkbox } from '@material-ui/core/'
import { Autocomplete } from '@material-ui/lab'
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@material-ui/icons'

interface IProps {
  value: string
  data: any
  change: any
  label: string
}

const ComboBox: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const icon = <CheckBoxOutlineBlankOutlined fontSize="small" />
  const checkedIcon = <CheckBoxOutlined fontSize="small" />

  const [valueSelected, setValueSelected] = useState({})

  useEffect(() => {
    if (props.value) {
      const selected = props.data.filter((d: any) => d.id == props.value)[0]
      console.log(selected)
      setValueSelected(selected)
    }
  }, [props.value])


  return (
    <div className={classes.root} style={{ margin: 5, width: '100%' }}>
      {props.data && props.data.length > 0 ? (
        <Autocomplete
          fullWidth
          value={valueSelected}
          renderOption={(option: any, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.descricao}
            </React.Fragment>
          )}
          options={props.data.map((d: any) => {
            return { ...d, id: String(d.id) }
          })}
          onChange={(d, i) => {
            if (i) {
              props.change(i)
              setValueSelected(i)
            }
          }}
          key={'id'}
          getOptionLabel={(option: any) => option.descricao || ''}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label={props.label} placeholder='Selecione...' />
          )}
        />
      ) : (<small>Carregando...</small>)}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > * + *': {
        marginTop: theme.spacing(3)
      }
    }
  })
)

export default ComboBox
