import api from "../../services/api"
import ICustomer from "../../types/ICustomer"
import { getToken } from "../../util"

const _update = async (customer: ICustomer, juridico: boolean, fornecedor: boolean): Promise<any> => {
  try {
    const response = await api.put(`client/update/${customer.idcliente}`,
      { ...customer, juridico, fornecedor },
      { headers: { 'x-access-token': await getToken() } }
    )
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao atualizar cliente" }
  }
}

export default _update
