import React from 'react'
import { Container, Typography } from '@material-ui/core'
import FadeIn from 'react-fade-in'

const Page404 = (): React.ReactElement => {
  return (
    <Container maxWidth='xl'>
      <FadeIn delay={200} transitionDuration={200}>
        <Typography variant='h5' color='textSecondary'>
          Pagina em desenvolvimento...
        </Typography>
      </FadeIn>
    </Container>
  )
}

export default Page404
