import api from "../../services/api"
import IFiles from "../../types/IFiles"
import { getToken } from "../../util"

interface IRegisterFile {
  error?: boolean
  visible?: boolean
  message?: string
}

const _register = async (file: IFiles): Promise<IRegisterFile> => {
  try {
    const response = await api.post('sale/fileorder/register',
      { ...file },
      { headers: { 'x-access-token': await getToken() } }
    )
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao incluir arquivo" }
  }
}

export default _register
