import api from "../../services/api"

const _delete = async (id: number): Promise<any> => {
  try {
    await api.delete(`product/productgrade/${id}`)
    return { error: false, visible: true, message: "Sucesso ao inativar produto grade" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao inativar produto grade" }
  }
}

export default _delete
