import React, { useState, useEffect, useRef } from 'react'
import handlersProduct from '../../../handlers/Product'
import handlersProductGrid from '../../../handlers/ProductGrid'
import handlersStock from '../../../handlers/Inventory'
import handlersBranch from '../../../handlers/Branch'
import {
  makeStyles, TextField, Theme, Divider, Tooltip, FormGroup,
  CircularProgress, Drawer, Typography, Container, Checkbox,
  Card, Button, IconButton, Switch, CardHeader,
  Breadcrumbs, Badge, List, ListItem, ListItemText,
  AppBar, TextareaAutosize, Tabs, Tab, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core/'
import { Alert } from '@material-ui/lab/'
import {
  AddOutlined, DeleteOutlined, RefreshOutlined,
  EditOutlined, ShoppingCartOutlined, ShoppingCart,
  ListOutlined, AttachFileOutlined, ChatOutlined,
  SearchOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  SyncOutlined,
  ArchiveOutlined,
  CheckOutlined
} from '@material-ui/icons/'
import DataTable from '../../../components/DataTable'
import SnackAlert from '../../../components/Alert'
import FadeIn from 'react-fade-in'
import IStock from '../../../types/IStock'
import { maskReais } from '../../../util'
import Modal from '../../../components/Modal'
import './styles.css'
import IProduct from '../../../types/IProduct'
import IProductGrid from '../../../types/IProductGrid'
import ComboBox from '../../../components/ComboBox'
import IBranch from '../../../types/IBranch'
import { Colors } from '../../../constants/colors'

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const StockReport = (props: any): React.ReactElement => {

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [isVisibleModalAlerta, setIsVisibleModalAlerta] = useState(false)
  const [isVisibleModalAlertaReset, setIsVisibleModalAlertaReset] = useState(false)
  const [loading, setLoading] = useState(false)
  const [branchs, setBranchs] = useState<IBranch[]>([])
  const [stockData, setStockData] = useState<IStock[]>([])
  const [selectedIdProduct, setSelectedIdProduct] = useState('')
  const [selectedIdfilial, setSelectedIdfilial] = useState('')
  const [selectedIdfilialFiltro, setSelectedIdfilialFiltro] = useState('')
  const [selectedIdProductGrid, setSelectedIdProductGrid] = useState('')
  const [products, setProducts] = useState<IProduct[]>([])
  const [productGrid, setProductGrid] = useState<IProductGrid[]>([])
  const [qtd, setQtd] = useState(0)
  const [checkMovimento, setCheckMovimento] = useState(false)
  const [selectedProductStock, setSelectedProductStock] = useState<any>()
  const [selectedStockReset, setSelectedStockReset] = useState<IStock>()

  const [isAdmin, setIsAdmin] = useState(false)

  const [alert, setAlert] = useState({
    visible: false,
    message: '',
    error: false
  })

  const classes = useStyles()

  const getAdminVerify = () => {

    const user = localStorage.getItem('userInfo')
    if (user) {
      const obj_user = JSON.parse(user)
      const { idtipousuario } = obj_user
      setIsAdmin([1, 4].includes(idtipousuario))
    }
  }

  const getStockReport = async (): Promise<void> => {
    setLoading(true)
    const data = await handlersStock._stockList()
    setStockData(data)
    setLoading(false)
  }

  const dataFilter = (arr: IStock[]): IStock[] => {

    let newArr: any = []

    switch (props?.selected?.id) {
      case '02.01':
        newArr = arr.filter((obj) => {
          if (selectedIdfilialFiltro !== "") {
            return obj.idfilial === Number(selectedIdfilialFiltro)
          } else {
            return true
          }
        })
    }

    let qtdTotal = 0
    let custoTotal = 0
    let valorTotal = 0

    for (let i of newArr) {
      const { quantidade, custo, valor } = i
      qtdTotal += parseFloat(quantidade)
      custoTotal += parseFloat(custo)
      valorTotal += parseFloat(valor)
    }

    newArr.push({
      idfilial: 0,
      coraluminio: '-',
      corfibra: '-',
      filial_cidade: '-',
      filial_desc: `TOTAL`,
      filial_uf: '-',
      idcoraluminio: 0,
      idcorfibra: 0,
      idestoque: 0,
      idproduto: 0,
      idprodutograde: 0,
      idtrama: 0,
      produto: '-',
      quantidade: qtdTotal.toFixed(2),
      trama: '-',
      custo: String(custoTotal),
      valor: String(valorTotal)
    })

    return newArr
  }

  const getProducts = async (): Promise<void> => {
    const data = await handlersProduct._list()
    setProducts(data)
  }

  const getProductGrid = async (idproduto: number): Promise<void> => {
    const data = await handlersProductGrid._list(idproduto)
    setProductGrid(data)
  }

  const getBranchs = async (): Promise<void> => {
    const data = await handlersBranch._list()
    setBranchs(data)
  }

  const registerStock = async (): Promise<void> => {
    const user = localStorage.getItem('userInfo')
    if (user) {
      const { idusuario } = JSON.parse(user)
      const newMoviment = {
        idfilial: Number(selectedIdfilial),
        quantidade: qtd,
        idprodutograde: Number(selectedIdProductGrid),
        idtipomovimento: checkMovimento ? 4 : 1,
        idusuario
      }
      const create = await handlersStock._register(newMoviment)
      setAlert(create)
      if (!create.error) {
        setDrawerVisible(false)
        getStockReport()
        setSelectedIdProduct('')
        setSelectedIdfilial('')
        setQtd(0)
        setProductGrid([])
        setSelectedIdProductGrid('')
      }
    }
  }

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({ ...alert, visible: false })
      }, 3000)
    }
  }, [alert.visible])

  useEffect(() => {
    getStockReport()
    getProducts()
    getBranchs()
    getAdminVerify()
  }, [])

  const TABLE = {
    columns:
      [
        {
          name: 'filial',
          cell: (obj: any) => obj.data_inclusao === '-' ? '__________' : `${obj.filial_desc}`,
          button: true
        },
        {
          name: 'Produto',
          cell: (obj: any) => obj.data_inclusao === '-' ? '___________________' : `${obj.produto}`,
        },
        {
          name: 'Trama',
          cell: (obj: any) => obj.data_inclusao === '-' ? '__________' : obj.trama,
          button: true
        },
        {
          name: 'Fibra',
          cell: (obj: any) => obj.data_inclusao === '-' ? '__________' : obj.corfibra,
          button: true
        },
        {
          name: 'Aluminio',
          cell: (obj: any) => obj.data_inclusao === '-' ? '__________' : obj.coraluminio,
          button: true
        },
        {
          name: 'Qtde.',
          cell: (v: any) => v.data_inclusao === '-' ? '__________' : (
            <>
              <span><b>{v.quantidade}</b></span>
              {isAdmin ? (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  size='small'
                  component="span"
                  style={{ marginLeft: 12 }}
                  onClick={async () => {
                    setSelectedProductStock(v)
                    setDrawerVisible(true)
                    setSelectedIdfilial(String(v.idfilial))
                    setSelectedIdProduct(String(v.idproduto))
                    await getProductGrid(Number(v.idproduto))
                    setSelectedIdProductGrid(String(v.idprodutograde))
                    setQtd(0)
                  }}
                >
                  <EditOutlined style={{ fontSize: 17 }} />
                </IconButton>
              ) : null}
            </>
          ),
          right: true,
          // button: true
        },
        {
          name: isAdmin ? 'Custo' : '-',
          cell: (v: any) => v.data_inclusao === '-' ? '__________' : isAdmin ? <b>{maskReais(v.custo)}</b> : '-',
          right: true,
          button: true
        },
        {
          name: isAdmin ? 'Valor' : '-',
          cell: (v: any) => v.data_inclusao === '-' ? '__________' : isAdmin ? <b>{maskReais(v.valor)}</b> : '-',
          right: true,
          button: true
        },
        {
          name: isAdmin ? 'Zerar' : '-',
          cell: (prod: any) => prod?.filial_desc === 'TOTAL' || prod.data_inclusao === '-' ? '______' : isAdmin ? (
            <>
              <IconButton
                color="secondary"
                aria-label="upload picture"
                size='small'
                component="span"
                style={{ marginLeft: 12 }}
                onClick={async () => {
                  setSelectedStockReset(prod)
                  setIsVisibleModalAlerta(true)

                }}
              >
                <DeleteOutlined style={{ fontSize: 17 }} />
              </IconButton>
            </>
          ) : '-',
          right: true,
          button: true
        }
      ],
    data: dataFilter(stockData)
  }

  const defaultProductStock = {
    coraluminio: "",
    corfibra: "",
    custo: "",
    filial_cidade: "",
    filial_desc: "",
    filial_uf: "",
    idcoraluminio: 0,
    idcorfibra: 0,
    idestoque: 0,
    idfilial: 0,
    idproduto: 0,
    idprodutograde: 0,
    idtrama: 0,
    produto: "",
    quantidade: 0,
    trama: "",
    valor: "",
  }


  const resetStock = async (idfilial: number, quantidade: number, idprodutograde: number): Promise<void> => {
    const user = localStorage.getItem('userInfo')
    if (user) {
      const { idusuario } = JSON.parse(user)
      const newMoviment = {
        idfilial,
        quantidade,
        idprodutograde,
        idtipomovimento: 4,
        idusuario
      }
      const create = await handlersStock._register(newMoviment)
      setIsVisibleModalAlerta(false)
      setAlert(create)
      await getStockReport()
    }
  }

  const resetStockFilial = async (idfilial: number): Promise<void> => {
    const res = await handlersStock._reset(idfilial)
    setIsVisibleModalAlertaReset(false)
    setAlert(res)
  }

  return (
    <>

      <Modal
        open={isVisibleModalAlertaReset}
        onClose={() => {
          setIsVisibleModalAlertaReset(false)
        }}
        children={
          <div style={{ width: 558 }}>
            <h3>Atenção</h3>
            <Divider />
            <div style={{ padding: 18, marginTop: 18 }}>
              <h2 style={{ color: Colors.darkblue }}>Realmente deseja zerar o estoque dessa filial? Essa ação não poderá ser desfeita!</h2>
            </div>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 22 }}
              onClick={async () => {
                await resetStockFilial(Number(selectedIdfilialFiltro))
              }}
            >
              <CheckOutlined /> &nbsp; Tenho certeza!
            </Button>
          </div>
        }
      />
      <Modal
        open={isVisibleModalAlerta}
        onClose={() => {
          setIsVisibleModalAlerta(false)
        }}
        children={
          <div style={{ width: 558 }}>
            <h3>Atenção</h3>
            <Divider />
            <div style={{ padding: 18, marginTop: 18 }}>
              <h2 style={{ color: Colors.darkblue }}>Realmente deseja zerar o estoque desse produto? Essa ação não poderá ser desfeita!</h2>
            </div>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 22 }}
              onClick={async () => {
                await resetStock(
                  Number(selectedStockReset?.idfilial),
                  Number(selectedStockReset?.quantidade),
                  Number(selectedStockReset?.idprodutograde),
                )
              }}
            >
              <CheckOutlined /> &nbsp; Tenho certeza!
            </Button>
          </div>
        }
      />

      <SnackAlert
        alert={alert}
        align='center'
        position='top'
      />
      <FadeIn delay={200} transitionDuration={200}>
        <Card style={{ padding: 15, marginTop: 30, borderRadius: 10 }}>
          <h2 style={{ textAlign: 'center' }}>{props?.selected?.descricao}</h2>
          <Divider />
          {loading ? <div style={{ display: 'flex', justifyContent: 'center', padding: 28 }}><CircularProgress /></div> : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>

              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 18 }}>
                <div style={{ width: 328, display: 'flex' }}>
                  <ComboBox
                    value={selectedIdfilialFiltro === '' ? '0' : selectedIdfilialFiltro}
                    data={branchs.map(f => {
                      return { ...f, id: String(f.idfilial) }
                    })}
                    change={(v: any) => setSelectedIdfilialFiltro(v.idfilial)}
                    label='Filtro de filial'
                  />
                  <div style={{ marginTop: 18 }}>
                    <Button color='primary' variant='outlined' onClick={() => setSelectedIdfilialFiltro('')}>
                      X
                    </Button>
                  </div>
                </div>
                <div style={{ marginLeft: 20, marginTop: 18 }}>
                  <Button
                    disabled={!isAdmin}
                    variant="outlined"
                    color="secondary"
                    onClick={() => setIsVisibleModalAlertaReset(true)}
                  >
                    <DeleteOutlined /> &nbsp; Zerar estoque filial
                  </Button>
                  <Button
                    disabled={!isAdmin}
                    variant="outlined"
                    color="primary"
                    style={{ marginLeft: 16 }}
                    onClick={() => {
                      setSelectedProductStock(defaultProductStock)
                      setSelectedIdfilial('')
                      setSelectedIdProduct('')
                      setSelectedIdProductGrid('')
                      setProductGrid([])
                      setQtd(0)
                      setDrawerVisible(true)
                    }}
                  >
                    <ArchiveOutlined /> &nbsp; Movimentação
                  </Button>
                </div>
              </div>
              <div style={{ marginTop: 18 }} />


              <DataTable
                columns={TABLE.columns}
                data={TABLE.data}
              />
            </div>
          )}
        </Card>
      </FadeIn>


      <Drawer
        open={drawerVisible}
        anchor='right'
        onClose={() => setDrawerVisible(false)}
      >
        <div style={{ width: 728, padding: 18 }}>
          <Typography variant='h5'>
            Movimentação de Estoque
          </Typography>
          <Divider style={{ margin: '5px 0' }} />
          <div style={{ padding: 18 }}>
            <ComboBox
              value={String(selectedIdfilial)}
              data={branchs.map(f => {
                return { ...f, id: String(f.idfilial) }
              })}
              change={(v: any) => setSelectedIdfilial(v.idfilial)}
              label='Selecione a filial'
            />
            <div style={{ marginTop: 12 }} />
            <ComboBox
              value={String(selectedIdProduct)}
              data={products.map(p => {
                return { ...p, id: String(p.idproduto) }
              })}
              change={(v: any) => {
                if (v?.idproduto) {
                  setSelectedIdProduct(v.idproduto)
                  getProductGrid(Number(v.idproduto))
                }
              }}
              label='Selecione o produto'
            />
            <div style={{ marginTop: 12 }} />
            {productGrid && productGrid.length > 0 && (<>
              <ComboBox
                value={selectedIdProductGrid}
                data={productGrid.map(p => {
                  return { ...p, id: String(p.idprodutograde), descricao: `TRAMA: ${p?.trama_desc?.toUpperCase()} - FIBRA: ${p?.corfibra_desc?.toUpperCase()} - ALUMÍNIO: ${p?.coraluminio_desc?.toUpperCase()}` }
                })}
                change={(v: any) => {
                  setSelectedIdProductGrid(v.idprodutograde)
                  const stockFilter = TABLE.data.filter(i => i.idfilial === Number(selectedIdfilial) && i.idprodutograde === Number(v.idprodutograde))
                  if (stockFilter && stockFilter.length > 0) {
                    const infoStock = stockFilter[0]
                    setSelectedProductStock(infoStock)
                  } else {
                    // console.log(v)
                    setSelectedProductStock(defaultProductStock)
                  }
                }}
                label='Selecione a grade'
              />
              <div style={{ marginTop: 12 }} />
              <TextField
                size='small'
                variant="standard"
                margin="normal"
                type="number"
                value={qtd}
                fullWidth
                label="Digite a quantidade"
                onChange={(e) => setQtd(Number(e.target.value))}
              />
              {selectedProductStock && (
                <div>
                  <p>
                    Saldo atual:
                    <b> {parseFloat(selectedProductStock?.quantidade)}</b>
                  </p>
                  <p>
                    Novo saldo: {' '}
                    <b>
                      {
                        checkMovimento
                          ? parseFloat(selectedProductStock.quantidade) - parseFloat(String(qtd))
                          : parseFloat(selectedProductStock.quantidade) + parseFloat(String(qtd))
                      }
                    </b>
                  </p>
                </div>
              )}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={checkMovimento} onChange={() => setCheckMovimento(!checkMovimento)} />
                  }
                  label={checkMovimento ? 'Saída' : 'Entrada'}
                />
              </FormGroup>
            </>)}
          </div>

          <div className="drawer-bottom">
            <Button
              size='small'
              type="reset"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={() => setDrawerVisible(false)}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => registerStock()}
              size='small'
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={selectedProductStock ? checkMovimento ? (selectedProductStock.quantidade - qtd) < 0 : (selectedProductStock.quantidade + qtd) < 0 : false}
            >
              Gravar
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default StockReport
