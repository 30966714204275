import api from "../../services/api"
import ISituation from "../../types/ISituations"
import { getToken } from "../../util"

const _listOrderSituation = async (): Promise<ISituation[]> => {
  try {
    const response = await api.get('sale/ordersituation/list', {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

export default _listOrderSituation
