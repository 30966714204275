import React, { useState, useEffect } from 'react'
import handlersProduct from '../../handlers/Product'
import handlersProductGrid from '../../handlers/ProductGrid'
import handlersProductPrice from '../../handlers/ProductPrice'
import handlersTrama from '../../handlers/Trama'
import handlersColorFibra from '../../handlers/ColorFibra'
import handlersColorAluminio from '../../handlers/ColorAluminio'
import {
  makeStyles, TextField, Theme, Divider, Tooltip,
  CircularProgress, Drawer, Typography, Container,
  Card, Button, IconButton, Switch, CardHeader,
  Paper, Tabs, Tab, Box, AppBar, FormControl,
  FormControlLabel, Checkbox
} from '@material-ui/core/'
import { Alert, Skeleton } from '@material-ui/lab/'
import { AddOutlined, DeleteOutlined, RefreshOutlined, EditOutlined, CloseOutlined, SyncOutlined } from '@material-ui/icons/'
import { useForm, Controller, FormProvider } from "react-hook-form"
import DataTable from '../../components/DataTable'
import SnackAlert from '../../components/Alert'
import FadeIn from 'react-fade-in'
import api from '../../services/api'
import IProduct from '../../types/IProduct'
import ComboBox from '../../components/ComboBox'
import { getToken } from '../../util'
import InputMoney from '../../components/InputMoney'
import semFotoImg from '../../assets/produto-sem-imagem.png'
import './styles.css'
import ITrama from '../../types/ITrama'
import IColorFibra from '../../types/IColorFibra'
import IColorAluminio from '../../types/IColorAluminio'
import { Colors } from '../../constants/colors'
import IProductGrid from '../../types/IProductGrid'
import IProductPrice from '../../types/IProductPrice'
import Modal from '../../components/Modal'

const blankForm = {
  descricao: "",
  ncm: "",
  altura: "",
  largura: "",
  profundidade: "",
  peso: "",
  personalizar: false,
  ativo: false
}
const blankFormProductGrid = {
  idproduto: 0,
  idtrama: 0,
  idcorfibra: 0,
  idcoraluminio: 0
}
const blankFormProductPrice = {
  idprodutovalor: 0,
  custo: 0,
  valor: 0,
  idproduto: 0,
  idtrama: 0,
  idcoraluminio: 0,
  valorproducao: 0
}

const Product = (): React.ReactElement => {
  const [personalizar, setPersonalizar] = useState(false)
  const [isVisibleModalCorFibra, setIsVisibleModalCorFibra] = useState(false)
  const [isVisibleModalCorAluminio, setIsVisibleModalCorAluminio] = useState(false)
  const [isVisibleModalTrama, setIsVisibleModalTrama] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingProductGrid, setLoadingProductGrid] = useState(false)
  const [loadingProductPrice, setLoadingProductPrice] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [tramas, setTramas] = useState<ITrama[]>([])
  const [products, setProducts] = useState<IProduct[]>()
  const [productGrid, setProductGrid] = useState<IProductGrid[]>()
  const [colorFibra, setColorFibra] = useState<IColorFibra[]>([])
  const [colorAluminio, setColorAluminio] = useState<IColorAluminio[]>([])
  const [productPrice, setProductPrice] = useState<IProductPrice[]>()
  const [editProductPrice, setEditProductPrice] = useState<IProductPrice>(blankFormProductPrice)
  const [editProductGrid, setEditProductGrid] = useState<IProductGrid>(blankFormProductGrid)
  const [edit, setEdit] = useState<IProduct>(blankForm)
  const [alert, setAlert] = useState({
    visible: false,
    message: '',
    error: false
  })
  const [selectedTab, setSelectedTab] = useState(0)
  const [idTrama, setIdTrama] = useState(0)
  const [nomeTrama, setNomeTrama] = useState("")
  const [idCorAluminio, setIdCorAluminio] = useState(0)
  const [nomeCorAluminio, setNomeCorAluminio] = useState("")
  const [idCorFibra, setIdCorFibra] = useState(0)
  const [nomeCorFibra, setNomeCorFibra] = useState("")
  const { handleSubmit, errors, control, setValue } = useForm()
  const [selectedIdprodutovalor, setSelectedIdprodutovalor] = useState(0)
  const [formPrecoCusto, setFormPrecoCusto] = useState({
    idtrama: 0,
    custo: 0,
    valor: 0,
    valorproducao: 0,
  })
  const [selectedIdprodutograde, setSelectedIdprodutograde] = useState(0)
  const [formProdutoGrade, setFormProdutoGrade] = useState({
    idtrama: 0,
    idcoraluminio: 0,
    idcorfibra: 0,
  })
  const classes = useStyles()

  const getProducts = async (): Promise<void> => {
    const data = await handlersProduct._list()
    setProducts(data)
  }

  const getTrama = async (): Promise<void> => {
    const data = await handlersTrama._list()
    setTramas(data)
  }

  const getColorAluminio = async (): Promise<void> => {
    const data = await handlersColorAluminio._list()
    setColorAluminio(data)
  }

  const getColorFibra = async (): Promise<void> => {
    const data = await handlersColorFibra._list()
    setColorFibra(data)
  }

  const getProductGrid = async (): Promise<void> => {
    if (edit.idproduto) {
      setLoadingProductGrid(true)
      const data = await handlersProductGrid._list(edit.idproduto)
      setProductGrid(data)
      setLoadingProductGrid(false)
    }
  }

  const getProductPrice = async (): Promise<void> => {
    if (edit.idproduto) {
      setLoadingProductGrid(true)
      const data = await handlersProductPrice._list(edit.idproduto)
      setProductPrice(data)
      setLoadingProductGrid(false)
    }
  }
  const handleSyncTiny = async (idprodutograde: number , tiny: boolean): Promise<void> => {
    if (idprodutograde) {
      setLoadingProductGrid(true)
      const data = await handlersProduct._syncTiny(idprodutograde, tiny)
      setAlert(data)
      setLoadingProductGrid(false)
    }
  }

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue)
  }

  const handleEdit = (obj: IProduct) => {
    setProductPrice([])
    setProductGrid([])
    setSelectedIdprodutovalor(0)
    setFormPrecoCusto({
      idtrama: 0,
      custo: 0,
      valor: 0,
      valorproducao: 0
    })
    setSelectedIdprodutograde(0)
    setFormProdutoGrade({
      idtrama: 0,
      idcoraluminio: 0,
      idcorfibra: 0,
    })
    setPersonalizar(obj.personalizar)
    // setProductGrid([])
    setEdit(obj)
    setDrawerVisible(true)
  }

  const handleCreateProduct = async (product: IProduct): Promise<void> => {
    setLoading(true)
    const register = edit.idproduto ?
      await handlersProduct._update({ ...product, idproduto: edit.idproduto, personalizar })
      :
      await handlersProduct._register({ ...product, personalizar })

    setAlert(register)
    setDrawerVisible(false)
    setLoading(false)
    getProducts()
    setEdit(blankForm)
  }

  const handleCreateProductGrid = async (): Promise<void> => {
    if (edit.idproduto) {
      setLoadingProductGrid(true)

      const register = selectedIdprodutograde > 0 ?
        await handlersProductGrid._update({ ...formProdutoGrade, idprodutograde: selectedIdprodutograde, idproduto: edit.idproduto })
        :
        await handlersProductGrid._register({ ...formProdutoGrade, idproduto: edit.idproduto })

      setAlert(register)
      setEditProductGrid(blankFormProductGrid)
      setLoadingProductGrid(false)
      getProductGrid()
      setSelectedTab(0)
      setSelectedTab(1)
      setSelectedIdprodutograde(0)
      setFormProdutoGrade({
        idtrama: 0,
        idcoraluminio: 0,
        idcorfibra: 0,
      })
    }
  }

  const handleCreateProductPrice = async (): Promise<void> => {
    if (edit.idproduto) {
      setLoadingProductPrice(true)
      const register = selectedIdprodutovalor > 0 ?
        await handlersProductPrice._update({ ...formPrecoCusto, idprodutovalor: selectedIdprodutovalor, idproduto: edit.idproduto })
        :
        await handlersProductPrice._register({ ...formPrecoCusto, idproduto: edit.idproduto })

      setAlert(register)
      setEditProductPrice(blankFormProductPrice)
      setFormPrecoCusto({
        custo: 0,
        valor: 0,
        idtrama: 0,
        valorproducao: 0
      })
      setLoadingProductPrice(false)
      getProductPrice()
      setSelectedTab(1)
      setSelectedTab(2)
    }
  }

  const handleCreateTrama = async (trama: string): Promise<void> => {
    const register = idTrama > 0 ? await handlersTrama._update({ descricao: trama, idtrama: idTrama }) : await handlersTrama._register({ descricao: trama })
    setAlert(register)
    getTrama()
    setNomeTrama("")
    setIdTrama(0)
  }

  const handleCreateCorFibra = async (corfibra: string): Promise<void> => {
    const register = idCorFibra > 0 ? await handlersColorFibra._update({ descricao: corfibra, idcorfibra: idCorFibra }) : await handlersColorFibra._register({ descricao: corfibra })
    setAlert(register)
    getColorFibra()
    setNomeCorFibra("")
    setIdCorFibra(0)
  }

  const handleCreateCorAluminio = async (corAluminio: string): Promise<void> => {
    const register = idCorAluminio > 0 ? await handlersColorAluminio._update({ descricao: corAluminio, idcoraluminio: idCorAluminio }) : await handlersColorAluminio._register({ descricao: corAluminio })
    setAlert(register)
    getColorAluminio()
    setNomeCorAluminio("")
    setIdCorAluminio(0)
  }

  const handleDelete = async (idproduto: number, ativo: boolean): Promise<void> => {
    const deleteProduct = await handlersProduct._delete(idproduto, ativo)
    setAlert(deleteProduct)
    await getProducts()
  }



  useEffect(() => {
    getProductGrid()
    getProductPrice()
  }, [editProductGrid, edit, editProductPrice])

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({ ...alert, visible: false })
      }, 3000)
    }
  }, [alert.visible])

  useEffect(() => {
    getProducts()
    getTrama()
    getColorAluminio()
    getColorFibra()
  }, [])

  return (
    <>
      <SnackAlert
        alert={alert}
        align='center'
        position='top'
      />
      <Modal
        open={isVisibleModalTrama}
        onClose={() => setIsVisibleModalTrama(false)}
        children={
          <>
            <h3>Cadastro de Tramas</h3>
            <Divider />
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <TextField
                size='small'
                variant="standard"
                margin="normal"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNomeTrama(e.target.value)}
                value={nomeTrama}
                fullWidth
                label="Digite o nome da trama"
              />
              <Tooltip title='Salvar'>
                <Button
                  size='small'
                  variant='text'
                  color='inherit'
                  onClick={() => {
                    if (!nomeTrama || nomeTrama.trim() === "") return window.alert("Nome de trama inválido")
                    handleCreateTrama(nomeTrama)
                  }}
                  style={{ borderRadius: '50%', height: 60 }}
                >
                  <AddOutlined style={{ fontSize: 22, color: Colors.lightblue }} />
                </Button>
              </Tooltip>
            </div>

            <div style={{ marginTop: 48 }} />

            <DataTable
              columns={[
                {
                  name: 'id',
                  selector: 'idtrama',
                  button: true
                },
                {
                  name: 'descricao',
                  selector: 'descricao'
                },
                {
                  button: true,
                  cell: (obj: ITrama) => (
                    <IconButton
                      aria-label="editar"
                      onClick={() => {
                        if (obj.idtrama) {
                          setIdTrama(obj.idtrama)
                          setNomeTrama(obj.descricao)
                        }
                      }}
                    >
                      <EditOutlined fontSize="small" />
                    </IconButton>
                  )
                },
                // {
                //   button: true,
                //   cell: (obj: ITrama) => (
                //     <IconButton aria-label="excluir" onClick={() => window.alert("Não foi possível excluir essa trama")}>
                //       <DeleteOutlined fontSize="small" />
                //     </IconButton>
                //   )
                // }
              ]}
              data={tramas}
            />
          </>
        }
      />
      <Modal
        open={isVisibleModalCorAluminio}
        onClose={() => setIsVisibleModalCorAluminio(false)}
        children={
          <>
            <h3>Cadastro de cor aluminio</h3>
            <Divider />
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <TextField
                size='small'
                variant="standard"
                margin="normal"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNomeCorAluminio(e.target.value)}
                value={nomeCorAluminio}
                fullWidth
                label="Digite o nome da Cor Aluminio"
              />
              <Tooltip title='Salvar'>
                <Button
                  size='small'
                  variant='text'
                  color='inherit'
                  onClick={() => {
                    if (!nomeCorAluminio || nomeCorAluminio.trim() === "") return window.alert("Nome da Cor Aluminio inválido")
                    handleCreateCorAluminio(nomeCorAluminio)
                  }}
                  style={{ borderRadius: '50%', height: 60 }}
                >
                  <AddOutlined style={{ fontSize: 22, color: Colors.lightblue }} />
                </Button>
              </Tooltip>
            </div>

            <div style={{ marginTop: 48 }} />

            <DataTable
              columns={[
                {
                  name: 'id',
                  selector: 'id',
                  button: true
                },
                {
                  name: 'descricao',
                  selector: 'descricao'
                },
                {
                  button: true,
                  cell: (obj: IColorAluminio) => (
                    <IconButton
                      aria-label="editar"
                      onClick={() => {
                        if (obj.idcoraluminio) {
                          setIdCorAluminio(obj.idcoraluminio)
                          setNomeCorAluminio(obj.descricao)
                        }
                      }}
                    >
                      <EditOutlined fontSize="small" />
                    </IconButton>
                  )
                },
                // {
                //   button: true,
                //   cell: (obj: IColorAluminio) => (
                //     <IconButton aria-label="excluir" onClick={() => window.alert("Não foi possível excluir essa cor aluminio")}>
                //       <DeleteOutlined fontSize="small" />
                //     </IconButton>
                //   )
                // }
              ]}
              data={colorAluminio}
            />
          </>
        }
      />
      <Modal
        open={isVisibleModalCorFibra}
        onClose={() => setIsVisibleModalCorFibra(false)}
        children={
          <>
            <h3>Cadastro cor fibra</h3>
            <Divider />
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <TextField
                size='small'
                variant="standard"
                margin="normal"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNomeCorFibra(e.target.value)}
                value={nomeCorFibra}
                fullWidth
                label="Digite o nome da Cor Fibra"
              />
              <Tooltip title='Salvar'>
                <Button
                  size='small'
                  variant='text'
                  color='inherit'
                  onClick={() => {
                    if (!nomeCorFibra || nomeCorFibra.trim() === "") return window.alert("Nome da Cor Fibra inválido")
                    handleCreateCorFibra(nomeCorFibra)
                  }}
                  style={{ borderRadius: '50%', height: 60 }}
                >
                  <AddOutlined style={{ fontSize: 22, color: Colors.lightblue }} />
                </Button>
              </Tooltip>
            </div>

            <div style={{ marginTop: 48 }} />

            <DataTable
              columns={[
                {
                  name: 'id',
                  selector: 'id',
                  button: true
                },
                {
                  name: 'descricao',
                  selector: 'descricao'
                },
                {
                  button: true,
                  cell: (obj: IColorFibra) => (
                    <IconButton
                      aria-label="editar"
                      onClick={() => {
                        if (obj.idcorfibra) {
                          setIdCorFibra(obj.idcorfibra)
                          setNomeCorFibra(obj.descricao)
                        }
                      }}
                    >
                      <EditOutlined fontSize="small" />
                    </IconButton>
                  )
                },
                // {
                //   button: true,
                //   cell: (obj: IColorFibra) => (
                //     <IconButton aria-label="excluir" onClick={() => window.alert("Não foi possível excluir essa cor fibra")}>
                //       <DeleteOutlined fontSize="small" />
                //     </IconButton>
                //   )
                // }
              ]}
              data={colorFibra}
            />
          </>
        }
      />

      <Container maxWidth='xl'>
        <FadeIn delay={200} transitionDuration={200}>
          <Typography variant='h5' color='textSecondary'>
            Produtos
          </Typography>
          <Card style={{ padding: 15, marginTop: 30, borderRadius: 10 }}>
            <CardHeader
              action={
                <>
                  <Tooltip title='Atualizar'>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={async () => await getProducts()}
                      style={{ borderRadius: '50%', height: 60 }}
                    >
                      <RefreshOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title='Cadastrar novo produto'>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={() => {
                        setDrawerVisible(true)
                        setEdit(blankForm)
                        setSelectedTab(0)
                      }}
                      style={{ borderRadius: '50%', height: 60 }}
                    >
                      <AddOutlined />
                    </Button>
                  </Tooltip>
                </>
              }
              title='Lista de produtos'
            />
            <DataTable
              search
              columns={[
                // {
                //   name: 'Foto',
                //   cell: (obj: any) => <img src={semFotoImg} alt='foto' width={60} height={60} />,
                //   button: true
                // },
                {
                  name: 'ID',
                  selector: 'idproduto',
                  sortable: true,
                  button: true
                },
                {
                  name: 'NCM',
                  selector: 'ncm',
                  sortable: true,
                  button: true
                },
                {
                  name: 'SOB-MEDIDA',
                  cell: (obj: IProduct) => obj.personalizar ? 'Sim' : 'Não',
                  sortable: true,
                  button: true
                },
                {
                  name: 'A',
                  selector: 'altura',
                  sortable: true,
                  button: true
                },
                {
                  name: 'L',
                  selector: 'largura',
                  sortable: true,
                  button: true
                },
                {
                  name: 'P',
                  selector: 'profundidade',
                  sortable: true,
                  button: true
                },
                {
                  name: 'PESO',
                  selector: 'peso',
                  sortable: true,
                  button: true
                },
                {
                  name: 'DESCRIÇÃO',
                  cell: (obj: IProduct) => obj.descricao.toUpperCase()
                },
                {
                  name: 'Status',
                  selector: 'ativo',
                  button: true, 
                  cell: (obj: IProduct) => obj.ativo ? <Alert severity="success"></Alert> : <Alert severity="error"></Alert>
                },
                {
                  button: true,
                  cell: (obj: IProduct) => (
                    <IconButton aria-label="editar" onClick={() => handleEdit(obj)}>
                      <EditOutlined fontSize="small" />
                    </IconButton>
                  )
                }, 
                {
                  button: true,
                  cell: (obj: any) => <IconButton aria-label="excluir" onClick={async () => await handleDelete(obj.idproduto, obj.ativo)}><DeleteOutlined fontSize="small" /></IconButton>
                }
              ]}
              data={products && products.map(p => {
                return {
                  ...p, nome: p.descricao.toUpperCase()
                }
              })}
            />
          </Card>
        </FadeIn>
      </Container>
      <Drawer
        open={drawerVisible}
        anchor='right'
        onClose={() => setDrawerVisible(false)}
      >
        <div style={{ padding: 18, width: 700 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h5'>
              {edit.idproduto ? 'Editando produto' : "Cadastro de produto"}
            </Typography>
            <CloseOutlined onClick={() => setDrawerVisible(false)} style={{ cursor: 'pointer' }} />
          </div>
          <small>{edit.descricao}</small>
          <Divider style={{ marginTop: 8 }} />
          <div className={classes.root} style={{ paddingTop: 8 }}>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="INFORMAÇÕES DO PRODUTO" {...a11yProps(0)} />
              <Tab disabled={edit.idproduto ? false : true} label={edit.idproduto ? "PRODUTO GRADE" : ""} {...a11yProps(1)} />
              <Tab disabled={edit.idproduto ? false : true} label={edit.idproduto ? "PREÇO CUSTO" : ""} {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={selectedTab} index={0} >
              <FadeIn delay={200} transitionDuration={200}>
                <form
                  className={classes.form}
                  autoComplete="off"
                  onSubmit={handleSubmit(handleCreateProduct)}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                    <TextField
                      size='small'
                      variant="standard"
                      margin="normal"
                      value={edit?.idproduto}
                      disabled
                      fullWidth
                      label="ID"
                    />
                    <Controller
                      name='descricao'
                      defaultValue={edit.descricao}
                      control={control}
                      rules={{ required: true }}
                      render={({ onChange, onBlur, value }) => (
                        <TextField
                          size='small'
                          error={errors.descricao}
                          helperText={errors.descricao && "Campo obrigatório"}
                          variant="standard"
                          margin="normal"
                          value={value}
                          fullWidth
                          label="Descrição"
                          placeholder="Digite uma descrição"
                          onChange={(e) => onChange(e.target.value.toUpperCase())}
                        />
                      )}
                    />
                    <Controller
                      name='ncm'
                      defaultValue={edit.ncm}
                      control={control}
                      rules={{ required: true }}
                      render={({ onChange, onBlur, value }) => (
                        <TextField
                          type="number"
                          size='small'
                          error={errors.ncm}
                          helperText={errors.ncm && "Campo obrigatório"}
                          variant="standard"
                          margin="normal"
                          value={value}
                          fullWidth
                          label="Código NCM"
                          onChange={(e) => onChange(e.target.value)}
                        />
                      )}
                    />
                    <Controller
                      name='altura'
                      defaultValue={edit.altura}
                      // rules={{ required: true }}
                      control={control}
                      render={({ onChange, onBlur, value }) => (
                        <InputMoney
                          value={value}
                          onChange={(v: any) => onChange(v)}
                          label='Altura  ( m )'
                        />
                      )}
                    />
                    <Controller
                      name='largura'
                      defaultValue={edit.largura}
                      // rules={{ required: true }}
                      control={control}
                      render={({ onChange, onBlur, value }) => (
                        <InputMoney
                          value={value}
                          onChange={(v: any) => onChange(v)}
                          label='Largura  ( m )'
                        />
                      )}
                    />
                    <Controller
                      name='profundidade'
                      defaultValue={edit.profundidade}
                      // rules={{ required: true }}
                      control={control}
                      render={({ onChange, onBlur, value }) => (
                        <InputMoney
                          value={value}
                          onChange={(v: any) => onChange(v)}
                          label='Profundidade  ( m )'
                        />
                      )}
                    />
                    <Controller
                      name='peso'
                      defaultValue={edit.peso}
                      // rules={{ required: true }}
                      control={control}
                      render={({ onChange, onBlur, value }) => (
                        <InputMoney
                          value={value}
                          onChange={(v: any) => onChange(v)}
                          label='Peso  ( Kg )'
                        />
                      )}
                    />

                    <div style={{ width: 168 }}>
                      <FormControl component="fieldset" >
                        <FormControlLabel
                          control={<Checkbox checked={personalizar} onChange={() => setPersonalizar(!personalizar)} name="gilad" />}
                          label="Sob-medida"
                        />
                      </FormControl>
                    </div>

                    <Button
                      size='small'
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={loading}
                      style={{ width: 228 }}
                    >
                      {loading ? <CircularProgress size={26} color="inherit" /> : 'Gravar'}
                    </Button>
                  </div>
                </form>
              </FadeIn>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <FadeIn delay={200} transitionDuration={200}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="form-row">
                    <ComboBox
                      value={String(formProdutoGrade.idtrama)}
                      data={tramas.map(t => {
                        return { ...t, id: String(t.idtrama) }
                      })}
                      change={(value: any) => setFormProdutoGrade({ ...formProdutoGrade, idtrama: Number(value.id) })}
                      label='Selecione a trama'
                    />
                    <Tooltip title='Adicionar nova trama'>
                      <Button
                        size='small'
                        variant='text'
                        color='inherit'
                        onClick={() => setIsVisibleModalTrama(true)}
                        style={{ borderRadius: '50%', height: 60 }}
                      >
                        <AddOutlined style={{ fontSize: 22, color: Colors.lightblue }} />
                      </Button>
                    </Tooltip>
                  </div>
                  <div className="form-row">
                    <ComboBox
                      value={String(formProdutoGrade.idcorfibra)}
                      data={colorFibra.map(c => {
                        return { ...c, id: String(c.idcorfibra) }
                      })}
                      change={(value: any) => setFormProdutoGrade({ ...formProdutoGrade, idcorfibra: Number(value.id) })}
                      label='Selecione a cor fibra'
                    />
                    <Tooltip title='Adicionar nova cor fibra'>
                      <Button
                        size='small'
                        variant='text'
                        color='inherit'
                        onClick={() => setIsVisibleModalCorFibra(true)}
                        style={{ borderRadius: '50%', height: 60 }}
                      >
                        <AddOutlined style={{ fontSize: 22, color: Colors.lightblue }} />
                      </Button>
                    </Tooltip>
                  </div>
                  <div className="form-row">
                    <ComboBox
                      value={String(formProdutoGrade.idcoraluminio)}
                      data={colorAluminio.map((c: any) => {
                        return { ...c, id: String(c.idcoraluminio) }
                      })}
                      change={(value: any) => setFormProdutoGrade({ ...formProdutoGrade, idcoraluminio: Number(value.id) })}
                      label='Selecione a cor aluminio'
                    />
                    <Tooltip title='Adicionar nova cor aluminio'>
                      <Button
                        size='small'
                        variant='text'
                        color='inherit'
                        onClick={() => setIsVisibleModalCorAluminio(true)}
                        style={{ borderRadius: '50%', height: 60 }}
                      >
                        <AddOutlined style={{ fontSize: 22, color: Colors.lightblue }} />
                      </Button>
                    </Tooltip>
                  </div>
                  <div className="form-row">
                    <Button
                      size='small'
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={async () => await handleCreateProductGrid()}
                      disabled={loadingProductGrid}
                      style={{ width: 128 }}
                    >
                      {loadingProductGrid ? <CircularProgress size={26} color="inherit" /> : 'Gravar'}
                    </Button>
                  </div>
                </div>
                <Divider />
                <DataTable
                  search={false}
                  columns={[
                    {
                      name: 'TRAMA',
                      selector: 'trama_desc'
                    },
                    {
                      name: 'FIBRA',
                      selector: 'corfibra_desc'
                    },
                    {
                      name: 'ALUMÍNIO',
                      selector: 'coraluminio_desc'
                    },
                    {
                      name: 'Editar',
                      button: true,
                      cell: (obj: IProductGrid) => (
                        <IconButton aria-label="editar" onClick={() => {
                          // window.alert(JSON.stringify(obj))
                          setEditProductGrid(obj)
                          setFormProdutoGrade({ ...obj })
                          if (obj.idprodutograde) {
                            setSelectedIdprodutograde(obj.idprodutograde)
                          }
                        }}>
                          <EditOutlined fontSize="small" />
                        </IconButton>
                      )
                    },
                    {
                      name: 'Excluir',
                      button: true,
                      cell: (obj: IProductGrid) => (
                        <>
                          <Switch
                            color='default'
                            checked={obj.ativo}
                            onChange={async e => {
                              const update = await handlersProductGrid._update({ ...obj, ativo: !obj.ativo })
                              setAlert(update)
                              getProductGrid()
                            }}
                          />
                          <small>{obj.ativo ? 'Ativo' : 'Inativo'}</small>
                        </>
                      )
                    },
                    {

                      name: 'Integrar tiny',
                      cell: (obj: any) => {
                        return (
                          <>
                            <IconButton onClick={() => handleSyncTiny(obj.idprodutograde , obj.tiny)}>
                              <SyncOutlined />
                            </IconButton>
                          </>
                        )
                      }
                    }
                  ]}
                  data={productGrid?.sort((a, b) => a.idprodutograde && b.idprodutograde ? a.idprodutograde - b.idprodutograde : 0)}
                  loading={loadingProductGrid}
                />
              </FadeIn>
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <FadeIn delay={200} transitionDuration={200}>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="form-row">
                    <ComboBox
                      value={String(formPrecoCusto.idtrama)}
                      data={tramas.map(t => {
                        return { ...t, id: String(t.idtrama) }
                      })}
                      change={(value: any) => setFormPrecoCusto({ ...formPrecoCusto, idtrama: Number(value.id) })}
                      label='Selecione a trama'
                    />
                    <Tooltip title='Adicionar nova trama'>
                      <Button
                        size='small'
                        variant='text'
                        color='inherit'
                        onClick={() => setIsVisibleModalTrama(true)}
                        style={{ borderRadius: '50%', height: 60 }}
                      >
                        <AddOutlined style={{ fontSize: 22, color: Colors.lightblue }} />
                      </Button>
                    </Tooltip>
                  </div>
                  {/* <div className="form-row">
                    <ComboBox
                      value={String(formPrecoCusto.idcoraluminio)}
                      data={colorAluminio.map(c => {
                        return { ...c, id: String(c.idcoraluminio) }
                      })}
                      change={(value: any) => setFormPrecoCusto({ ...formPrecoCusto, idcoraluminio: value.id })}
                      label='Selecione a cor aluminio'
                    />
                    <Tooltip title='Adicionar nova cor aluminio'>
                      <Button
                        size='small'
                        variant='text'
                        color='inherit'
                        onClick={() => setIsVisibleModalCorAluminio(true)}
                        style={{ borderRadius: '50%', height: 60 }}
                      >
                        <AddOutlined style={{ fontSize: 22, color: Colors.lightblue }} />
                      </Button>
                    </Tooltip>
                  </div> */}
                  <div className="form-row" style={{ marginRight: 28 }}>
                    <div style={{ width: '30%' }}>
                      <InputMoney
                        value={formPrecoCusto.custo}
                        onChange={(value: any) => setFormPrecoCusto({ ...formPrecoCusto, custo: value })}
                        label='Custo'
                      />
                    </div>
                    <div style={{ width: '30%' }}>
                      <InputMoney
                        value={formPrecoCusto.valor}
                        onChange={(value: any) => setFormPrecoCusto({ ...formPrecoCusto, valor: value })}
                        label='Preço'
                      />
                    </div>
                    <div style={{ width: '30%' }}>
                      <InputMoney
                        value={formPrecoCusto.valorproducao}
                        onChange={(value: any) => setFormPrecoCusto({ ...formPrecoCusto, valorproducao: value })}
                        label='Valor produção'
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <Button
                      size='small'
                      onClick={async () => await handleCreateProductPrice()}
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={loadingProductPrice}
                      style={{ width: 128 }}
                    >
                      {loadingProductPrice ? <CircularProgress size={26} color="inherit" /> : 'Gravar'}
                    </Button>
                  </div>
                </div>

                {/* {JSON.stringify(editProductPrice)} */}
                <Divider />
                <DataTable
                  search={false}
                  columns={[
                    {
                      name: 'TRAMA',
                      selector: 'trama_desc'
                    },
                    {
                      name: 'CUSTO',
                      selector: 'custo'
                    },
                    {
                      name: 'PREÇO',
                      selector: 'valor'
                    },
                    {
                      name: 'VAL. PRODUÇÃO',
                      selector: 'valorproducao'
                    },
                    {
                      button: true,
                      cell: (obj: IProductPrice) => (
                        <IconButton aria-label="editar" onClick={() => {
                          // window.alert(JSON.stringify(obj))
                          setEditProductPrice(obj)
                          setFormPrecoCusto({ ...obj })
                          if (obj.idprodutovalor) {
                            setSelectedIdprodutovalor(obj.idprodutovalor)
                          }
                        }}>
                          <EditOutlined fontSize="small" />
                        </IconButton>
                      )
                    }
                  ]}
                  data={productPrice}
                  loading={loadingProductPrice}
                />
              </FadeIn>
            </TabPanel>
          </div>
        </div>
      </Drawer>
    </>
  )
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%' // Fix IE 11 issue.
    // padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}))

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export default Product
