import React from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

function BarcodeScanner({ onChange, value }) {
  return (
    <>
      <BarcodeScannerComponent
        width={'100%'}
        height={450}
        onUpdate={(err, result) => {
          if (result) onChange(result.text);
          else onChange("");
        }}
      />
      <h3>{value}</h3>
    </>
  );
}

export default BarcodeScanner;