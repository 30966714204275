import api from "../../services/api"
import IBranch from "../../types/IBranch"
import { getToken } from "../../util"

const _update = async (branch: IBranch): Promise<any> => {
  try {
    const response = await api.put(`branch/update/${branch.idfilial}`, { ...branch }, { headers: { 'x-access-token': await getToken() } })
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao atualizar filial" }
  }
}

export default _update
