import api from "../../services/api"
import ISalesReport from "../../types/ISalesReport"
import { getToken } from "../../util"

const _list = async (body: any): Promise<ISalesReport[]> => {
  try {
    const response = await api.post(
      'sale/list',
      {...body},
      {
        headers: {
          'x-access-token': await getToken()
        }
      }
    )
    return response.data
  } catch (err) {
    return []
  }
}

export default _list
