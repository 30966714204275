import api from "../../services/api"
import IProductPrice from "../../types/IProductPrice"
import { getToken } from "../../util"

const _register = async (productPrice: IProductPrice): Promise<any> => {
  try {
    await api.post('product/productprice/register',
      { ...productPrice, custo: Number(productPrice.custo), valor: Number(productPrice.valor) },
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: "Sucesso ao cadastrar valor" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao cadastrar valor" }
  }
}

export default _register
