import api from "../../services/api"
import IDebts from "../../types/IDebts"
import { getToken } from "../../util"

interface IReturn {
  error: boolean
  visible: boolean
  message: string
}

const _register = async (body: IDebts): Promise<IReturn> => {
  try {
    await api.post('debts',
      { ...body },
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: "Sucesso ao registrar conta" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao registrar conta" }
  }
}

export default _register
