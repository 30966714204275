import React, { useState, useEffect, useRef } from 'react'
import handlersDebts from '../../../handlers/Debts'
import handlersCustomer from '../../../handlers/Customer'
import TableData from 'react-data-table-component'
import { DateRange } from '@mui/lab/DateRangePicker';
import {
  makeStyles, TextField, Theme, Divider, Tooltip,
  CircularProgress, Drawer, Typography, Container,
  Card, Button, IconButton, Switch, CardHeader,
  Breadcrumbs, Badge, List, ListItem, ListItemText,
  AppBar, TextareaAutosize, Tabs, Tab, Box, FormControl,
  FormLabel, RadioGroup, FormControlLabel, Radio, InputAdornment
} from '@material-ui/core/'
import { Alert } from '@material-ui/lab/'
import {
  AddOutlined, DeleteOutlined, RefreshOutlined,
  EditOutlined, ShoppingCartOutlined, ShoppingCart,
  ListOutlined, AttachFileOutlined, ChatOutlined,
  SearchOutlined, CloudDownloadOutlined, SyncAltOutlined,
  SyncOutlined, ArchiveOutlined, ClearOutlined, AddCircleOutlined, CheckOutlined, PhotoCameraOutlined
} from '@material-ui/icons/'
import { getToken, onlyUnique } from "../../../util"
import DatePicker from '../../../components/DatePicker'
import DataTable from '../../../components/DataTable'
import SnackAlert from '../../../components/Alert'
import RangePicker from '../../../components/RangePicker'
import FadeIn from 'react-fade-in'
import ISalesReport from '../../../types/ISalesReport'
import { maskReais } from '../../../util'
import ISituation from '../../../types/ISituations'
import Modal from '../../../components/Modal'
import IParcela from '../../../types/IParcela'
import ICart from '../../../types/ICart'
import './styles.css'
import ISaleComment from '../../../types/ISaleComment'
import ImgView from '../../../components/ImgView'
import BarcodeScanner from '../../../components/BarcodeScanner'
import Upload from '../../../components/Upload'
import IFiles from '../../../types/IFiles'
import ComboBox from '../../../components/ComboBox'
import IBranch from '../../../types/IBranch'
import ISaleOrigin from '../../../types/ISaleOrigin'
import ICustomer from '../../../types/ICustomer'
import ISituations from '../../../types/ISituations'
import IUser from '../../../types/IUser'
import api from '../../../services/api'
import Kpi from '../../../components/Kpi'
import ComboBoxMulti from '../../../components/ComboBoxMulti'
import IDebts from '../../../types/IDebts';
import { Colors } from '../../../constants/colors';
import InputMoney from '../../../components/InputMoney';
import IParcelasRelContas from '../../../types/IParcelasRelContas';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const ContasReport = (props: any): React.ReactElement => {


  const [selectedRows, setSelectedRows] = useState<any>()
  const [arrParcelas, setArrParcelas] = useState<IParcelasRelContas[]>([])
  const [isVisibleModalParcelas, setIsVisibleModalParcelas] = useState(false)
  const [visibleAlertDelete, setVisibleAlertDelete] = useState(false)
  const [isVisibleModalAlerta, setIsVisibleModalAlerta] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedTabPrincipal, setSelectedTabPrincipal] = useState(0);
  const [contas, setContas] = useState<any>([])
  const [customers, setCustomers] = useState<ICustomer[]>([])
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange<Date>>([null, null]);
  const [selectedIdCliente, setSelectedIdCliente] = useState('0');
  const [selectedIdFornecedor, setSelectedIdFornecedor] = useState('0');
  const [selectedStatus, setSelectedStatus] = useState<any>('1');
  const [selectedIdTipoDocumento, setSelectedIdTipoDocumento] = useState<any>('0');
  const [selectedIdTipoConta, setSelectedIdTipoConta] = useState<any>('0');
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [tiposDocumento, setTiposDocumento] = useState<any>([])
  const [tiposContas, setTiposContas] = useState<any>([])
  const [numeroNota, setNumeroNota] = useState('')
  const [numeroDoc, setNumeroDoc] = useState('')
  const [codigoBarra, setCodigoBarra] = useState('')
  const [qtdParcelas, setQtdParcelas] = useState(0);
  const [valorTotal, setValorTotal] = useState('')
  const [obs, setObs] = useState('')
  const [selectedParcela, setSelectedParcela] = useState<any>(null)
  const [selectedContaDelete, setSelectedContaDelete] = useState<any>(null)
  const [dataPrimeiraParcela, setDataPrimeiraParcela] = useState<Date | null>(null);
  const [qtdDiasIntervalo, setQtdDiasIntervalo] = useState(0);
  const [dataPagamento, setDataPagamento] = useState<Date | null>(null);
  const [alert, setAlert] = useState({
    visible: false,
    message: '',
    error: false
  })
  const [order, setOrder] = useState('Date')

  const [showModalScanner, setShowModalScanner] = useState(false)
  const [showModalTipoConta, setShowModalTipoConta] = useState(false)
  const [contasPagar, setContasPagar] = useState('')
  const [idContaEdit, setIdContaEdit] = useState('')
  const [descricaoTipoConta, setDescricaoTipoConta] = useState('')
  const [tipoContaEdit, setTipoContaEdit] = useState(null)

  const classes = useStyles()


  const getCustomers = async (): Promise<void> => {
    const data = await handlersCustomer._list()
    setCustomers(data)
  }

  const handleChangeTabPrincipal = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabPrincipal(newValue);
  };

  const registerTipoConta = async (): Promise<void> => {
    if (tipoContaEdit) {
      await handlersDebts._updateType(tipoContaEdit, descricaoTipoConta)
    } else {
      await handlersDebts._registerType(descricaoTipoConta)
    }
    getTipoContas()
    setTipoContaEdit(null)
    setDescricaoTipoConta('')
    window.alert('Tipo de conta salvo com sucesso')
  }

  const getContas = async (): Promise<void> => {
    setLoading(true)
    const data = await handlersDebts._list({
      status: Number(selectedStatus),
      periodo: dateRangeFilter[0] === null ? null : dateRangeFilter,
      idfornecedor: selectedIdCliente === '0' ? null : Number(selectedIdCliente),
    })
    setContas(data)
    setLoading(false)
  }

  const getTipoDocumentos = async (): Promise<void> => {
    const data = await handlersDebts._listTypes()
    setTiposDocumento(data)
  }
  const getTipoContas = async (): Promise<void> => {
    const data = await handlersDebts._listDebtsTypes()
    setTiposContas(data)
  }

  const resetFilters = async () => {
    setCustomers([])
    setDateRangeFilter([null, null])
    await setSelectedIdCliente('0')
    await getCustomers()
    await setSelectedStatus('0')
  }

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({ ...alert, visible: false })
      }, 3000)
    }
  }, [alert.visible])

  useEffect(() => {
    getCustomers()
    getTipoDocumentos()
    getTipoContas()
  }, [])

  const handleUpdateStatusParcela = async (): Promise<void> => {
    if (!dataPagamento) return window.alert('Data de pagamento inválida')

    if (contasPagar && contasPagar.split(',') && contasPagar.split(',').length > 0) {
      const arrContas = []
      for await (let idConta of contasPagar.split(',')) {
        arrContas.push(contas.filter((c: any) => String(c.idcontaspagar) === String(idConta))[0])
      }
      for await (let c of arrContas) {
        for await (let p of c.parcelas) {
          const { idcontaspagarparcela } = p
          await handlersDebts._update(idcontaspagarparcela, dataPagamento)
        }
      }
      setContasPagar('')
    } else {
      await handlersDebts._update(selectedParcela.idcontaspagarparcela, dataPagamento)
    }

    setAlert({ error: false, visible: true, message: 'Parcelas quitadas com sucesso!' })
    setIsVisibleModalParcelas(false)
    setIsVisibleModalAlerta(false)
    await getContas()
  }

  const getParcelas = (): IParcelasRelContas[] => {
    const qtd_parcelas = Number(qtdParcelas)
    const valor = Number(valorTotal)
    const valor_parcela = valor / qtd_parcelas
    const limit = qtd_parcelas
    const arr_parcelas: IParcelasRelContas[] = []

    if (dataPrimeiraParcela) {
      for (let i = 1; i <= limit; i++) {

        let dt = new Date(dataPrimeiraParcela)
        if (i > 1) {
          dt = new Date(arr_parcelas[i - 2]?.datavencimento)
          const hours = qtdDiasIntervalo * 24
          dt.setHours(hours)
        }
        arr_parcelas.push({
          numeroparcela: i,
          valor: String(valor_parcela.toFixed(2)),
          datavencimento: dt,
        })

      }
    }

    return arr_parcelas
  }

  const handleRegister = async (): Promise<void> => {
    if (idContaEdit && idContaEdit !== '') {
      if (getParcelas() && getParcelas().length > 0) {
        const register = await handlersDebts._updateConta({
          idcontaspagar: idContaEdit,
          idfornecedor: Number(selectedIdFornecedor),
          idtipodocumento: selectedIdTipoDocumento,
          idtipocontaspagar: selectedIdTipoConta === 'null' ? null : Number(selectedIdTipoConta),
          parcelas: getParcelas(),
          numerodocumento: numeroDoc,
          numeronota: numeroNota,
          quantidadeparcelas: qtdParcelas,
          observacao: obs,
          codigobarra: codigoBarra ? codigoBarra : null
        })
        setAlert(register)
        setIdContaEdit('')
      } else {
        setAlert({ error: true, message: 'Erro ao gerar parcelas , att', visible: true })
      }

    } else {
      const register = await handlersDebts._register({
        idfornecedor: Number(selectedIdFornecedor),
        idtipodocumento: selectedIdTipoDocumento,
        idtipocontaspagar: selectedIdTipoConta,
        parcelas: getParcelas(),
        numerodocumento: numeroDoc,
        numeronota: numeroNota,
        quantidadeparcelas: qtdParcelas,
        observacao: obs,
        codigobarra: codigoBarra ? codigoBarra : null
      })
      setAlert(register)
      setIdContaEdit('')
    }
    setDrawerVisible(false)
    resetForm()
    getContas()
    getContas()
  }

  const resetForm = () => {
    setDataPrimeiraParcela(null)
    setSelectedIdFornecedor('0')
    setSelectedIdTipoDocumento('0')
    setNumeroDoc('')
    setCodigoBarra('')
    setNumeroNota('')
    setQtdParcelas(0)
    setValorTotal('')
    setObs('')
  }

  const getCalcTotalContas = (contas: IDebts[]): number => {
    let value = 0
    for (let i of contas) {
      value += Number(i.total)
    }
    return value
  }

  const getCalcContas = (param: null | boolean, contas: IDebts[]): number => {
    let value = 0
    for (let i of contas) {
      const calc = sumCalcTotalParcelas(i.parcelas, param)
      value += calc
    }
    return value
  }

  const sumCalcTotalParcelas = (parcelas: IParcelasRelContas[], param: null | boolean): number => {
    let val = 0
    for (let c of parcelas) {
      if (param === null) {
        val += Number(c.valor)
      } else if (c?.pago === param) {
        val += Number(c.valor)
      }
    }
    return val
  }

  const handleDeleteConta = async (id: number): Promise<void> => {
    const del = await handlersDebts._delete(id)
    await getContas()
    setVisibleAlertDelete(false)
    setAlert(del)

  }

  const getDataVencimento = (obj: any): string => {
    return obj?.parcelas[0]?.datavencimento
  }

  const verifySelected = (idconta: number): boolean => {
    if (contasPagar && contasPagar.split(',') && contasPagar.split(',').length > 0) {
      for (let id of contasPagar.split(',')) {
        if (String(idconta) === String(id)) {
          return true
        }
      }
    }
    return false
  }

  const verifyDateDiff = (between: DateRange<Date>): boolean => {
    if (!between || !between[0] || !between[1]) return false
    const dt1 = between[0]?.toLocaleDateString().toString()
    const dt2 = between[1]?.toLocaleDateString().toString()
    const verify = dt1 === dt2
    return verify
  }

  const handleEdit = (obj: any) => {
    if (obj) {
      setIdContaEdit(obj.idcontaspagar)
      setObs(obj.observacao)
      setCodigoBarra(obj.codigobarra)
      setSelectedIdFornecedor(String(obj.idfornecedor))
      setSelectedIdTipoConta(String(obj.idtipocontaspagar))
      setSelectedIdTipoDocumento(obj.idtipodocumento)
      setNumeroNota(obj.numeronota)
      setNumeroDoc(obj.numerodocumento)
      setQtdParcelas(obj.quantidadeparcelas)
      setValorTotal(obj.total)
      setDataPrimeiraParcela(obj.parcelas[0].datavencimento)
      setQtdDiasIntervalo(0)
      if (obj.parcelas && obj.parcelas.length > 1) {
        const date1 = new Date(obj.parcelas[0].datavencimento);
        const date2 = new Date(obj.parcelas[1].datavencimento);
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());
        const diffDays = timeDiff / (1000 * 3600 * 24);
        setQtdDiasIntervalo(diffDays);
      }
      setDrawerVisible(true)
    }
  }

  const parseContas = (arrContas: any, agrupador: string) => {
    if (arrContas) {

      let agrupadores = []
      for (let it of arrContas) {
        if (it[agrupador]) {
          agrupadores.push(it[agrupador])
        }
      }
      agrupadores = agrupadores.filter(onlyUnique)

      let newArr = []
      for (let agg of agrupadores) {
        const contasAgrupadorArr = arrContas.filter((obj: any) => obj[agrupador] === agg)
        let quantidade = 0
        let total = 0
        let totalpago = 0
        let totalapagar = 0
        for (let acc of contasAgrupadorArr) {
          total += acc.total && acc.total !== '0' ? parseFloat(acc.total) : 0
          totalpago += sumCalcTotalParcelas(acc.parcelas, true)
          totalapagar += sumCalcTotalParcelas(acc.parcelas, false)
        }
        console.log('descricao', agg, 'total', total)
        newArr.push({
          descricao: agg,
          quantidade: contasAgrupadorArr.length,
          total,
          totalpago,
          totalapagar
        })
      }
      return newArr.sort((a, b) => (a.total < b.total) ? +1 : -1)
    } else {
      return []
    }
  }

  return (
    <>


      <Modal
        open={visibleAlertDelete}
        onClose={() => {
          setVisibleAlertDelete(false)
        }}
        children={
          <div style={{ width: 558 }}>
            <h3>Atenção</h3>
            <Divider />
            <div style={{ padding: 18, marginTop: 18 }}>
              <h2 style={{ color: Colors.darkblue }}>Realmente deseja excluir essa conta? Essa ação não poderá ser desfeita!</h2>
            </div>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 22 }}
              onClick={async () => await handleDeleteConta(selectedContaDelete.idcontaspagar)}
            >
              <CheckOutlined /> &nbsp; Tenho certeza!
            </Button>
          </div>
        }
      />

      <Modal
        open={showModalScanner}
        onClose={() => {
          setShowModalScanner(false)
          // getCustomers()
        }}
        children={
          <div style={{ width: 900 }}>
            <h2>Ler código de barras</h2>
            <Divider />
            {showModalScanner && (
              <BarcodeScanner value={codigoBarra} onChange={(value: any) => {
                setCodigoBarra(value)
                if (value && value !== "") setShowModalScanner(false)
              }} />
            )}
            <Button variant='contained' color='primary' onClick={() => setShowModalScanner(false)}>
              Concluido
            </Button>
          </div>
        }
      />

      <Modal
        open={showModalTipoConta}
        onClose={() => {
          setShowModalTipoConta(false)
        }}
        children={
          <div style={{ width: 900 }}>
            <h2>Gerenciar tipos de conta</h2>
            <Divider />
            <div style={{ display: 'flex', padding: 12, alignItems: 'center' }}>
              <TextField
                size='small'
                variant="standard"
                margin="normal"
                value={descricaoTipoConta}
                fullWidth
                label="Digite a descrição"
                onChange={(e) => setDescricaoTipoConta(e.target.value)}
              />
              <Button variant='contained' color='primary' size='small' style={{ height: 40 }} onClick={() => registerTipoConta()}>
                <AddOutlined /> Gravar
              </Button>
            </div>
            <Divider />
            <DataTable
              columns={[
                {
                  name: 'Descrição',
                  selector: 'descricao',
                  sortable: true
                },
                {
                  name: 'Editar',
                  button: true,
                  cell: (obj: any) => {
                    return (
                      <IconButton
                        onClick={() => {
                          setTipoContaEdit(obj.idtipocontaspagar)
                          setDescricaoTipoConta(obj.descricao)
                        }}
                      >
                        <EditOutlined color='primary' />
                      </IconButton>
                    )
                  }
                }
              ]}
              data={tiposContas}
            />
          </div>
        }
      />


      <Modal
        open={isVisibleModalParcelas}
        onClose={() => {
          setIsVisibleModalParcelas(false)
          // getCustomers()
        }}
        children={
          <div style={{ width: 900 }}>
            <h2>Parcelas</h2>
            <Divider />
            <DataTable
              columns={[
                {
                  name: 'Parcela',
                  selector: 'numeroparcela',
                  sortable: true,
                  button: true
                },
                {
                  name: 'Data Vencimento',
                  cell: (obj: any) => `${new Date(obj.datavencimento).toLocaleDateString()}`,
                  sortable: true
                },
                {
                  name: 'Data Pagamento',
                  cell: (obj: any) => `${obj.datapagamento ? new Date(obj.datapagamento).toLocaleDateString() : '-'}`,
                  sortable: true
                },
                {
                  name: 'Valor',
                  selector: 'valor',
                  cell: (p: any) => maskReais(Number(p.valor)),
                  sortable: true
                },
                {
                  name: 'Status',
                  cell: (obj: any) => {
                    return (
                      <>
                        {obj.pago ? 'Pago' : 'Pendente'}
                        {!obj.pago && (
                          <IconButton
                            onClick={() => {
                              setIsVisibleModalAlerta(true)
                              setSelectedParcela(obj)
                            }}
                          >
                            <SyncAltOutlined style={{ color: Colors.lightblue }} />
                          </IconButton>
                        )}
                      </>
                    )
                  }
                }
              ]}
              data={arrParcelas.sort((a: any, b: any) => a.numeroparcela - b.numeroparcela)}
            />
          </div>
        }
      />


      <Modal
        open={isVisibleModalAlerta}
        onClose={() => {
          setIsVisibleModalAlerta(false)
          // getCustomers()
        }}
        children={
          <div style={{ width: 558 }}>
            <h3>Atenção</h3>
            <Divider />
            <div style={{ padding: 18, marginTop: 18 }}>
              <h2 style={{ color: Colors.darkblue }}>Realmente deseja quitar parcelas? Essa ação não poderá ser desfeita!</h2>
              <div style={{ paddingTop: 18 }}>
                <DatePicker
                  label='Data de pagamento'
                  value={dataPagamento}
                  change={(v: Date | null) => setDataPagamento(v)}
                />
              </div>
            </div>

            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 22 }}
              disabled={!dataPagamento}
              onClick={async () => await handleUpdateStatusParcela()}

            >
              <CheckOutlined /> &nbsp; Tenho certeza!
            </Button>
          </div>
        }
      />

      <SnackAlert
        alert={alert}
        align='center'
        position='top'
      />


      <FadeIn delay={200} transitionDuration={200}>
        <Card style={{ padding: 15, marginTop: 30, borderRadius: 10 }}>
          <h2 style={{ textAlign: 'center' }}>{props?.selected?.descricao}</h2>
          <div style={{ padding: 12 }}>
            <h2>Filtros: </h2>
          </div>
          <div style={{ padding: '0 12px', display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 350 }}>
              <RangePicker
                value={dateRangeFilter}
                onChange={(v: any) => {
                  setDateRangeFilter(v)
                  if (v) setContas([])
                }}
              />
            </div>
            <div style={{ display: 'flex', width: '60%', marginLeft: 16 }}>
              {customers && (
                <ComboBox
                  value={selectedIdCliente}
                  data={
                    customers
                      .filter(f => f?.fornecedor).map(c => {
                        return {
                          descricao: `${c.nome.toUpperCase()}`,
                          id: String(c.idcliente)
                        }
                      })
                  }
                  change={(e: any) => {
                    setSelectedIdCliente(e?.id)
                    if (e?.id) setContas([])
                  }}
                  label='Fornecedor'
                />
              )}
              <ComboBox
                value={selectedStatus}
                data={[
                  {
                    descricao: 'Todos',
                    id: '1'
                  },
                  {
                    descricao: 'A Pagar',
                    id: '2'
                  },
                  {
                    descricao: 'Pago',
                    id: '3'
                  },
                ]}
                change={(e: any) => {
                  setSelectedStatus(e?.id)
                  if (e?.id) setContas([])
                }}
                label='Status'
              />
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 28 }}>
                <Button color='secondary' size='small' variant='contained' onClick={() => resetFilters()} style={{ marginRight: 8 }}>
                  <ClearOutlined /> Limpar
                </Button>
                <Button size='small' color='default' variant='contained' onClick={async () => await getContas()} >
                  {loading ? (<><CircularProgress size={19} /> Carregando</>) : (<><SearchOutlined /> Pesquisar</>)}
                </Button>
              </div>
            </div>
            <div style={{ marginLeft: 20 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDrawerVisible(true)
                }}
              >
                <AddOutlined /> &nbsp; Cadastro
              </Button>
            </div>
          </div>
          {contas && contas.length > 0 && (
            <div style={{ padding: 38 }}>
              <Tabs
                value={selectedTabPrincipal}
                onChange={handleChangeTabPrincipal}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Relatório" {...a11yProps(1)} />
              </Tabs>

              <TabPanel value={selectedTabPrincipal} index={0} >
                <div style={{ marginTop: 12, display: 'flex', justifyContent: 'space-between' }}>
                  <Kpi
                    label="Quantidade de contas"
                    value={contas.length.toString()}
                  />
                  <Kpi
                    label="Valor total contas"
                    value={
                      `R$ ${maskReais(getCalcTotalContas(contas))}`
                    }
                    color='inheit'
                  />
                  <Kpi
                    label="Valor total a Pagar"
                    value={
                      `R$ ${maskReais(getCalcContas(false, contas))}`
                    }
                    color='red'
                  />
                  <Kpi
                    label="Valor total Pago"
                    value={
                      `R$ ${maskReais(getCalcContas(true, contas))}`
                    }
                    color='green'
                  />
                </div>
                <div style={{ marginTop: 18, display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '49.5%' }}>
                    <h4>Total por tipo de conta</h4>
                    <div style={{ border: '2px #e1dede solid', padding: 5 }}>
                      <DataTable
                        pagination
                        columns={[
                          {
                            name: 'Tipo',
                            selector: 'descricao',
                          },
                          {
                            name: 'Qtde.',
                            selector: 'quantidade',
                            button: true
                          },
                          {
                            name: 'Total',
                            cell: (obj: any) => maskReais(obj.total),
                            button: true
                          },
                          {
                            name: 'Pago',
                            cell: (obj: any) => maskReais(obj.totalpago),
                            button: true
                          },
                          {
                            name: 'A pagar',
                            cell: (obj: any) => maskReais(obj.totalapagar),
                            button: true
                          },
                        ]}
                        data={parseContas(contas, 'tipocontaspagar')}
                        striped
                      />
                    </div>
                  </div>
                  <div style={{ width: '49.5%' }}>
                    <h4>Total por fornecedor</h4>
                    <div style={{ border: '2px #e1dede solid', padding: 5 }}>
                      <DataTable
                        pagination
                        columns={[
                          {
                            name: 'Fornecedor',
                            selector: 'descricao',
                          },
                          {
                            name: 'Qtde.',
                            selector: 'quantidade',
                            button: true
                          },
                          {
                            name: 'Total',
                            cell: (obj: any) => maskReais(obj.total),
                            button: true
                          },
                          {
                            name: 'Pago',
                            cell: (obj: any) => maskReais(obj.totalpago),
                            button: true
                          },
                          {
                            name: 'A pagar',
                            cell: (obj: any) => maskReais(obj.totalapagar),
                            button: true
                          },
                        ]}
                        data={parseContas(contas, 'fornecedor')}
                        striped
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={selectedTabPrincipal} index={1} >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 38, paddingTop: 38 }}>
                    <Button variant='contained' color={order === '>' ? 'primary' : 'default'} onClick={() => setOrder('>')}>
                      Maior Valor
                    </Button>

                    <Button variant='contained' color={order === '<' ? 'primary' : 'default'} style={{ marginLeft: 8 }} onClick={() => setOrder('<')}>
                      Menor Valor
                    </Button>

                    <Button variant='contained' color={order === 'Date' ? 'primary' : 'default'} style={{ marginLeft: 8 }} onClick={() => setOrder('Date')}>
                      Data de vencimento
                    </Button>
                  </div>
                  <Divider />
                  <div style={{ padding: 8 }}>
                    {!contasPagar && (<small>* Para quitar várias parcelas selecione somente um dia no filtro de data e a situação "A pagar";</small>)}
                    {contasPagar && (<span>Contas selecionadas: </span>)}
                    {contasPagar && (<span style={{ fontWeight: 'bold' }}>{contasPagar} </span>)}
                    <Button onClick={() => setIsVisibleModalAlerta(true)} variant='outlined' color='primary' style={{ marginLeft: 8, display: contasPagar ? '' : 'none' }}>
                      Pagar
                    </Button>
                  </div>
                  <Divider />
                  <div style={{ zoom: '90%' }}>
                    <DataTable
                      selectedRows={selectedRows}
                      changeRow={(arr: any) => setSelectedRows(arr)}
                      selectable={false}
                      columns={[
                        {
                          name: 'id',
                          cell: (obj: any) => {
                            return (
                              <>
                                {obj.datainclusao === 'TOTAL' ? <span style={{ fontWeight: 'bold' }}>TOTAL</span> : (
                                  <>
                                    {!verifySelected(obj.idcontaspagar) && verifyDateDiff(dateRangeFilter) && selectedStatus === '2' && (
                                      <Button
                                        onClick={() => {
                                          const newStr = contasPagar === '' ? String(obj.idcontaspagar) : contasPagar + ',' + obj.idcontaspagar
                                          setContasPagar(newStr)
                                        }}
                                      >
                                        <AddOutlined />
                                      </Button>
                                    )}
                                    {obj.idcontaspagar}
                                  </>
                                )}
                              </>
                            )
                          },
                          sortable: true,
                          button: true
                        },
                        {
                          name: 'Dt. inclusão',
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? '-' : new Date(obj.datainclusao).toLocaleDateString()
                        },
                        {
                          name: 'Dt. vencimento',
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? '-' : !getDataVencimento(obj) ? '-' : new Date(getDataVencimento(obj)).toLocaleDateString()
                        },
                        // {
                        //   name: 'Cód. barras',
                        //   cell: (obj: any) => obj.datainclusao === 'TOTAL' ? '-' : obj.codigobarra
                        // },
                        {
                          name: 'N° nota',
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? '-' : obj.numeronota
                        },
                        {
                          name: 'Tipo Conta',
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? '-' : obj.tipocontaspagar
                        },
                        {
                          name: 'Tipo Doc.',
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? '-' : obj.tipodocumento
                        },
                        {
                          name: 'N° documento',
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? '-' : obj.numerodocumento
                        },
                        {
                          name: 'Fornecedor',
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? '-' : `${obj.fornecedor.toUpperCase()}`
                        },
                        {
                          name: 'Total',
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? obj.total : maskReais(obj.total)
                        },
                        {
                          name: 'Pago',
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? obj.pago : maskReais(sumCalcTotalParcelas(obj.parcelas, true))
                        },
                        {
                          name: 'A Pagar',
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? obj.aPagar : maskReais(sumCalcTotalParcelas(obj.parcelas, false))
                        },
                        {
                          name: 'Observação',
                          cell: (obj: any) => {
                            return obj.datainclusao === 'TOTAL' ? '-' : (
                              <TextareaAutosize
                                style={{ width: '100%' }}
                                aria-label="minimum height"
                                maxRows={2}
                                value={obj.observacao}
                                disabled
                              />
                            )
                          }
                        },
                        {
                          name: 'Parcelas',
                          button: true,
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? '-' : (
                            <Button
                              color='default'
                              onClick={() => {
                                setArrParcelas(obj.parcelas)
                                setIsVisibleModalParcelas(true)
                              }}
                            >
                              <Badge badgeContent={<span style={{ width: 24 }}>{obj.parcelas.length}</span>} color="primary">
                                <SearchOutlined color="action" style={{ fontSize: 28 }} />
                              </Badge>
                            </Button>
                          )
                        },
                        {
                          name: 'Excluir',
                          button: true,
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? '-' : (
                            <IconButton
                              aria-label="excluir"
                              onClick={async () => {
                                setVisibleAlertDelete(true)
                                setSelectedContaDelete(obj)
                              }}
                            >
                              <DeleteOutlined fontSize="small" />
                            </IconButton>
                          )
                        },
                        {
                          name: 'Editar',
                          button: true,
                          cell: (obj: any) => obj.datainclusao === 'TOTAL' ? '-' : (
                            <IconButton
                              aria-label="editar"
                              disabled={
                                sumCalcTotalParcelas(obj.parcelas, true) > 0
                                ||
                                Math.abs(Number(obj.total) - (sumCalcTotalParcelas(obj.parcelas, false) ? sumCalcTotalParcelas(obj.parcelas, false) : Number(obj.total))) > 0
                              }
                              onClick={async () => handleEdit(obj)}
                            >
                              <EditOutlined fontSize="small" />
                            </IconButton>
                          )
                        }
                      ]}
                      data={
                        [
                          ...contas.sort(
                            (a: any, b: any) => {

                              if (order === '>') {
                                return Number(sumCalcTotalParcelas(b.parcelas, false)) - Number(sumCalcTotalParcelas(a.parcelas, false))
                              }
                              if (order === '<') {
                                return Number(sumCalcTotalParcelas(a.parcelas, false)) - Number(sumCalcTotalParcelas(b.parcelas, false))
                              }

                              const dtA = getDataVencimento(a)
                              const dtB = getDataVencimento(b)
                              if (dtA < dtB) return -1
                              else if (dtA > dtB) return 0
                              else return 1

                            }
                          ),
                          {
                            datainclusao: 'TOTAL',
                            total: maskReais(getCalcTotalContas(contas)),
                            pago: maskReais(getCalcContas(true, contas)),
                            aPagar: maskReais(getCalcContas(false, contas)),
                          }
                        ]
                      }
                    />
                  </div>
                </div>

              </TabPanel>
            </div>
          )}
        </Card>
      </FadeIn>


      <Drawer
        open={drawerVisible}
        anchor='right'
        onClose={() => setDrawerVisible(false)}
      >
        <div style={{ width: 500, padding: 18 }}>
          <Typography variant='h5'>
            Formulário
          </Typography>
          <Divider style={{ margin: '5px 0' }} />
          <div style={{ padding: 18 }}>
            <TextareaAutosize
              style={{ width: '100%', marginTop: 18 }}
              aria-label="minimum height"
              minRows={3}
              value={obs}
              placeholder="Observacão"
              onChange={(e) => setObs(e.target.value)}
            />
            {customers && (
              <ComboBox
                value={selectedIdFornecedor}
                data={
                  customers
                    .filter(f => f?.fornecedor).map(c => {
                      return {
                        descricao: `${c.nome.toUpperCase()}`,
                        id: String(c.idcliente)
                      }
                    })
                }
                change={(e: any) => setSelectedIdFornecedor(e?.id)}
                label='Fornecedor'
              />
            )}
            <div style={{ marginTop: 16 }} />
            <TextField
              size='small'
              variant="standard"
              margin="normal"
              value={codigoBarra}
              fullWidth
              label="Código de barras"
              onChange={(e) => setCodigoBarra(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                    <Button size='small' onClick={() => setShowModalScanner(!showModalScanner)}>
                      <PhotoCameraOutlined color='primary' />
                    </Button>
                  </InputAdornment>
                )
              }}
            />
            <div style={{ marginTop: 4 }} />
            <div style={{ width: '100%', padding: '10px 0', display: 'flex', alignItems: 'center' }}>
              <ComboBox
                value={selectedIdTipoConta}
                data={tiposContas.map((p: any) => {
                  return { ...p, id: String(p.idtipocontaspagar) }
                })}
                change={(v: any) => setSelectedIdTipoConta(v.id)}
                label='Tipo de conta'
              />
              <Button variant='contained' size='small' onClick={() => setShowModalTipoConta(true)} color='primary' style={{ height: 38 }}>
                <AddOutlined />
              </Button>
            </div>
            <div style={{ marginTop: 4 }} />
            <ComboBox
              value={selectedIdTipoDocumento}
              data={tiposDocumento.map((p: any) => {
                return { ...p, id: String(p.idtipodocumento) }
              })}
              change={(v: any) => setSelectedIdTipoDocumento(v.id)}
              label='Tipo de documento'
            />
            <div style={{ marginTop: 6 }} />
            <div style={{ marginLeft: 4 }}>
              <TextField
                size='small'
                variant="standard"
                margin="normal"
                value={Number(numeroNota)}
                fullWidth
                type='number'
                label="Número nota"
                onChange={(e) => setNumeroNota(e.target.value)}
              />
              <TextField
                size='small'
                variant="standard"
                margin="normal"
                value={numeroDoc}
                fullWidth
                label="Número do documento"
                onChange={(e) => setNumeroDoc(e.target.value)}
              />
              <TextField
                size='small'
                variant="standard"
                margin="normal"
                value={qtdParcelas}
                fullWidth
                type="number"
                label="Quantidade de parcelas"
                onChange={(e) => setQtdParcelas(Number(e.target.value))}
              />
              <InputMoney
                value={valorTotal}
                onChange={(v: any) => setValorTotal(v)}
                label='Valor total'
              />

              <div style={{ marginTop: 18 }} />
              <DatePicker
                label='Data primeira parcela'
                value={dataPrimeiraParcela}
                change={(v: Date | null) => setDataPrimeiraParcela(v)}
              />
              <TextField
                size='small'
                variant="standard"
                margin="normal"
                value={qtdDiasIntervalo}
                fullWidth
                type="number"
                label="Qtde. de dias entre as parcelas"
                onChange={(e) => setQtdDiasIntervalo(Number(e.target.value))}
              />

              {qtdParcelas > 0 && valorTotal && dataPrimeiraParcela && (
                <div style={{ marginTop: 18 }}>
                  <h4>Parcelas</h4>
                  <Divider />
                  <DataTable
                    columns={[
                      {
                        name: 'Parcela',
                        selector: 'numeroparcela',
                        sortable: true,
                        button: true
                      },
                      {
                        name: 'Data Vencimento',
                        cell: (obj: any) => `${new Date(obj.datavencimento).toLocaleDateString()}`,
                        sortable: true
                      },
                      {
                        name: 'Valor',
                        selector: 'valor',
                        cell: (p: any) => maskReais(Number(p.valor)),
                        sortable: true
                      }
                    ]}
                    data={getParcelas()}
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', width: '90%', marginLeft: 20 }}>
            <Button
              size='small'
              type="reset"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={() => setDrawerVisible(false)}
            >
              Cancelar
            </Button>
            <Button
              onClick={async () => await handleRegister()}
              size='small'
              disabled={qtdParcelas === 0 || !valorTotal}
              style={{ marginLeft: 4 }}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Gravar
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default ContasReport
