import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

interface IProps {
    label: string
    value: string
    color?: string
}

const Kpi: React.FC<IProps> = (props) => {
    return (
        <Card sx={{ width: 288 }}>
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" color={props.color ? props.color : "primary"}>
                        {props.value}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.label}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default Kpi;