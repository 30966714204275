import React, { useState, useEffect } from 'react'
import handlersUser from '../../handlers/User'
import handlersUserTypes from '../../handlers/UserTypes'
import {
  makeStyles,
  TextField,
  Theme,
  Divider,
  Tooltip,
  CircularProgress,
  Drawer,
  Typography,
  Container,
  Card,
  Button,
  IconButton,
  Switch,
  CardHeader
} from '@material-ui/core/'
import { Alert } from '@material-ui/lab/'
import { AddOutlined, DeleteOutlined, RefreshOutlined, EditOutlined } from '@material-ui/icons/'
import { useForm, Controller } from "react-hook-form";
import DataTable from '../../components/DataTable'
import SnackAlert from '../../components/Alert'
import FadeIn from 'react-fade-in'
import api from '../../services/api';
import IUser from '../../types/IUser'
import IUserTypes from '../../types/IUserTypes'
import ComboBox from '../../components/ComboBox';
import { getToken } from '../../util';

const blankForm = {
  idusuario: 0,
  login: '',
  nome: '',
  email: '',
  idtipousuario: 0,
  tipousuario_desc: '',
  ativo: true,
  senha: ''
}

const UserPage = (): React.ReactElement => {
  const [status, setStatus] = useState(true)
  const [loading, setLoading] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [users, setUsers] = useState<IUser[]>([])
  const [usersTypes, setUserTypes] = useState<IUserTypes[]>([])
  const [edit, setEdit] = useState<IUser>(blankForm)
  const [alert, setAlert] = useState({
    visible: false,
    message: '',
    error: false
  })

  const { handleSubmit, errors, control } = useForm();
  const classes = useStyles()

  useEffect(() => {
    getUsers()
    getUserTypes()
  }, [])

  const getUsers = async (): Promise<void> => {
    const data = await handlersUser._list()
    setUsers(data)
  }

  const getUserTypes = async (): Promise<void> => {
    const data = await handlersUserTypes._list()
    setUserTypes(data)
  }

  const handleDelete = async (idusuario: number): Promise<void> => {
    const deleteUser = await handlersUser._delete(idusuario)
    setAlert(deleteUser)
  }

  const handleRegister = async (user: IUser): Promise<void> => {
    setLoading(true)
    setDrawerVisible(false)
    const register = edit.idusuario ?
      await handlersUser._update({ ...user, idusuario: edit.idusuario }, status)
      :
      await handlersUser._register(user, status)
    if (register.error) {
      setAlert(register)
    } else {
      setAlert({
        message: "Sucesso ao incluir registro.",
        visible: true,
        error: false
      })
    }
    setLoading(false)
    getUsers()
    setEdit(blankForm)
  }

  const handleEdit = (obj: IUser) => {
    setEdit(obj)
    setStatus(obj.ativo)
    setDrawerVisible(true)
  }

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({ ...alert, visible: false })
      }, 3000)
    }
  }, [alert.visible])

  return (
    <>
      <SnackAlert
        alert={alert}
        align='center'
        position='top'
      />
      <Container maxWidth='xl'>
        <FadeIn delay={200} transitionDuration={200}>
          <Typography variant='h5' color='textSecondary'>
            Usuários
          </Typography>
          <Card style={{ padding: 15, marginTop: 30, borderRadius: 10 }}>
            <CardHeader
              action={
                <>
                  <Tooltip title='Atualizar'>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={async () => await getUsers()}
                      style={{ borderRadius: '50%', height: 60 }}
                    >
                      <RefreshOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title='Cadastrar novo usuário'>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={() => {
                        setDrawerVisible(true)
                        setEdit(blankForm)
                      }}
                      style={{ borderRadius: '50%', height: 60 }}
                    >
                      <AddOutlined />
                    </Button>
                  </Tooltip>
                </>
              }
              title='Lista de usuários'
            />
            <DataTable
              search
              columns={[
                {
                  name: 'id',
                  selector: 'idusuario',
                  sortable: true,
                  button: true
                },
                {
                  name: 'Nome',
                  selector: 'nome',
                  sortable: true
                },
                {
                  name: 'Tipo',
                  selector: 'tipousuario_desc',
                  sortable: true
                },
                {
                  name: 'Login',
                  selector: 'login',
                  sortable: true
                },
                {
                  name: 'E-mail',
                  selector: 'email',
                  sortable: true
                },
                {
                  name: 'Status',
                  selector: 'ativo',
                  cell: (obj: IUser) => obj.ativo ? <Alert severity="success"></Alert> : <Alert severity="error"></Alert>
                },
                {
                  button: true,
                  cell: (obj: IUser) => <IconButton aria-label="editar" onClick={() => handleEdit(obj)}><EditOutlined fontSize="small" /></IconButton>
                },
                // {
                //   button: true,
                //   cell: (obj: IUser) => <IconButton aria-label="excluir" onClick={async () => await handleDelete(obj.idusuario)}><DeleteOutlined fontSize="small" /></IconButton>
                // }
              ]}
              data={users}
            />
          </Card>
        </FadeIn>
      </Container>

      <Drawer
        open={drawerVisible}
        anchor='right'
        onClose={() => setDrawerVisible(false)}
      >
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={handleSubmit(handleRegister)}
        >
          <Typography variant='h5'>
            {edit.idusuario > 0 ? 'Editando usuário' : 'Cadastrando usuário'}
          </Typography>
          <Divider style={{ margin: '5px 0' }} />
          <Controller
            name='nome'
            defaultValue={edit.nome}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.nome}
                helperText={errors.nome && "Campo obrigatório"}
                variant="standard"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o nome"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name='email'
            defaultValue={edit.email}
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                variant="standard"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o e-mail"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name='login'
            defaultValue={edit.login}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.login}
                helperText={errors.login && "Campo obrigatório"}
                variant="standard"
                margin="normal"
                value={value}
                fullWidth
                label="Digite o login"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name='senha'
            defaultValue={edit.senha}
            control={control}
            rules={{ required: !(edit.idusuario > 0) }}
            render={({ onChange, onBlur, value }) => (
              <TextField
                size='small'
                error={errors.senha}
                helperText={errors.senha && "Campo obrigatório"}
                variant="standard"
                margin="normal"
                type="password"
                value={value}
                fullWidth
                label="Digite o senha"
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />

          <Controller
            name='idtipousuario'
            defaultValue={edit.idtipousuario}
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <ComboBox
                value={value}
                data={usersTypes.map(i => {
                  return {...i, id: String(i.idtipousuario)}
                })}
                change={(v: any) => onChange(v.idtipousuario)}
                label='Tipo Usuário'
              />
            )}
          />

          <div style={{ marginTop: 12 }}>
            <Switch
              size='small'
              value={status}
              checked={status}
              onChange={() => setStatus(!status)}
            />  Ativo
          </div>
          <div className="drawer-bottom">
            <Button
              size='small'
              type="reset"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={() => setDrawerVisible(false)}
            >
              Cancelar
            </Button>
            <Button
              size='small'
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={errors.idusuario || errors.nome}
            >
              {loading ? <CircularProgress size={26} color="inherit" /> : 'Gravar'}
            </Button>
          </div>
        </form>
      </Drawer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    padding: '0 15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: 30
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default UserPage
