import api from "../../services/api"
import IUser from "../../types/IUser"
import { getToken } from "../../util"

const _list = async (): Promise<IUser[]> => {
  try {
    const response = await api.get('user/list', {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

export default _list
