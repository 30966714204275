import _list from "./list";
import _stockList from "./stockList";
import _register from "./register";
import _reset from "./reset";

export default {
  _list,
  _stockList,
  _register,
  _reset
}
