import api from "../../services/api"
import IFiles from "../../types/IFiles"
import { getToken } from "../../util"

const _list = async (idpedidovenda: number): Promise<IFiles[]> => {
  try {
    const response = await api.get(`/sale/fileorder/list/${idpedidovenda}`, {
      headers: {
        'x-access-token': await getToken()
      }
    })
    return response.data
  } catch (err) {
    return []
  }
}

export default _list
