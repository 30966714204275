import api from "../../services/api"
import { getToken } from "../../util"

interface IRegisterTrial {
  trial?: string
  error?: boolean
  visible?: boolean
  message?: string
}

const _register = async (idusuario: number): Promise<IRegisterTrial> => {
  try {
    const response = await api.post('sale/trial/register',
      { idusuario },
      { headers: { 'x-access-token': await getToken() } }
    )
    return response.data
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao gerar trial" }
  }
}

export default _register
