import api from "../../services/api"
import ITrama from "../../types/ITrama"
import { getToken } from "../../util"

const _register = async (trama: ITrama): Promise<any> => {
  try {
    await api.post('product/trama/register',
      { ...trama },
      { headers: { 'x-access-token': await getToken() } }
    )
    return { error: false, visible: true, message: "Sucesso ao cadastrar nova trama" }
  } catch (err) {
    return { error: true, visible: true, message: "Erro ao registrar trama" }
  }
}

export default _register
